export interface IPrimaryToken {
  token: string;
  sessionId: string;
  questionId: number;
  question: string;
  profileVersion: string;
}
export const hardshipPageDictionary = {
  '1': '/bankruptcy',
  '2': '/personalInfo',
  '3': '/driversLicense',
  '4': '/hardships-address',
  '5': '/hardships-income',
  '6': '/hardships-liquidassets',
  '7': '/hardships-retirement',
  '8': '/hardships-housing',
  '9': '/hardships-ownHousing',
  '10': '/hardships-rentalHousing',
  '11': '/hardships-employment',
  '12': '/personalInfo',
  '13': '/driversLicense',
  '14': '/hardships-address',
  '15': '/hardships-income',
  '16': '/hardships-liquidassets',
  '17': '/hardships-retirement',
  '18': '/hardships-employment',
  '': '/questionnaire',
  '0': '/CBhardships'
};
