import React, {Fragment, useState, createRef, useEffect} from 'react';
import {useAppContext} from '../../contexts/AppContext';
import FormInput from '../common/FormInput';
import Button from '../common/Button';
import './GuestCheckoutLanding.scss';
import {Link, useNavigate} from 'react-router-dom';
import PageHeading from '../PageHeading/PageHeading';
import {guestCheckoutValidate} from '../../services/PaymentService';
import Alert from '../Alert/Alert';
import dispatchEvent from '../../ensighten/index';
import {ResetEnsighten} from '../../ensighten/Ensighten.types';

function GuestCheckoutLanding() {
  const [showAlert, setshowAlert] = useState<boolean>();
  const [showPendingPaymentAlert, setShowPendingPaymentAlert] = useState<boolean>();
  const [showAccountLockedAlert, setShowAccountLockedAlert] = useState<boolean>();
  const [guestCheckoutAccountNumber, setGuestCheckoutAccountNumber] = useState('');
  const [reenterGuestAccountNumber, setReenterGuestAccountNumber] = useState('');
  const [lastName, setLastName] = useState('');
  const [ssn, setSSN] = useState('');
  const {
    setIsLoading, 
    setGuestCheckoutAcctNbr, 
    setGuestCheckoutDisplayNbr,
    setGuestCheckoutMinPayment,
    setGuestCheckoutMaxPayment,
    setGuestCheckoutProductType,
    setIsAchPaymentEnabled,
    EnsightenDetails
  } = useAppContext();
  const navigate = useNavigate();
  
  sessionStorage.removeItem('isRefreshed');
  sessionStorage.clear();

  useEffect(() =>{
    /* Reset Ensighten Object */
    ResetEnsighten(EnsightenDetails);
    dispatchEvent(EnsightenDetails);
  }, [])

  const submitButtonHandler = (event) => {

    event.preventDefault();

    setShowAccountLockedAlert(false);
    setShowPendingPaymentAlert(false);
    setshowAlert(false);

    if (guestCheckoutAccountNumber !== reenterGuestAccountNumber) {
      setshowAlert(true);
    } else if (guestCheckoutAccountNumber && reenterGuestAccountNumber && lastName && ssn) {
      if (ssn.length !== 4) {
        setshowAlert(true);
      } else {
        const data = {
          transType: 'GuestCheckout',
          acctNbr: parseInt(guestCheckoutAccountNumber).toString(),
          lastName: lastName,
          last4: ssn
        };
        setIsLoading(true);
        const response = guestCheckoutValidate(data);
        response
          .then((res) => {
            setIsLoading(false);
            if (res && res.acctStatus) {
              if (res.isAccountLocked) {
                setShowAccountLockedAlert(true);
              } else if (res.existingPayment){
                setShowPendingPaymentAlert(true);
              } else {
                setGuestCheckoutAcctNbr(res.acctNbr);
                setGuestCheckoutDisplayNbr(res.displayNbr);
                setGuestCheckoutMinPayment(res.minPayment);
                setGuestCheckoutMaxPayment(res.maxPayment);
                setGuestCheckoutProductType(res.productType);
                setIsAchPaymentEnabled(res.isEligibleForAchPayment);

                navigate('/guestPayment');
              }
            } else {
              setshowAlert(true);
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      setshowAlert(true);
    }
  };

  return (
    <Fragment>
      <PageHeading title={'Regions Account Solutions'} />
      <h1 className={'sr-only'}>Regions Account Solutions</h1>
      <div className={'regions-container'}>
        {showAlert && (
          <Alert
            alertText="We couldn’t validate these details. Please try again or call 1-800-333-4104 if you believe this is in error."
            srText="Alert: We couldn’t validate these details. Please try again or call 1-800-333-4104 if you believe this is in error."
          />
        )}
        {showPendingPaymentAlert && (
          <Alert
            alertText="A payment was already submitted on this account today. Please contact us at 1-800-333-4104 if you have any questions. Thank you."
            srText="Alert: A payment was already submitted on this account today. Please contact us at 1-800-333-4104 if you have any questions. Thank you."
          />
        )}
        {showAccountLockedAlert && (
          <Alert
            alertText="Please call Regions Account Solutions at 1-800-333-4104"
            srText="Alert: Please call Regions Account Solutions at 1-800-333-4104"
          />
        )}
        <div className={'inner-block main-content recovery-landing'}>
          <form noValidate>
            <div className="form-group input-block">
              {' '}
              <h2 id="payText" className={'secondary-header mb30'}>
                Before scheduling your one-time payment, please provide the following information.
              </h2>
              <div className={'input-div'}>
                <FormInput
                  title="Account Number"
                  type="number"
                  name="guestCheckoutAccountNumber"
                  className={'form-control'}
                  value={guestCheckoutAccountNumber}
                  handleChange={(e) => setGuestCheckoutAccountNumber(e.target.value)}
                  aria-required="true"
                  aria-describedby="guestCheckoutAccountNumber"
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                />
                <FormInput
                  title="Re-enter Account Number"
                  type="number"
                  name="reenterGuestCheckoutAccountNumber"
                  className={'form-control'}
                  value={reenterGuestAccountNumber}
                  handleChange={(e) => setReenterGuestAccountNumber(e.target.value)}
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                  aria-required="true"
                  aria-describedby="guestCheckoutAccountNumber"
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCut={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onDrag={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onDrop={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  autocomplete="off"
                />
                <FormInput
                  title="Last Name"
                  type="text"
                  name="lastName"
                  value={lastName}
                  handleChange={(e) => setLastName(e.target.value)}
                />
                <FormInput
                  title="Last 4 Digits of Social Security Number"
                  type="number"
                  name="guestCheckoutSSN"
                  value={ssn}
                  handleChange={(e) => setSSN(e.target.value)}
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                />
              </div>
            </div>

            <div className={'button-block'}>
              <Button
                title="Go to Payment"
                type="submit"
                className={'next-button submit-button'}
                action={submitButtonHandler}
              />
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default GuestCheckoutLanding;
