import React, {FC, useState, useEffect, Fragment} from 'react';
import useForm from '../common/UseForm';
import {incomeValidate} from '../common/FormValidationRules';
import Button from '../common/Button';
import {useAppContext} from '../../contexts/AppContext';
import FormInput from '../common/FormInput';
import {useNavigate} from 'react-router';
import PageHeading from '../PageHeading/PageHeading';
import {
  numberWithCommas,
  restrictNumericInput,
  getAccountDisplayStr
} from '../../utilities/Utilities';
import {saveHardshipApplicationData} from '../../services/HardshipService';
import dispatchEvent from '../../ensighten/index';

const HardshipsIncome: FC = () => {
  const {
    selectedAccount,
    coBorrowerPath,
    modalProps,
    setModalProps,
    hardshipAppDetails,
    setHardshipAppDetails,
    userToken,
    setUserToken,
    setIsLoading,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  const applicant = coBorrowerPath ? 'Coborrower' : '';
  const {values, errors, handleChange, handleSubmit, setValues, isSubmitting, isSent} = useForm(
    incomeValidate,
    ''
  );
  const [submitLater, setSubmitLater] = useState<boolean>(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchEvent(EnsightenDetails, true, false, false, coBorrowerPath);
  }, []);
  const [incomeState, setIncomeState] = useState(
    applicant === 'Coborrower'
      ? {
          grossMonthlyIncome: hardshipAppDetails.coBorrowerDetails.GrossMonthlyIncome,
          disAbilityIncome: hardshipAppDetails.coBorrowerDetails.UnemploymentIncome,
          childSupportIncome: hardshipAppDetails.coBorrowerDetails.ChildSupport,
          rents: hardshipAppDetails.coBorrowerDetails.Rent,
          OthersType: hardshipAppDetails.coBorrowerDetails.OthersIncomeType,
          OthersAmount: hardshipAppDetails.coBorrowerDetails.OtherIncome
        }
      : {
          grossMonthlyIncome: hardshipAppDetails.borrowerDetails.GrossMonthlyIncome,
          disAbilityIncome: hardshipAppDetails.borrowerDetails.UnemploymentIncome,
          childSupportIncome: hardshipAppDetails.borrowerDetails.ChildSupport,
          rents: hardshipAppDetails.borrowerDetails.Rent,
          OthersType: hardshipAppDetails.borrowerDetails.OthersIncomeType,
          OthersAmount: hardshipAppDetails.borrowerDetails.OtherIncome
        }
  );
  useEffect(() => {
    if (applicant === 'Coborrower') {
      setValues({
        ...values,
        grossMonthlyIncome: hardshipAppDetails.coBorrowerDetails.GrossMonthlyIncome
      });
    } else {
      setValues({
        ...values,
        grossMonthlyIncome: hardshipAppDetails.borrowerDetails.GrossMonthlyIncome
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent) {
      submitHandleChange();
    }
  }, [errors]);

  const navigate = useNavigate();
  const submitHandleChange = () => {
    setIsLoading(true);
    if (applicant === 'Coborrower') {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          LastUpdatedPageId: '15',
          actionType: 'update',
          coBorrowerDetails: {
            ...hardshipAppDetails.coBorrowerDetails,
            GrossMonthlyIncome: values.grossMonthlyIncome,
            ChildSupport: incomeState.childSupportIncome,
            Rent: incomeState.rents,
            UnemploymentIncome: incomeState.disAbilityIncome,
            OtherIncome: incomeState.OthersAmount,
            OthersIncomeType: incomeState.OthersType,
            TotalIncome: `${(
              parseFloat(values.grossMonthlyIncome) +
              parseFloat(incomeState.childSupportIncome ? incomeState.childSupportIncome : '0') +
              parseFloat(incomeState.rents ? incomeState.rents : '0') +
              parseFloat(incomeState.disAbilityIncome ? incomeState.disAbilityIncome : '0') +
              parseFloat(incomeState.OthersAmount ? incomeState.OthersAmount : '0')
            ).toFixed(2)}`
          }
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('income CoBorrower Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            console.log('\nincome Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              LastUpdatedPageId: '15',
              actionType: 'update',
              coBorrowerDetails: {
                ...hardshipAppDetails.coBorrowerDetails,
                GrossMonthlyIncome: values.grossMonthlyIncome,
                ChildSupport: incomeState.childSupportIncome,
                Rent: incomeState.rents,
                UnemploymentIncome: incomeState.disAbilityIncome,
                OtherIncome: incomeState.OthersAmount,
                OthersIncomeType: incomeState.OthersType,
                TotalIncome: `${(
                  parseFloat(values.grossMonthlyIncome) +
                  parseFloat(
                    incomeState.childSupportIncome ? incomeState.childSupportIncome : '0'
                  ) +
                  parseFloat(incomeState.rents ? incomeState.rents : '0') +
                  parseFloat(incomeState.disAbilityIncome ? incomeState.disAbilityIncome : '0') +
                  parseFloat(incomeState.OthersAmount ? incomeState.OthersAmount : '0')
                ).toFixed(2)}`
              }
            });
            setUserToken(resp.token);
            if (submitLater) {
              setSubmitLater(false);
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate('/hardships-liquidassets');
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
            // history.push('/hardships-liquidassets');
          }
        })
        .catch((err) => console.log(err));
    } else {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          LastUpdatedPageId: '5',
          actionType: 'update',
          borrowerDetails: {
            ...hardshipAppDetails.borrowerDetails,
            GrossMonthlyIncome: values.grossMonthlyIncome,
            ChildSupport: incomeState.childSupportIncome,
            Rent: incomeState.rents,
            UnemploymentIncome: incomeState.disAbilityIncome,
            OtherIncome: incomeState.OthersAmount,
            OthersIncomeType: incomeState.OthersType,
            TotalIncome: `${(
              parseFloat(values.grossMonthlyIncome) +
              parseFloat(incomeState.childSupportIncome ? incomeState.childSupportIncome : '0') +
              parseFloat(incomeState.rents ? incomeState.rents : '0') +
              parseFloat(incomeState.disAbilityIncome ? incomeState.disAbilityIncome : '0') +
              parseFloat(incomeState.OthersAmount ? incomeState.OthersAmount : '0')
            ).toFixed(2)}`
          }
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('income Borrower Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            setUserToken(resp.token);
            console.log('\nincome Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              LastUpdatedPageId: '5',
              actionType: 'update',
              borrowerDetails: {
                ...hardshipAppDetails.borrowerDetails,
                GrossMonthlyIncome: values.grossMonthlyIncome,
                ChildSupport: incomeState.childSupportIncome,
                Rent: incomeState.rents,
                UnemploymentIncome: incomeState.disAbilityIncome,
                OtherIncome: incomeState.OthersAmount,
                OthersIncomeType: incomeState.OthersType,
                TotalIncome: `${(
                  parseFloat(values.grossMonthlyIncome) +
                  parseFloat(
                    incomeState.childSupportIncome ? incomeState.childSupportIncome : '0'
                  ) +
                  parseFloat(incomeState.rents ? incomeState.rents : '0') +
                  parseFloat(incomeState.disAbilityIncome ? incomeState.disAbilityIncome : '0') +
                  parseFloat(incomeState.OthersAmount ? incomeState.OthersAmount : '0')
                ).toFixed(2)}`
              }
            });
            if (submitLater) {
              setSubmitLater(false);
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate('/hardships-liquidassets');
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
            // history.push('/hardships-liquidassets');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Fragment>
      <PageHeading title="Income"></PageHeading>
      {selectedAccount && (
        <div className={'regions-container'}>
          <div className={'page-content-container hardship-card '}>
            <div className="row">
              <div className={'two-column-layout'}>
                <div className="column-one">
                  <h2 className="payment-header">
                    <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                    <span className={'account-number'}>
                      {getAccountDisplayStr(selectedAccount)}
                    </span>
                  </h2>
                  <div className={'well'}>
                    <div id="income">
                      <p className={'confirmation-number'}>{hardshipAppDetails.hardshipAppId}</p>
                      <p>
                        <strong>{applicant ? applicant + ' ' : ''}Income</strong>
                      </p>
                      <p>
                        Your answers in the following fields will provide information about your
                        forms of income. If a field does not apply, you can leave it blank.
                      </p>
                      <p>*Fields marked with an asterisk are required.</p>
                    </div>
                  </div>
                </div>

                <div className="column-two">
                  <form onSubmit={handleSubmit} noValidate>
                    <FormInput
                      id="gross-monthly-income"
                      title={(applicant ? applicant + ' ' : '') + 'Gross Monthly Income*'}
                      type="number"
                      name="grossMonthlyIncome"
                      value={values.grossMonthlyIncome || ''}
                      handleChange={(e) => {
                        handleChange(e);
                      }}
                      errors={errors.grossMonthlyIncome}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="disability-income"
                      title={
                        (applicant ? applicant + ' ' : '') + 'Unemployment / Disability Income'
                      }
                      type="text"
                      name="Unemployment/ Disability Income"
                      value={incomeState.disAbilityIncome}
                      handleChange={(event) =>
                        setIncomeState({
                          ...incomeState,
                          disAbilityIncome: restrictNumericInput(
                            event.target.value,
                            incomeState.disAbilityIncome
                          )
                        })
                      }
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="child-support-alimony"
                      title={
                        (applicant ? applicant + ' ' : '') + 'Child Support / Alimony Received'
                      }
                      type="text"
                      name="Child Support or Alimony Received"
                      value={incomeState.childSupportIncome}
                      handleChange={(event) =>
                        setIncomeState({
                          ...incomeState,
                          childSupportIncome: restrictNumericInput(
                            event.target.value,
                            incomeState.childSupportIncome
                          )
                        })
                      }
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />
                    <div className="income-well">
                      You are not required to disclose any alimony, child support or separate
                      maintenance income if you do not wish to have it considered as a basis for
                      paying your past-due account balance.
                    </div>
                    <FormInput
                      id="rents-received"
                      title={(applicant ? applicant + ' ' : '') + 'Rents Received'}
                      type="text"
                      name="Rents Received"
                      value={incomeState.rents}
                      handleChange={(event) =>
                        setIncomeState({
                          ...incomeState,
                          rents: restrictNumericInput(event.target.value, incomeState.rents)
                        })
                      }
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="othersType"
                      title={(applicant ? applicant + ' ' : '') + 'Others (Specify Type)'}
                      type="text"
                      name="othersType"
                      value={incomeState.OthersType}
                      handleChange={(event) =>
                        setIncomeState({...incomeState, OthersType: event.target.value})
                      }
                      maxLength={20}
                    />
                    <FormInput
                      id="othersAmount"
                      title={(applicant ? applicant + ' ' : '') + 'Others (Specify Amount)'}
                      type="text"
                      name="othersAmount"
                      value={incomeState.OthersAmount}
                      handleChange={(event) =>
                        setIncomeState({
                          ...incomeState,
                          OthersAmount: restrictNumericInput(
                            event.target.value,
                            incomeState.OthersAmount
                          )
                        })
                      }
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />
                    <div className={'column-two-buttons float-right'}>
                      <Button
                        title="Next"
                        type="submit"
                        className={'next-button float-right'}
                        action={() => setSubmitLater(false)}
                      />
                    </div>
                    <div className="float-left column-two-buttons">
                      <Button
                        title="Back"
                        className=" cancel-button"
                        action={() => {
                          navigate('/hardships-address');
                        }}
                      />
                    </div>
                    <div className="">
                      <Button
                        title="Save and Continue Later"
                        className={'third-button save-button'}
                        action={(e) => {
                          setSubmitLater(true);
                          handleSubmit(e);
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default HardshipsIncome;
