import React, {useState, FC} from 'react';
// interface IButton {
//   className: string;
//   style?: React.CSSProperties;
//   action?: () => void;
//   title: string;
//   type?: any;
// }
const Button = (props) => {
  return (
    <button
      type={props.type}
      className={props.className}
      style={props.style}
      onClick={props.action}
      disabled={props.disabled}
    >
      {props.title}
    </button>
  );
};

export default Button;
