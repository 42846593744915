import React, {FC, useState, useEffect} from 'react';
import FormInput from '../../common/FormInput';
import {useNavigate} from 'react-router';
import Button from '../../common/Button';
import DatePicker from 'react-datepicker';
import './DriversLicenseCard.scss';
import {useAppContext} from '../../../contexts/AppContext';
import {
  numberWithCommas,
  getDateString,
  getAccountDisplayStr,
  useDateRange
} from '../../../utilities/Utilities';
import {constants} from '../../common/Constants';
import useForm from '../../common/UseForm';
import calendarIcon from '../../../assets/img/icons_package/icon-calendar-lines.svg';
import {driversLicenseValidate} from '../../common/FormValidationRules';
import {saveHardshipApplicationData} from '../../../services/HardshipService';
import dispatchEvent from '../../../ensighten/index';
const DriversLicenseCard: FC = () => {
  const {
    selectedAccount,
    coBorrowerPath,
    modalProps,
    setModalProps,
    hardshipAppDetails,
    setHardshipAppDetails,
    userToken,
    setUserToken,
    setIsLoading,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  const applicant = coBorrowerPath ? 'Coborrower' : '';
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    isSubmitting,
    isSent
  } = useForm(driversLicenseValidate, '');
  const navigate = useNavigate();
  const [issueDate, setIssueDate] = useState();
  const [errorIssDate, setErrorIssDate] = useState<boolean>(false);
  const [errorExpDate, setErrorExpDate] = useState<boolean>(false);
  const [expiryDate, setExpiryDate] = useState();
  const [submitLater, setSubmitLater] = useState<boolean>(false);
  const dlContent = `Your answers in the following fields will provide driver’s license information that we may use to verify your identity.`;
  const {minDate, maxDate} = useDateRange();

  function handleIssueDate(date) {
    setIssueDate(date);
    setValues({...values, dlIsDt: date});
    setErrorIssDate(false);
    setErrors({...errors, dlIsDt: undefined});
  }
  function handleExpiryDate(date) {
    setExpiryDate(date);
    setValues({...values, dlExpDt: date});
    setErrorExpDate(false);
    setErrors({...errors, dlExpDt: undefined});
  }
  let issueDtcalendar: DatePicker;
  let expiryDtcalendar: DatePicker;
  const openIssueDatepicker = () => issueDtcalendar.setOpen(true);
  const openExpiryDatepicker = () => expiryDtcalendar.setOpen(true);
  useEffect(() => {
    if (applicant === 'Coborrower') {
      setValues({
        ...values,
        dlNum: hardshipAppDetails.coBorrowerDetails.LicenseNumber,
        dlIsDt: hardshipAppDetails.coBorrowerDetails.LicenseIssueDate,
        dlExpDt: hardshipAppDetails.coBorrowerDetails.LicenseExpirationDate
      });
    } else {
      setValues({
        ...values,
        dlNum: hardshipAppDetails.borrowerDetails.LicenseNumber,
        dlIsDt: hardshipAppDetails.borrowerDetails.LicenseIssueDate,
        dlExpDt: hardshipAppDetails.borrowerDetails.LicenseExpirationDate
      });
    }
    
    dispatchEvent(EnsightenDetails, true, false, false, coBorrowerPath);
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent) {
      submitHandleChange();
    }
  }, [errors]);

  const submitHandleChange = () => {
    setIsLoading(true);
    if (applicant === 'Coborrower') {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          actionType: 'update',
          LastUpdatedPageId: '13',
          coBorrowerDetails: {
            ...hardshipAppDetails.coBorrowerDetails,
            LicenseNumber: values.dlNum,
            LicenseIssueDate: hardshipAppDetails.coBorrowerDetails.LicenseIssueDate
              ? hardshipAppDetails.coBorrowerDetails.LicenseIssueDate
              : getDateString(issueDate),
            LicenseExpirationDate: hardshipAppDetails.coBorrowerDetails.LicenseExpirationDate
              ? hardshipAppDetails.coBorrowerDetails.LicenseExpirationDate
              : getDateString(expiryDate)
          }
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('driv CoBorrower Obj is ', reqObj);
      resp
        .then((resp) => {
          setIsLoading(false);
          setUserToken(resp.token);
          if ('saveHardshipAppStatus' in resp) {
            console.log('\ndriv co Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              coBorrowerDetails: {
                ...hardshipAppDetails.coBorrowerDetails,
                LicenseNumber: values.dlNum,
                LicenseIssueDate: hardshipAppDetails.coBorrowerDetails.LicenseIssueDate
                  ? hardshipAppDetails.coBorrowerDetails.LicenseIssueDate
                  : getDateString(issueDate),
                LicenseExpirationDate: hardshipAppDetails.coBorrowerDetails.LicenseExpirationDate
                  ? hardshipAppDetails.coBorrowerDetails.LicenseExpirationDate
                  : getDateString(expiryDate)
              }
            });
            if (submitLater) {
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate('/hardships-address');
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
          }
        })
        .catch((err) => console.log(err));
    } else {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          actionType: 'update',
          LastUpdatedPageId: '3',
          borrowerDetails: {
            ...hardshipAppDetails.borrowerDetails,
            LicenseNumber: values.dlNum,
            LicenseIssueDate: hardshipAppDetails.borrowerDetails.LicenseIssueDate
              ? hardshipAppDetails.borrowerDetails.LicenseIssueDate
              : getDateString(issueDate),
            LicenseExpirationDate: hardshipAppDetails.borrowerDetails.LicenseExpirationDate
              ? hardshipAppDetails.borrowerDetails.LicenseExpirationDate
              : getDateString(expiryDate)
          }
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('driv Borrower Obj is ', reqObj);
      resp
        .then((resp) => {
          setIsLoading(false);
          if ('saveHardshipAppStatus' in resp) {
            console.log('\nDL Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              borrowerDetails: {
                ...hardshipAppDetails.borrowerDetails,
                LicenseNumber: values.dlNum,
                LicenseIssueDate: hardshipAppDetails.borrowerDetails.LicenseIssueDate
                  ? hardshipAppDetails.borrowerDetails.LicenseIssueDate
                  : getDateString(issueDate),
                LicenseExpirationDate: hardshipAppDetails.borrowerDetails.LicenseExpirationDate
                  ? hardshipAppDetails.borrowerDetails.LicenseExpirationDate
                  : getDateString(expiryDate)
              }
            });
            setUserToken(resp.token);
            if (submitLater) {
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate('/hardships-address');
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
            // history.push('/hardships-address');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className={'regions-container'}>
      {selectedAccount && (
        <div className={'drivers-license-card hardship-card page-content-container'}>
          <div className="row">
            <div className={'two-column-layout'}>
              <div className="column-one">
                <h2 className="payment-header">
                  <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                  <span className={'account-number'}>{getAccountDisplayStr(selectedAccount)}</span>
                </h2>
                <div className={'well'}>
                  <div id="driver-screen">
                    <p className={'confirmation-number'}>{hardshipAppDetails.hardshipAppId}</p>
                    <p>
                      <strong>
                        {applicant ? applicant + ' ' : ''}
                        {constants.driverLicenseHeader}
                      </strong>
                    </p>
                    <p>{dlContent}</p>
                    <p>*Fields marked with an asterisk are required. </p>
                  </div>
                </div>
              </div>

              <div className="column-two">
                <form onSubmit={handleSubmit} noValidate>
                  <FormInput
                    title={(applicant ? applicant + ' ' : '') + "Driver's License Number*"}
                    type="text"
                    name="dlNum"
                    value={values.dlNum}
                    handleChange={handleChange}
                    errors={errors.dlNum}
                    onWheel={(e) => {
                      e.target.blur();
                    }}
                    maxLength={20}
                  />
                  <div className="form-group">
                    <label htmlFor="dlIssueDt">
                      <strong>
                        {applicant
                          ? applicant + ' ' + "Driver's License Issue Date*"
                          : "Driver's License Issue Date*"}
                      </strong>
                    </label>
                    <div className={'position-rel'}>
                      <DatePicker
                        id="dlIssueDt"
                        ref={(c) => (issueDtcalendar = c)}
                        className="form-control date-picker"
                        selected={issueDate}
                        placeholderText="MM/DD/YYYY"
                        name={'dlIsDt'}
                        value={values.dlIsDt}
                        onChange={handleIssueDate}
                        dateFormat="MM / dd / yyyy"
                        maxDate={new Date()}
                        aria-label="dlIssueDt"
                        minDate={minDate}
                      />
                      <div className="image-wrapper-div">
                        <img
                          className="img-wrapper"
                          src={calendarIcon}
                          onClick={openIssueDatepicker}
                          alt="Calendar icon"
                        />
                      </div>
                    </div>
                    {(errorIssDate || errors.dlIsDt) && (
                      <div className="chinstrap form-control">{errors.dlIsDt}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="dlExpiryDt">
                      <strong>
                        {applicant
                          ? applicant + ' ' + "Driver's License Expiration Date*"
                          : "Driver's License Expiration Date*"}
                      </strong>
                    </label>
                    <div className={'position-rel'}>
                      <DatePicker
                        id="dlExpiryDt"
                        ref={(c) => (expiryDtcalendar = c)}
                        className="form-control date-picker"
                        selected={expiryDate}
                        placeholderText="MM/DD/YYYY"
                        onChange={handleExpiryDate}
                        preventOpenOnFocus={true}
                        name={'dlExpDt'}
                        value={values.dlExpDt}
                        minDate={issueDate}
                        maxDate={maxDate}
                        dateFormat="MM / dd / yyyy"
                        aria-label="dlExpiryDt"
                      />
                      <div className="image-wrapper-div">
                        <img
                          className="img-wrapper"
                          src={calendarIcon}
                          onClick={openExpiryDatepicker}
                          alt="Calendar icon"
                        />
                      </div>
                    </div>
                    {(errorExpDate || errors.dlExpDt) && (
                      <div className="chinstrap form-control">{errors.dlExpDt}</div>
                    )}
                  </div>
                  <div className={'column-two-buttons float-right'}>
                    <Button
                      title="Next"
                      type="submit"
                      className={'next-button float-right'}
                      action={() => setSubmitLater(false)}
                    />
                  </div>
                  <div className="float-left column-two-buttons">
                    <Button
                      title="Back"
                      className={'cancel-button float-left'}
                      action={() => navigate('/personalInfo')}
                    />
                  </div>
                  <div className="">
                    <Button
                      title="Save and Continue Later"
                      className={'third-button save-button'}
                      action={(e) => {
                        setSubmitLater(true);
                        handleSubmit(e);
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DriversLicenseCard;
