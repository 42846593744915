import React, {Fragment} from 'react';
import InputMask from 'react-input-mask';

function MaksedInput(props) {
  return (
    <Fragment>
      <div className="form-group">
        <label htmlFor={props.name} className={props.errors ? 'text-danger' : 'form-label'}>
          <strong>{props.title}</strong>
        </label>
        <div className="input-group">
          <InputMask
            mask={props.mask}
            className={props.errors ? 'border border-danger form-control' : 'form-control'}
            id={props.name}
            name={props.name}
            onChange={props.onChange}
            value={props.value}
            errors={props.errors}
            aria-label={props.name}
            maskplaceholder={props.maskplaceholder}
          />
        </div>
        {props.errors && <div className="chinstrap form-control">{props.errors}</div>}
      </div>
    </Fragment>
  );
}

export default MaksedInput;
