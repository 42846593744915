import React, {Fragment} from 'react';
import './Alert.scss';
interface IAlertProps {
  alertText: string;
  srText: string;
}

function Alert(props: IAlertProps) {
  const {alertText, srText} = props;
  return (
    <Fragment>
      <div className={'regions-alert-notification'}>
        <div className="alert alert-danger" role="alert">
          <div className="span sr-only">{srText}</div>
          <div className="alert-text">{alertText}</div>
        </div>
      </div>
    </Fragment>
  );
}

export default Alert;
