import React, {FC, useState, Fragment} from 'react';
import Button from '../common/Button';
import {useAppContext} from '../../contexts/AppContext';
import PageHeading from '../PageHeading/PageHeading';
import {useNavigate} from 'react-router-dom';
import {submitExtension} from '../../services/HardshipService';
import FormInput from '../common/FormInput';
const OneMonthExtension: FC = () => {
  const {
    selectedAccount,
    userToken,
    hardshipLoanDetails,
    hardshipReason,
    setUserToken,
    coBorrower,
    setConfNmbr
  } = useAppContext();
  const navigate = useNavigate();
  const [email, setemail] = useState(hardshipLoanDetails.borrowerEmail);
  const [cellPhone, setCellPhone] = useState(hardshipLoanDetails.borrowerCellPhoneNumber);
  const [emailCB, setemailCB] = useState(hardshipLoanDetails.coBorrowerEmail);
  const [cellPhoneCB, setCellPhoneCB] = useState(hardshipLoanDetails.coBorrowerCellPhoneNumber);
  const [errorState, setErrorState] = useState<{
    error: boolean;
    messgae: string;
    errorCB: boolean;
    messageCB: string;
    cellPhoneError: boolean,
    cellPhoneMessage: string,
    cellPhoneErrorCB: boolean,
    cellPhoneMessageCB: string,    
  }>({
    error: false,
    messgae: '',
    errorCB: false,
    messageCB: '',
    cellPhoneError: false,
    cellPhoneMessage: '',
    cellPhoneErrorCB: false,
    cellPhoneMessageCB: '',  
  });

  const checkInValidity = (value) => {
    const trueValue = value
      .replace(/_/g, '')
      .replace(/ /g, '')
      .replace(/-/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '');
  
    if (trueValue.length < 10) {
      return true;
    } else {
      return false;
    }
  };

  const isValidForm = () => {
    let error = false;
    let messgae = '';
    let cellPhoneError = false;
    let cellPhoneMessage = '';
    let cellPhoneErrorCB = false;
    let cellPhoneMessageCB = '';
    let errorCB = false;
    let messageCB = '';

    // Borrower Cell Phone Validation
    if (cellPhone === undefined || cellPhone === null || cellPhone.length <= 0 || cellPhone.trim() === '') {
      cellPhoneError = true;
      cellPhoneMessage = 'This field is required. Please enter a value.'
    }
    else if(checkInValidity(cellPhone)){
      cellPhoneError = true;
      cellPhoneMessage = 'Invalid cell phone number';
    }

    // Borrower Email Validation
    if (email === undefined || email === null || email.length <= 0 || email.trim() === '') {
        error = true;
        messgae = 'This field is required. Please enter a value.';
    } 
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        error = true;
        messgae = 'Please provide valid email id.';
    }

    if (coBorrower) {
      // CoBorrower Cell Phone Validation
      if (cellPhoneCB === undefined || cellPhoneCB === null || cellPhoneCB.length <= 0 || cellPhoneCB.trim() === '') {
        cellPhoneErrorCB = true;
        cellPhoneMessageCB = 'This field is required. Please enter a value.'
      }
      else if(checkInValidity(cellPhoneCB)){
        cellPhoneErrorCB = true;
        cellPhoneMessageCB = 'Invalid cell phone number'
      }

      // CoBorrower Email Validation
      if (emailCB === undefined || emailCB === null || emailCB.length <= 0 || emailCB.trim() === '') {
        errorCB = true;
        messageCB = 'This field is required. Please enter a value.';
      } 
      else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailCB)) {
          errorCB = true;
          messageCB = 'Please provide valid email id.';
      }      
            
    }

    setErrorState({
      ...errorState,
      error: error,
      messgae: messgae,
      errorCB: errorCB,
      messageCB: messageCB,
      cellPhoneError: cellPhoneError,
      cellPhoneMessage: cellPhoneMessage,
      cellPhoneErrorCB: cellPhoneErrorCB,
      cellPhoneMessageCB: cellPhoneMessageCB
    });    

    if (!error && !cellPhoneError && !cellPhoneErrorCB && !errorCB) {
      return true;
    }
    
    return false;
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
  };
  const submitApplication = () => {
    if (isValidForm()) {
      const extensionObj = {
        token: userToken,
        transType: 'Collections',
        extensionDetails: {
          bank: hardshipLoanDetails.bankNumber || '',
          app: hardshipLoanDetails.appCode || '',
          acctNum: hardshipLoanDetails.acctNum || '',
          AcctId: selectedAccount.delId,
          checkedBy: 'SSP',
          checkedDate: new Date().toLocaleDateString(),
          recommendation: 'Qualified',
          reason: hardshipReason,
          comment: '',
          eSign: '1',
          makerEmail: email,
          coMakerEmail: emailCB,
          makerCellPhone: cellPhone,
          coMakerCellPhone: cellPhoneCB,                      
          processed: '',
          source: 'SSP',
          checkType: 'EXT',
          sendLimitLtr: '',
          limitLtrProcessed: '',
          requestedMonths: '1'
        }
      };
      const response = submitExtension(extensionObj);
      response
        .then((res) => {
          if ('confNbr' in res) {
            console.log('One Month Extn Obj', res);
            setUserToken(res.token);
            setConfNmbr(res.confNbr);
            navigate('/extensionApplied');
          } else {
            setUserToken(res.response.data.token);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <Fragment>
      <PageHeading title="Loan Extension" />
      {selectedAccount && (
        <div className={'regions-container'}>
          <div className={'page-content-container hardship-card'}>
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <div className={'one-column-block'}>
                  <h1 className={'sr-only'}>Being Extension Application</h1>
                  <h2 className="payment-header">
                    <span className={'account-type'}>
                      {selectedAccount.delqProdType} Ending in{' '}
                      {selectedAccount.delqAcctNbr.substr(-4, 4)}
                    </span>
                  </h2>
                  <div>
                    <p>
                      Based on your responses, you are eligible to apply for a
                      <strong> one month extension.</strong> Please choose Apply to submit your
                      application.
                    </p>
                    <p>
                      If you need help or have questions about other hardship assistance that may be
                      available to you, please call 1-866-298-1113 Monday through Friday, 8 a.m. to
                      4:30 p.m. CT.
                    </p>
                  </div>
                  <form onSubmit={onFormSubmit} noValidate>
                    <div>
                      <FormInput
                        id="emailId"
                        title={'Email*'}
                        type="email"
                        name="emailId"
                        value={email}
                        handleChange={(e) => {
                          e.preventDefault();
                          setemail(e.target.value);

                          setErrorState({
                            ...errorState,
                            error: false,
                            messgae: ''
                          });
                          
                        }}
                        errors={errorState.error && errorState.messgae}
                      />
                    </div>
                    <div>
                    <FormInput
                      id="cellIdBorrower"
                      title={'Borrower Cell Phone Number*'}
                      type="phone"
                      name="cellIdBorrower"
                      value={cellPhone}
                      handleChange={(e) => {
                        e.preventDefault();
                        setCellPhone(e.target.value);

                        setErrorState({
                          ...errorState,
                          cellPhoneError: false,
                          cellPhoneMessage: ''
                        });
                      }}
                      errors={errorState.cellPhoneError && errorState.cellPhoneMessage}
                    />
                  </div>                    
                    {coBorrower && (
                      <Fragment>
                      <div>
                        <FormInput
                          id="emailIdCoBorrower"
                          title={'Coborrower Email*'}
                          type="email"
                          name="emailIdCoBorrower"
                          value={emailCB}
                          handleChange={(e) => {
                            e.preventDefault();
                            setemailCB(e.target.value);
                            
                            setErrorState({
                              ...errorState,
                              errorCB: false,
                              messageCB: ''
                            });
                          }}
                          errors={errorState.errorCB && errorState.messageCB}
                        />
                      </div>
                      <div>
                    <FormInput
                      id="cellIdCoBorrower"
                      title={'CoBorrower Cell Phone Number*'}
                      type="phone"
                      name="cellIdCoBorrower"
                      value={cellPhoneCB}
                      handleChange={(e) => {
                        e.preventDefault();
                        setCellPhoneCB(e.target.value);

                        setErrorState({
                          ...errorState,
                          cellPhoneErrorCB: false,
                          cellPhoneMessageCB: ''
                        });
                      }}
                      errors={errorState.cellPhoneErrorCB && errorState.cellPhoneMessageCB}
                    />
                  </div>                      
                      </Fragment>
                    )}
                    <div className="float-right button-layout">
                      <Button
                        title="Apply"
                        className={'next-button text-center float-right'}
                        action={submitApplication}
                      />
                    </div>
                  </form>
                  <div className="float-left button-layout">
                    <Button
                      title="Back"
                      className={'cancel-button '}
                      action={() => navigate('/questionnaire')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default OneMonthExtension;
