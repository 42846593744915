import QueryString from 'qs';
import {setExperienceApiUrl, setLoginLinks, setGuestCheckoutUrl, getCurrentEnvironment} from './envs';

export const EXPERIENCE_API_URL = setExperienceApiUrl();
export const LOGIN_URLS: {Enroll: string; PassReset: string; IDReminder: string} = setLoginLinks(
  EXPERIENCE_API_URL
);
export const DEBIT_CARD_ACTIVE_FLAG = true;
export const GUEST_CHECKOUT_URL = setGuestCheckoutUrl();

export const RegExStrings = {
  IsDate: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
  IsEmail: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
  IsOnlyDigits: /^\d+$/,
  IsDecimal: /^(\d{1,5}|\d{0,5}\.\d{1,2})$/,
  EveryThreeDigtis: /\B(?=(\d{3})+(?!\d))/g,
};

const getEapSettings = () => {
  const environment = getCurrentEnvironment();

  const eapSettings = {
    LoginUrl: ''
  };

  if (environment === 'dev') {
    eapSettings.LoginUrl = 'https://login-dev.rgbk.com/signin?olbenv=d3&channel=sscp&product=login'
  }
  else if (environment === 'test') {
    eapSettings.LoginUrl = 'https://login-ci.rgbk.com/signin?channel=sscp&product=login'
  }
  else if (environment === 'qa') {
    eapSettings.LoginUrl = 'https://login.regionstest.com/signin?channel=sscp&product=login'
  } 
  else if (environment === 'dr') {
    eapSettings.LoginUrl = 'https://login.regions.com/signin?channel=sscp&product=login'
  }   
  else if (environment === 'prod') {
    eapSettings.LoginUrl = 'https://login.regions.com/signin?channel=sscp&product=login'
  }  
  else {
    eapSettings.LoginUrl = 'https://login-dev.rgbk.com/signin?olbenv=d4&channel=sscp&product=login'
  }

  return eapSettings;
};

export const EAPSettings = getEapSettings();

const getTransmitSettings = () => {
 const environment = getCurrentEnvironment();
 const settings = {
   ClientId: '',
   RedirectUri: '',
   BaseUrl: '',
   QueryString: '',
   GetFullUrl: (codeChallenge) => {
    return `${settings.BaseUrl}${settings.QueryString.replace('[+CodeChallenge+]', codeChallenge)}`
   }
 }

 if (environment === 'dev') {
  settings.ClientId = 'q1891423lasf0943568sf';
  settings.BaseUrl = 'https://tsauthdev.rgbk.com:443/api/v2/oidc/oidc_dev/authorize?';
  settings.RedirectUri = 'https%3a%2f%2fselfservice-dev.regionstest.com%2fsign-in%2fcallback'
  settings.QueryString = `client_id=${settings.ClientId}&response_type=code&scope=openid+sscp&redirect_uri=${settings.RedirectUri}&nonce=n-0S6_WzA2Mj&prompt=none&code_challenge_method=S256&code_challenge=[+CodeChallenge+]`
 }
 else if (environment === 'test') {
  settings.ClientId = '129af9asdg090812096';
  settings.BaseUrl = 'https://tsqaauth.rgbk.com/api/v2/oidc/oidc/authorize?';
  settings.RedirectUri = 'https%3a%2f%2fselfservice-test.regionstest.com%2fsign-in%2fcallback'
  settings.QueryString = `client_id=${settings.ClientId}&response_type=code&scope=openid+sscp&redirect_uri=${settings.RedirectUri}&nonce=n-0S6_WzA2Mj&prompt=none&code_challenge_method=S256&code_challenge=[+CodeChallenge+]`
 }
 else if (environment === 'qa') {
  settings.ClientId = '9845aseryhwq45621oqfn';
  settings.BaseUrl = 'https://olbauth.regionstest.com/api/v2/oidc/oidc_test/authorize?';
  settings.RedirectUri = 'https%3a%2f%2fselfservice-qa.regionstest.com%2fsign-in%2fcallback'
  settings.QueryString = `client_id=${settings.ClientId}&response_type=code&scope=openid+sscp&redirect_uri=${settings.RedirectUri}&nonce=n-0S6_WzA2Mj&prompt=none&code_challenge_method=S256&code_challenge=[+CodeChallenge+]`
 } 
 else if (environment === 'dr') {
  settings.ClientId = '098agsjOPIUQT1sgfa098';
  settings.BaseUrl = 'https://olbauth.regions.com/api/v2/oidc/oidc/authorize?';
  settings.RedirectUri = 'https%3a%2f%2fselfservice.regions.com%2fsign-in%2fcallback'
  settings.QueryString = `client_id=${settings.ClientId}&response_type=code&scope=openid+sscp&redirect_uri=${settings.RedirectUri}&nonce=n-0S6_WzA2Mj&prompt=none&code_challenge_method=S256&code_challenge=[+CodeChallenge+]`
 } 
 else if (environment === 'prod') {
  settings.ClientId = '098agsjOPIUQT1sgfa098';
  settings.BaseUrl = 'https://olbauth.regions.com/api/v2/oidc/oidc/authorize?';
  settings.RedirectUri = 'https%3a%2f%2fselfservice.regions.com%2fsign-in%2fcallback'
  settings.QueryString = `client_id=${settings.ClientId}&response_type=code&scope=openid+sscp&redirect_uri=${settings.RedirectUri}&nonce=n-0S6_WzA2Mj&prompt=none&code_challenge_method=S256&code_challenge=[+CodeChallenge+]`
 } 
 else {
  settings.ClientId = 'q1891423lasf0943568sf';
  settings.BaseUrl = 'https://tsauthdev.rgbk.com:443/api/v2/oidc/oidc_dev/authorize?';
  settings.RedirectUri = 'http%3a%2f%2flocalhost%3a3000%2fsign-in%2fcallback'
  settings.QueryString = `client_id=${settings.ClientId}&response_type=code&scope=openid+sscp&redirect_uri=${settings.RedirectUri}&nonce=n-0S6_WzA2Mj&prompt=none&code_challenge_method=S256&code_challenge=[+CodeChallenge+]`
 }

 return settings;
};

export const TransmitSettings = getTransmitSettings();
