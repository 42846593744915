import React, {Fragment, useState, createRef, useEffect} from 'react';
import {useAppContext} from '../../contexts/AppContext';
import FormInput from '../common/FormInput';
import Button from '../common/Button';
import './RecoveryLanding.scss';
import {Link, useNavigate} from 'react-router-dom';
import useForm from '../common/UseForm';
import PageHeading from '../PageHeading/PageHeading';
import {recoveryValidate} from '../../services/PaymentService';
import Alert from '../Alert/Alert';
import {ResetEnsighten} from '../../ensighten/Ensighten.types';
import dispatchEvent from '../../ensighten/index';

function RecoveryLanding() {
  const [showAlert, setshowAlert] = useState<boolean>();
  const [showAccountLockedAlert, setshowAccountLockedAlert] = useState<boolean>();
  const [recoveryAcctNum, setRecoveryAcctNum] = useState('');
  const [reenterAcctNum, setReenterAcctNum] = useState('');
  const [lastName, setLastName] = useState('');
  const [ssn, setSSN] = useState('');

  const {
    setRecoveryAcctNbr, 
    setIsLoading, 
    setIsAchPaymentEnabled,
    EnsightenDetails
  } = useAppContext();
  const navigate = useNavigate();

  sessionStorage.removeItem('isRefreshed');
  sessionStorage.clear();

  useEffect(() => {
    /* Reset Ensighten Object */
    ResetEnsighten(EnsightenDetails);
    dispatchEvent(EnsightenDetails);
  }, []);

  const submitButtonHandler = (event) => {
    event.preventDefault();
    if (recoveryAcctNum !== reenterAcctNum) {
      setshowAlert(true);
    } else if (recoveryAcctNum && reenterAcctNum && lastName && ssn) {
      if (ssn.length !== 4) {
        setshowAlert(true);
      } else {
        const data = {
          transType: 'Recovery',
          acctNbr: parseInt(recoveryAcctNum).toString(),
          lastName: lastName,
          last4: ssn
        };
        setIsLoading(true);
        const response = recoveryValidate(data);
        response
          .then((res) => {
            setIsLoading(false);
            if (res.isAccountLocked === true) {
              setshowAccountLockedAlert(true);
              setshowAlert(false);
            } else if (res && res.acctStatus) {
              setRecoveryAcctNbr(res.acctNbr);
              setIsAchPaymentEnabled(res.isEligibleForAchPayment);
              setshowAlert(false);
              setshowAccountLockedAlert(false);
              navigate('/recoveryPayment');
            } else {
              setshowAlert(true);
              setshowAccountLockedAlert(false);
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      setshowAlert(true);
    }
  };

  return (
    <Fragment>
      <PageHeading title={'Regions Default Solutions'} />
      <h1 className={'sr-only'}>Regions Default Solutions</h1>
      <div className={'regions-container'}>
        {showAlert && (
          <Alert
            alertText="We couldn’t validate these details. Please try again or call 1-800-289-6720 if you believe this is in error."
            srText="Alert: We couldn’t validate these details. Please try again or call 1-800-289-6720 if you believe this is in error."
          />
        )}
        {showAccountLockedAlert && (
          <Alert
            alertText="Please call Regions Default solutions at 1-800-289-6720"
            srText="Alert: Please call Regions Default solutions at 1-800-289-6720"
          />
        )}
        <div className={'inner-block main-content recovery-landing'}>
          <form noValidate>
            <div className="form-group input-block">
              {' '}
              <h2 id="payText" className={'secondary-header mb30'}>
                Before scheduling your one-time payment, please provide the following information.
              </h2>
              <div className={'input-div'}>
                <FormInput
                  title="Account Number"
                  type="number"
                  name="recoveryAcctNum"
                  className={'form-control'}
                  value={recoveryAcctNum}
                  handleChange={(e) => setRecoveryAcctNum(e.target.value)}
                  aria-required="true"
                  aria-describedby="recoveryAcctNum"
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                />
                <FormInput
                  title="Re-enter Account Number"
                  type="number"
                  name="reenterAcctNum"
                  className={'form-control'}
                  value={reenterAcctNum}
                  handleChange={(e) => setReenterAcctNum(e.target.value)}
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                  aria-required="true"
                  aria-describedby="recoveryAcctNum"
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCut={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onDrag={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onDrop={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  autocomplete="off"
                />
                <FormInput
                  title="Last Name"
                  type="text"
                  name="lastName"
                  value={lastName}
                  handleChange={(e) => setLastName(e.target.value)}
                />
                <FormInput
                  title="Last 4 Digits of Social Security Number"
                  type="number"
                  name="recoverySSN"
                  value={ssn}
                  handleChange={(e) => setSSN(e.target.value)}
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                />
              </div>
            </div>

            <div className={'button-block'}>
              <Button
                title="Go to Payment"
                type="submit"
                className={'next-button submit-button'}
                action={submitButtonHandler}
              />
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default RecoveryLanding;
