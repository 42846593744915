import React, {FC, useState, useEffect} from 'react';
import FormInput from '../../common/FormInput';
import Select from '../../common/Select';
import {Link, useNavigate} from 'react-router-dom';
import Button from '../../common/Button';
import './PersonalInfoCard.scss';
import {useAppContext} from '../../../contexts/AppContext';
import useForm from '../../common/UseForm';
import {constants} from '../../common/Constants';
import {personalInfoValidate} from '../../common/FormValidationRules';
import {getDateFrom} from '../../Questionnaire/ExtensionUtilities';
import {saveHardshipApplicationData} from '../../../services/HardshipService';
import {CoBorrowerPersonalInfoValidate} from '../../common/FormValidationRules';
import {numberWithCommas, getAccountDisplayStr} from '../../../utilities/Utilities';
import MaksedInput from '../../common/MaksedInput';
import dispatchEvent from '../../../ensighten/index';

const PersonalInfoCard: FC = () => {
  const {
    selectedAccount,
    coBorrowerPath,
    modalProps,
    setModalProps,
    hardshipAppDetails,
    hardshipLoanDetails,
    setHardshipAppDetails,
    userToken,
    hardshipDuration,
    setUserToken,
    setIsLoading,
    hardshipReason,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  const applicant = coBorrowerPath ? 'Coborrower' : '';
  const [additionalDetails, setadditionalDetails] = useState('');
  const [submitLater, setSubmitLater] = useState<boolean>(false);
  const {values, errors, handleChange, setValues, handleSubmit, isSent, isSubmitting} = useForm(
    coBorrowerPath ? CoBorrowerPersonalInfoValidate : personalInfoValidate,
    ''
  );

  const hardshipReasons = [
    'Loss of job',
    'Military Service',
    'Became unemployed',
    'Employment income was reduced',
    'Automotive repairs or accident',
    'Medical expenses',
    'Unexpected expense',
    'Disaster',
    'Other'
  ];
  const navigate = useNavigate();

  let dob = null;

  if (applicant === 'Coborrower') {
    dob = new Date(hardshipAppDetails.coBorrowerDetails.dob);
    if (dob.toString() === 'Invalid Date') {
      dob = new Date(hardshipLoanDetails.coBorrowerDOB);
    }
  }
  else {
    dob = new Date(hardshipAppDetails.borrowerDetails.dob);
    if (dob.toString() === 'Invalid Date') {
      dob = new Date(hardshipLoanDetails.borrowerDOB);
    }
  }

  useEffect(() => {
    setValues({
      ...values,
      emailId: hardshipAppDetails.borrowerDetails.email || hardshipLoanDetails.borrowerEmail,
      emailCoborrower:
        hardshipAppDetails.coBorrowerDetails.email || hardshipLoanDetails.coBorrowerEmail,
      cellPhone: applicant
        ? hardshipAppDetails.coBorrowerDetails.CellPhoneNumber || ''
        : hardshipAppDetails.borrowerDetails.CellPhoneNumber || '',
      workPhone: applicant
        ? hardshipAppDetails.coBorrowerDetails.WorkPhoneNumber || ''
        : hardshipAppDetails.borrowerDetails.WorkPhoneNumber || '',
      homePhone: applicant
        ? hardshipAppDetails.coBorrowerDetails.HomePhoneNumber || ''
        : hardshipAppDetails.borrowerDetails.HomePhoneNumber || '',
      additionalDetails: hardshipAppDetails.HardshipDetails || additionalDetails
    });

    dispatchEvent(EnsightenDetails, true, false, false, coBorrowerPath);
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent) {
      submitHandleChange();
    }
  }, [errors]);

  const submitHandleChange = () => {
    setIsLoading(true);
    if (applicant === 'Coborrower') {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          actionType: 'update',
          hardshipAppId: hardshipAppDetails.hardshipAppId,
          coBorrowerDetails: {
            ...hardshipAppDetails.coBorrowerDetails,
            email: values.emailCoborrower,
            HomePhoneNumber: values.homePhone,
            CellPhoneNumber: values.cellPhone,
            WorkPhoneNumber: values.workPhone
          },
          LastUpdatedPageId: '12'
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('PInfo CoBorrower Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            console.log('\nPInfo Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              actionType: 'update',
              coBorrowerDetails: {
                ...hardshipAppDetails.coBorrowerDetails,
                email: values.emailCoborrower,
                HomePhoneNumber: values.homePhone,
                CellPhoneNumber: values.cellPhone,
                WorkPhoneNumber: values.workPhone
              },
              LastUpdatedPageId: '12'
            });
            setUserToken(resp.token);
            if (submitLater) {
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate('/driversLicense');
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
            // navigate('/driversLicense');
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          actionType: 'update',
          borrowerDetails: {
            ...hardshipAppDetails.borrowerDetails,
            email: values.emailId,
            HomePhoneNumber: values.homePhone,
            CellPhoneNumber: values.cellPhone,
            WorkPhoneNumber: values.workPhone
          },
          HardshipDetails: hardshipAppDetails.HardshipDetails || additionalDetails,
          LastUpdatedPageId: '2'
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('PInfo Borrower Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            console.log('\nPInfo Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              actionType: 'update',
              borrowerDetails: {
                ...hardshipAppDetails.borrowerDetails,
                email: values.emailId,
                HomePhoneNumber: values.homePhone,
                CellPhoneNumber: values.cellPhone,
                WorkPhoneNumber: values.workPhone
              },
              HardshipDetails: hardshipAppDetails.HardshipDetails || additionalDetails,
              LastUpdatedPageId: '2'
            });

            setUserToken(resp.token);
            if (submitLater) {
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate('/driversLicense');
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
            // navigate('/driversLicense');
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div className={'regions-container'}>
      {selectedAccount && (
        <div className={'personal-info-card hardship-card page-content-container'}>
          <div className="row">
            <div className={'two-column-layout'}>
              <div className="column-one">
                <h2 className="payment-header">
                  <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                  <span className={'account-number'}>{getAccountDisplayStr(selectedAccount)}</span>
                </h2>
                <div className={'well'}>
                  <div id="personal-screen">
                    <p className={'confirmation-number'}>{hardshipAppDetails.hardshipAppId}</p>
                    <p>
                      <strong>{applicant ? applicant + ' ' : ''}Personal Information</strong>
                    </p>
                    <p>
                      Your answers in the following fields will provide your personal and contact
                      information, as well as details about your particular hardship.
                    </p>
                    <p>*Fields marked with an asterisk are required.</p>
                  </div>
                </div>
              </div>

              <div className="column-two">
                <form onSubmit={handleSubmit} noValidate>
                  <FormInput
                    id={applicant ? 'emailCoborrower' : 'emailId'}
                    title={(applicant ? applicant + ' ' : '') + 'Email*'}
                    type="email"
                    name={applicant ? 'emailCoborrower' : 'emailId'}
                    value={applicant ? values.emailCoborrower : values.emailId}
                    handleChange={handleChange}
                    errors={applicant ? errors.emailCoborrower : errors.emailId}
                    maxLength={100}
                  />
                  <FormInput
                    title={(applicant ? applicant + ' ' : '') + 'Date of Birth'}
                    type="text"
                    name="dob"
                    value={dob.toLocaleDateString()}
                    disabled={true}
                  />
                  <MaksedInput
                    title={(applicant ? applicant + ' ' : '') + 'Mobile Phone Number*'}
                    name="cellPhone"
                    value={values.cellPhone}
                    mask={'(999) 999-9999'}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    errors={errors.cellPhone}
                    maskplaceholder={''}
                  />
                  <MaksedInput
                    title={(applicant ? applicant + ' ' : '') + 'Work Phone Number'}
                    name="workPhone"
                    value={values.workPhone}
                    mask={'(999) 999-9999'}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    errors={errors.workPhone}
                    maskplaceholder={''}
                  />
                  <MaksedInput
                    title={(applicant ? applicant + ' ' : '') + 'Home Phone Number'}
                    name="homePhone"
                    value={values.homePhone}
                    mask={'(999) 999-9999'}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    maskplaceholder={''}
                    errors={errors.homePhone}
                  />
                  {/* {!applicant && (
                  <Select
                    id="hardshipReasonPInfo"
                    name="hardshipReasonPInfo"
                    title="Please select a reason for your hardship*"
                    options={hardshipReasons}
                    value={values.hardshipReasonPInfo || hardshipReason}
                    placeholder={
                      hardshipAppDetails.HardshipReason
                        ? hardshipAppDetails.HardshipReason
                        : 'Select reason'
                    }
                    handleChange={handleChange}
                    errors={errors.hardshipReasonPInfo}
                  />
                )}

                {!applicant && (
                  <div className="well">
                    *Please select the Military Service in the drop down box if you are a Military
                    Service member who is currently on Active Duty within the last 12 months. For
                    additional information, please call 1-866-298-1113.
                  </div>
                )} */}
                  {!applicant && (
                    <div className="form-group">
                      <label htmlFor="additional-details">
                        <strong>Please explain your hardship.*</strong>
                      </label>
                      <textarea
                        className="form-control"
                        id="additionalDetails"
                        name="additionalDetails"
                        value={values.additionalDetails}
                        onChange={(e) => {
                          setadditionalDetails(e.target.value);
                          handleChange(e);
                        }}
                        maxLength={1000}
                      ></textarea>
                      {errors.additionalDetails && (
                        <div className="chinstrap form-control">{errors.additionalDetails}</div>
                      )}
                    </div>
                  )}
                  <div className={'column-two-buttons float-right'}>
                    <Button
                      title="Next"
                      type="submit"
                      className={'next-button float-right'}
                      action={() => setSubmitLater(false)}
                    />
                  </div>
                  <div className="float-left column-two-buttons">
                    <Button
                      title="Back"
                      className={'cancel-button float-left'}
                      action={() =>
                        applicant === 'Coborrower'
                          ? navigate('/CBhardships')
                          : navigate('/bankruptcy')
                      }
                    />
                  </div>
                  <div className="">
                    <Button
                      title="Save and Continue Later"
                      className={'third-button save-button'}
                      action={(e) => {
                        handleChange(e);
                        setSubmitLater(true);
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalInfoCard;
