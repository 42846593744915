import React from 'react';
import {useIdleTimer} from 'react-idle-timer';
import {useNavigate} from 'react-router-dom';
import {useAppContext} from '../../contexts/AppContext';
import {onLogOut} from '../../services/UserLoginService';
import {useSSCPContext} from '../../index';
interface ISessionProps {
  BaseComponent: React.ComponentType;
  IdleTime: number;
  AppSession?: boolean;
}
function SessionTimeOut(props: ISessionProps) {
  {
    const {BaseComponent, IdleTime, AppSession} = props;
    const navigate = useNavigate();
    const {userToken, setAccountList} = useAppContext();
    const {sscpState} = useSSCPContext();
    const logoutUser = () => {
      if (sscpState === 'collections') {
        const data = {
          messsage: '',
          token: userToken
        };
        const response = onLogOut(data);
        response
          .then((res) => {
            navigate('/login');
          })
          .catch((err) => console.log(err));
      } else if (sscpState === 'recovery') {
        navigate('/rds');
      } else if (sscpState === 'guestcheckout') {
        navigate('/guest');
      }
    };
    const handleOnIdle = () => {
      const dateTime = new Date(getLastActiveTime());
      if (AppSession) {
        logoutUser();
      } else {
        setAccountList([]);
        navigate('/landing');
      }
    };

    const {getLastActiveTime} = useIdleTimer({
      timeout: 1000 * 60 * IdleTime,
      onIdle: handleOnIdle,
      debounce: 500
    });

    return <BaseComponent />;
  }
}
export default SessionTimeOut;
