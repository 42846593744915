import axios from 'axios';
import {EXPERIENCE_API_URL} from '../utilities/constants';
export const savePaymentDetails = async (payObj) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(`${EXPERIENCE_API_URL}/Payment/submit`, payObj, options);
    const paymentSuccessRes = res.data.makeAPaymentStatus;
    const token = res.data.token;
    return {paymentSuccessRes, token};
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};

export const recoveryValidate = async (data) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/Collection/Accounts/validate`,
      data,
      options
    );
    const responseObj = res.data;
    return responseObj;
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};

export const guestCheckoutValidate = async (data) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/Collection/Accounts/validate/guestcheckout`,
      data,
      options
    );
    const responseObj = res.data;
    return responseObj;
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};

export const pendingPayments = async (data) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(`${EXPERIENCE_API_URL}/Payment/pending/payments`, data, options);
    const resObj = res.data;
    return resObj;
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};

export const cancelPayment = async (data) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(`${EXPERIENCE_API_URL}/Payment/cancel/payment`, data, options);
    const responseObj = res.data;
    return responseObj;
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};

export const ValidateAccountPaymentInformation = async (data) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(`${EXPERIENCE_API_URL}/Payment/validate/account/payment`, data, options);
    const responseObj = res.data;
    return responseObj;
  } catch (error) {
    console.log('Error Occured while Fetching data from server to validate account payment information:', error);
    return error;
  }
};

export const getDefaultPaymentDate = async () => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(`${EXPERIENCE_API_URL}/Payment/default/payment/date`, null, options);
    const responseObj = res.data;
    return responseObj;
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};
