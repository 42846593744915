import React from 'react';
import './Header.scss';
import regionsLogo from '../../assets/img/regionsLogo.png';
import {useNavigate} from 'react-router-dom';
import {useAppContext} from '../../contexts/AppContext';
import {onLogOut} from '../../services/UserLoginService';
import {initialLoanObjDetails, initialHardshipData} from '../Hardships/HardshipsConstants';

function Header(props) {
  const navigate = useNavigate();
  const {
    isAuthenticated,
    userToken,
    setUserToken,
    setAccountList,
    setAchStoredPymtMethods,
    setDebitStoredPymtMethods,
    setAuthorizedRegionsAccounts,
    setIsLoading,
    setHardshipAppDetails,
    setHardshipLoanDetails
  } = useAppContext();

  // const logOutHandler = () => {
  //   const data = {
  //     messsage: '',
  //     token: userToken
  //   };
  //   setIsLoading(true);
  //   const response = onLogOut(data);

  //   response.then((res) => {
  //     setIsLoading(false);
  //     navigate('/login');
  //     console.log('userToken1', userToken);
  //     setUserToken('');
  //   });
  //   setUserToken('');
  //   console.log('userToken2', userToken);
  //   setAccountList([]);
  //   setAchStoredPymtMethods([]);
  //   setDebitStoredPymtMethods([]);
  //   setAuthorizedRegionsAccounts([]);
  //   setHardshipAppDetails(initialHardshipData);
  //   setHardshipLoanDetails(initialLoanObjDetails);
  //   setUserToken('');
  // };
  return (
    <div className={'header-row'}>
      <div className={'regions-container'}>
        <nav className={'navbar'} role="navigation">
          <span className={'navbar-brand mb-0 h1'}>
            <img src={regionsLogo} alt="Regions Logo" />
          </span>
          <span className={'float-right logout-header'}>
            {userToken && userToken.trim() !== '' && (
              <button className={'signout-button'} onClick={props.logOutHandler}>
                Log Out
              </button>
            )}
          </span>
        </nav>
      </div>
    </div>
  );
}

export default Header;
