import {
  getTransactionToken,
  getDebitToken,
} from "../services/DebitCardService";
import {
  IHardshipAppDetails,
  ILoanDetailResponseObj,
  IBorrowerDetails,
} from "components/Hardships/HardshipsConstants";
import { pendingPayments } from "../services/PaymentService";
export const numberWithCommas = (num: number) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const numFormatted = num.toFixed(2).toString();
  return Number(numFormatted).toLocaleString("en", options);
};

export const getDebitCard = (
  setShowAlert,
  userToken,
  setUserToken,
  setDebitCardObj,
  setBankName,
  IsActiveCollections,
  setIsLoading,
  payAmount,
  callBack
) => {
  const data = {
    token: userToken,
    pmtAmt: parseFloat(payAmount),
    transType: IsActiveCollections ? "Collections" : "Recovery",
  };
  let parsedData;
  setIsLoading(true);
  setShowAlert(false);
  const response = getTransactionToken(data);
  response
    .then((res) => {
      setIsLoading(false);
      if (IsActiveCollections) {
        setUserToken(res.token);
      }
      const paywith = window.window["PayWithConverge"];
      const paymentFields = {
        ssl_txn_auth_token: res.lightBoxToken,
      };
      const callback = {
        onError: function (error) {
          console.log("on error:", error);
          parsedData = error;
          showResult(
            "error",
            error,
            res.token,
            setUserToken,
            setDebitCardObj,
            setBankName,
            IsActiveCollections,
            setIsLoading,
            setShowAlert,
            callBack
          );
        },
        onCancelled: function () {
          console.log("on empty:", "");
          showResult(
            "cancelled",
            "",
            res.token,
            setUserToken,
            setDebitCardObj,
            setBankName,
            IsActiveCollections,
            setIsLoading,
            setShowAlert,
            callBack
          );
        },
        onDeclined: function (response) {
          console.log("on decline:", response);
          parsedData = response;
          showResult(
            "declined",
            response,
            res.token,
            setUserToken,
            setDebitCardObj,
            setBankName,
            IsActiveCollections,
            setIsLoading,
            setShowAlert,
            callBack
          );
        },
        onApproval: function (response) {
          console.log("on approval:", response);
          parsedData = response;
          showResult(
            "approval",
            response,
            res.token,
            setUserToken,
            setDebitCardObj,
            setBankName,
            IsActiveCollections,
            setIsLoading,
            setShowAlert,
            callBack
          );
        },
      };
      paywith.open(paymentFields, callback);
    })
    .catch((err) => console.log("QWWW:", err));
  return parsedData;
};

function showResult(
  status,
  msg,
  token,
  setUserToken,
  setDebitCardObj,
  setBankName,
  IsActiveCollections,
  setIsLoading,
  setShowAlert,
  callBack
) {
  console.log("result::", msg, status);

  if (msg) {
    setDebitCardObj({ expDt: msg.ssl_exp_date, debitToken: msg.ssl_token });
    const bankNm = `Card ending in ${msg.ssl_card_number.substr(-4, 4)}`;
    setBankName(bankNm);

    if (
      msg.ssl_token &&
      msg.ssl_result_message == "APPROVAL" &&
      msg.ssl_cvv2_response == "M"
    ) {
      const payload = {
        token: token,
        transType: IsActiveCollections ? "Collections" : "Recovery",
        debitCardToken: msg.ssl_token,
      };
      setIsLoading(true);
      setShowAlert(false);
      const apiRes = getDebitToken(payload);
      apiRes
        .then((res) => {
          setIsLoading(false);
          if (IsActiveCollections) {
            setUserToken(res.token);
          }

          console.log("in api:", res);
          //console.log('in result', debitCardObj);
          if (!res.isDebitCard) {
            console.log("in api:", res.isDebitCard);
            setShowAlert(true);
          } else {
            callBack(
              msg.ssl_exp_date,
              msg.ssl_token,
              `Card ending in ${msg.ssl_token.substring(
                msg.ssl_token.length - 4
              )}`
            );
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log("in else of debit response:");
      //setUserToken(apiRes.response.data.token.token);
      setShowAlert(true);
    }
  }
}
export const setLoanDetailsToApp = (
  fetchedLoanObject: ILoanDetailResponseObj,
  hardshipApplicationData: IHardshipAppDetails
) => {
  const returnObj: IHardshipAppDetails = {
    ...hardshipApplicationData,
    Appl_ID: fetchedLoanObject.appCode,
    loanAcctNbr: fetchedLoanObject.acctNum,
    BankiD: fetchedLoanObject.bankNumber,
    autoLoan: {
      make: fetchedLoanObject.vehicleMake,
      model: fetchedLoanObject.vehicleModel,
      year: fetchedLoanObject.vehicleYear,
      vin: fetchedLoanObject.vehicleVIN,
    },
    loanType: fetchedLoanObject.lN_TYP,
    borrowerDetails: {
      ...hardshipApplicationData.borrowerDetails,
      name: fetchedLoanObject.borrowerName,
      email: fetchedLoanObject.borrowerEmail,
      ssn: fetchedLoanObject.borrowerSSN,
      mailingAddress: {
        streetAddress: fetchedLoanObject.borrowerMailingAddress,
        city: fetchedLoanObject.borrowerMailingCity,
        state: fetchedLoanObject.borrowerMailingState,
        zip: fetchedLoanObject.borrowerMailingZip,
      },
      propertyAddress: {
        streetAddress: fetchedLoanObject.borrowerPropertyAddress,
        city: fetchedLoanObject.borrowerPropertyCity,
        state: fetchedLoanObject.borrowerPropertyState,
        zip: fetchedLoanObject.borrowerPropertyZip,
      },
    },
    coBorrowerDetails: {
      ...hardshipApplicationData.coBorrowerDetails,
      name: fetchedLoanObject.coBorrowerName,
      email: fetchedLoanObject.coBorrowerEmail,
      ssn: fetchedLoanObject.coBorrowerSSN,
      mailingAddress: {
        streetAddress: fetchedLoanObject.coBorrowerMailingAddress,
        city: fetchedLoanObject.coBorrowerMailingCity,
        state: fetchedLoanObject.coBorrowerMailingState,
        zip: fetchedLoanObject.coBorrowerMailingCity,
      },
    },
  };
  return returnObj;
};

export const getPendingPayments = (
  userToken,
  selectedAccount,
  setIsLoading,
  setUserToken,
  setScheduledPayments,
  setPaymentsInProcessing,
  setNoPayFlag
) => {
  const data = {
    token: userToken,
    transType: "Collections",
    //pmtToAcctNbr: selectedAccount.delqAcctNbr
    PmtToId: selectedAccount ? selectedAccount.delId : "",
  };
  setIsLoading(true);
  const response = pendingPayments(data);
  response
    .then((res) => {
      setIsLoading(false);
      if (res.token) {
        setUserToken(res.token);
      } else {
        setUserToken(res.response.data.token);
      }
      // setUserToken(res.token);
      if (res.pendingPayments.length > 0) {
        let scheduledPaymentsArr = [];
        let paymentsInProcessingArr = [];
        res.pendingPayments.map((item) => {
          const tommorrowDt = new Date();
          tommorrowDt.setDate(new Date().getDate() + 1);

          item.pmtAmt = parseFloat(item.pmtAmt);
          const date = new Date(item.pmtDate).toLocaleDateString();

          if (new Date(item.pmtDate) > tommorrowDt) {
            item.pmtDate = date;

            scheduledPaymentsArr = [...scheduledPaymentsArr, item];
          } else {
            item.pmtDate = date;
            paymentsInProcessingArr = [...paymentsInProcessingArr, item];
          }
        });
        // scheduledPaymentsArr.sort((a, b) => {
        //   const da = new Date(a.pmtDate),
        //     db = new Date(b.pmtDate);
        //   return <any>da - <any>db;
        // });
        compareDates(scheduledPaymentsArr);
        compareDates(paymentsInProcessingArr);
        setScheduledPayments(scheduledPaymentsArr);
        setPaymentsInProcessing(paymentsInProcessingArr);
        console.log("scheduledPaymentsArr", scheduledPaymentsArr);
      } else {
        console.log("manage payments");
        setNoPayFlag(true);
        setScheduledPayments([]);
        setPaymentsInProcessing([]);
      }
      console.log("managePay res", res);
    })
    .catch((err) => console.log(err));
};

const compareDates = (paymentArr) => {
  paymentArr.sort((a, b) => {
    const da = new Date(a.pmtDate),
      db = new Date(b.pmtDate);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <any>da - <any>db;
  });
};

export const getAccountDisplayStr = (account) => {
  if (account) {
    if (account.delqProdType === "Cards" || account.delqProdType === "Card") {
      let cardStr = "";
      if (account.delqCardNbrs.includes(",")) {
        const cardArr = account.delqCardNbrs.split(",");
        let newCardArr = [];
        cardArr.map((item) => {
          item = item.substr(-4, 4);
          newCardArr = [...newCardArr, item];
        });
        cardStr = newCardArr.join(", ");
      } else {
        account.delqProdType = "Card";
        cardStr = account.delqCardNbrs.substr(-4, 4);
      }
      return "Ending in " + cardStr;
    } else {
      return "Account Ending in " + account.delqAcctNbr.substr(-4, 4);
    }
  }
};

export const getDateString = (dateStr) => {
  const returnDate = new Date(dateStr);
  return `${
    returnDate.getMonth() + 1
  }/${returnDate.getDate()}/${returnDate.getFullYear()}`;
};

export const restrictNumericInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d.-]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 20) return currentValue;
  }
};
export const useDateRange = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const minDate = new Date(year - 100, month, day);
  const maxDate = new Date(year + 100, month, day);
  return { minDate, maxDate };
};
export const setResponseToHardshipObj = (hardshipResponseObj) => {
  console.log(
    "From the Utilities method:",
    hardshipResponseObj.borrowerDetails.AnyLawsuitOne,
    hardshipResponseObj.borrowerDetails.FiledBankruptcyOne
  );

  const hardshipReturnObj: IHardshipAppDetails = {
    actionType: hardshipResponseObj.actionType || "",
    hardshipAppId: hardshipResponseObj.hardshipAppId || "",
    loanAcctNbr: hardshipResponseObj.loanAcctNbr || "",
    loanType: hardshipResponseObj.loanType || "",
    AcctId: hardshipResponseObj.acctId || "",
    HardshipReason: hardshipResponseObj.hardshipReason || "",
    HardshipDetails: hardshipResponseObj.hardshipDetails || "",
    HardshipTerm: hardshipResponseObj.hardshipTerm || "",
    LastUpdatedPageId: hardshipResponseObj.lastUpdatedPageId || "",
    BankiD: hardshipResponseObj.bankID || "",
    Appl_ID: hardshipResponseObj.appL_ID || "",
    Reason: hardshipResponseObj.hardshipReason || "",
    RequestedMonths: hardshipResponseObj.requestedMonths || "",
    autoLoan: {
      year: hardshipResponseObj.autoLoan.year || "",
      make: hardshipResponseObj.autoLoan.make || "",
      model: hardshipResponseObj.autoLoan.model || "",
      vin: hardshipResponseObj.autoLoan.vin || "",
    },
    borrowerDetails: {
      name: hardshipResponseObj.borrowerDetails.name || "",
      email: hardshipResponseObj.borrowerDetails.email || "",
      ssn: hardshipResponseObj.borrowerDetails.ssn || "",
      dob: hardshipResponseObj.borrowerDetails.dob
        ? getDateString(hardshipResponseObj.borrowerDetails.dob)
        : "",
      incOnW2: !hardshipResponseObj.borrowerDetails.incOnW2
        ? "1"
        : hardshipResponseObj.borrowerDetails.incOnW2 === "True"
        ? "1"
        : "0",
      supIncome:
        hardshipResponseObj.borrowerDetails.supIncome === "True" ? "1" : "0",
      HomePhoneNumber:
        hardshipResponseObj.borrowerDetails.homePhoneNumber || "",
      WorkPhoneNumber:
        hardshipResponseObj.borrowerDetails.workPhoneNumber || "",
      CellPhoneNumber:
        hardshipResponseObj.borrowerDetails.cellPhoneNumber || "",
      LicenseNumber: hardshipResponseObj.borrowerDetails.licenseNumber || "",
      LicenseIssueDate: hardshipResponseObj.borrowerDetails.licenseIssueDate
        ? getDateString(hardshipResponseObj.borrowerDetails.licenseIssueDate)
        : "",
      LicenseExpirationDate: hardshipResponseObj.borrowerDetails
        .licenseExpirationDate
        ? getDateString(
            hardshipResponseObj.borrowerDetails.licenseExpirationDate
          )
        : "",
      EmployerName: hardshipResponseObj.borrowerDetails.employerName || "",
      StartDateWithEmployer: hardshipResponseObj.borrowerDetails
        .startDateWithEmployer
        ? getDateString(
            hardshipResponseObj.borrowerDetails.startDateWithEmployer
          )
        : "",
      GrossMonthlyIncome:
        hardshipResponseObj.borrowerDetails.grossMonthlyIncome || "",
      UnemploymentIncome:
        hardshipResponseObj.borrowerDetails.unemploymentIncome || "",
      ChildSupport: hardshipResponseObj.borrowerDetails.childSupport || "",
      Rent: hardshipResponseObj.borrowerDetails.rent || "",
      OtherIncome: hardshipResponseObj.borrowerDetails.otherIncome || "",
      OthersIncomeType:
        hardshipResponseObj.borrowerDetails.othersIncomeType || "",
      TotalIncome: hardshipResponseObj.borrowerDetails.totalIncome || "",
      PresentValueOne:
        hardshipResponseObj.borrowerDetails.presentValueOne || "",
      AmountMortgageOne:
        hardshipResponseObj.borrowerDetails.amountMortgageOne || "",
      MontlyIncomeOne:
        hardshipResponseObj.borrowerDetails.montlyIncomeOne || "",
      MonthlyPaymentOne:
        hardshipResponseObj.borrowerDetails.monthlyPaymentOne || "",
      NetIncomeOne: hardshipResponseObj.borrowerDetails.netIncomeOne || "",
      LienspropertyOne:
        hardshipResponseObj.borrowerDetails.lienspropertyOne === "True"
          ? "1"
          : "0",
      ExplainationJudgementOne:
        hardshipResponseObj.borrowerDetails.explainationJudgementOne || "",
      AnyLawsuitOne:
        hardshipResponseObj.borrowerDetails.anyLawsuitOne === "True"
          ? "1"
          : "0",
      FiledBankruptcyOne:
        hardshipResponseObj.borrowerDetails.filedBankruptcyOne === "True"
          ? "1"
          : "0",
      CheckingAccountOne:
        hardshipResponseObj.borrowerDetails.checkingAccountOne || "",
      CheckingAccountOneInstitutionName:
        hardshipResponseObj.borrowerDetails.checkingAccountOneInstitutionName ||
        "",
      CheckingAccountOneBalance:
        hardshipResponseObj.borrowerDetails.checkingAccountOneBalance || "",
      CheckingAccountTwo:
        hardshipResponseObj.borrowerDetails.checkingAccountTwo || "",
      CheckingAccountTwoInstitutionName:
        hardshipResponseObj.borrowerDetails.checkingAccountTwoInstitutionName ||
        "",
      CheckingAccountTwoBalance:
        hardshipResponseObj.borrowerDetails.checkingAccountTwoBalance || "",
      CheckingAccountThree:
        hardshipResponseObj.borrowerDetails.checkingAccountThree || "",
      CheckingAccountThreeInstitutionName:
        hardshipResponseObj.borrowerDetails
          .checkingAccountThreeInstitutionName || "",
      CheckingAccountThreeBalance:
        hardshipResponseObj.borrowerDetails.checkingAccountThreeBalance || "",
      SavingsAccountOne:
        hardshipResponseObj.borrowerDetails.savingsAccountOne || "",
      SavingsAccountOneInstitutionName:
        hardshipResponseObj.borrowerDetails.savingsAccountOneInstitutionName ||
        "",
      SavingsAccountOneBalance:
        hardshipResponseObj.borrowerDetails.savingsAccountOneBalance || "",
      SavingsAccountTwo:
        hardshipResponseObj.borrowerDetails.savingsAccountTwo || "",
      SavingsAccountTwoInstitutionName:
        hardshipResponseObj.borrowerDetails.savingsAccountTwoInstitutionName ||
        "",
      SavingsAccountTwoBalance:
        hardshipResponseObj.borrowerDetails.savingsAccountTwoBalance || "",
      SavingsAccountThree:
        hardshipResponseObj.borrowerDetails.savingsAccountThree || "",
      SavingsAccountThreeInstitutionName:
        hardshipResponseObj.borrowerDetails
          .savingsAccountThreeInstitutionName || "",
      SavingsAccountThreeBalance:
        hardshipResponseObj.borrowerDetails.savingsAccountThreeBalance || "",
      IRAAccount: hardshipResponseObj.borrowerDetails.iRAAccount || "",
      IRAInstitution: hardshipResponseObj.borrowerDetails.iRAInstitution || "",
      IRABalance: hardshipResponseObj.borrowerDetails.iRABalance || "",
      StockIdentifier:
        hardshipResponseObj.borrowerDetails.stockIdentifier || "",
      StockInstitution:
        hardshipResponseObj.borrowerDetails.stockInstitution || "",
      StockBalance: hardshipResponseObj.borrowerDetails.stockBalance || "",
      OtherAccount: hardshipResponseObj.borrowerDetails.otherAccount || "",
      OtherAccountInstitution:
        hardshipResponseObj.borrowerDetails.otherAccountInstitution || "",
      OtherAccountBalance:
        hardshipResponseObj.borrowerDetails.otherAccountBalance || "",
      TotalAssets: hardshipResponseObj.borrowerDetails.totalAssets || "",
      mailingAddress: {
        streetAddress:
          hardshipResponseObj.borrowerDetails.mailingAddress.streetAddress ||
          "",
        city: hardshipResponseObj.borrowerDetails.mailingAddress.city || "",
        state: hardshipResponseObj.borrowerDetails.mailingAddress.state || "",
        zip: hardshipResponseObj.borrowerDetails.mailingAddress.zip || "",
      },
      propertyAddress: {
        streetAddress:
          hardshipResponseObj.borrowerDetails.propertyAddress.streetAddress ||
          "",
        city: hardshipResponseObj.borrowerDetails.propertyAddress.city || "",
        state: hardshipResponseObj.borrowerDetails.propertyAddress.state || "",
        zip: hardshipResponseObj.borrowerDetails.propertyAddress.zip || "",
      },
    },
    coBorrowerDetails: {
      name: hardshipResponseObj.coBorrowerDetails.name || "",
      email: hardshipResponseObj.coBorrowerDetails.email || "",
      ssn:
        hardshipResponseObj.coBorrowerDetails.ssn != "0"
          ? hardshipResponseObj.coBorrowerDetails.ssn
          : "0",
      dob: hardshipResponseObj.coBorrowerDetails.dob
        ? getDateString(hardshipResponseObj.coBorrowerDetails.dob)
        : "",
      incOnW2: !hardshipResponseObj.coBorrowerDetails.incOnW2
        ? "1"
        : hardshipResponseObj.coBorrowerDetails.incOnW2 === "True"
        ? "1"
        : "0",
      supIncome:
        hardshipResponseObj.coBorrowerDetails.supIncome === "True" ? "1" : "0",
      HomePhoneNumber:
        hardshipResponseObj.coBorrowerDetails.homePhoneNumber || "",
      WorkPhoneNumber:
        hardshipResponseObj.coBorrowerDetails.workPhoneNumber || "",
      CellPhoneNumber:
        hardshipResponseObj.coBorrowerDetails.cellPhoneNumber || "",
      LicenseNumber: hardshipResponseObj.coBorrowerDetails.licenseNumber || "",
      LicenseIssueDate: hardshipResponseObj.coBorrowerDetails.licenseIssueDate
        ? getDateString(hardshipResponseObj.coBorrowerDetails.licenseIssueDate)
        : "",
      LicenseExpirationDate: hardshipResponseObj.coBorrowerDetails
        .licenseExpirationDate
        ? getDateString(
            hardshipResponseObj.coBorrowerDetails.licenseExpirationDate
          )
        : "",
      EmployerName: hardshipResponseObj.coBorrowerDetails.employerName || "",
      StartDateWithEmployer: hardshipResponseObj.coBorrowerDetails
        .startDateWithEmployer
        ? getDateString(
            hardshipResponseObj.coBorrowerDetails.startDateWithEmployer
          )
        : "",
      CheckingAccountOne:
        hardshipResponseObj.coBorrowerDetails.checkingAccountOne || "",
      CheckingAccountOneInstitutionName:
        hardshipResponseObj.coBorrowerDetails
          .checkingAccountOneInstitutionName || "",
      CheckingAccountOneBalance:
        hardshipResponseObj.coBorrowerDetails.checkingAccountOneBalance || "",
      CheckingAccountTwo:
        hardshipResponseObj.coBorrowerDetails.checkingAccountTwo || "",
      CheckingAccountTwoInstitutionName:
        hardshipResponseObj.coBorrowerDetails
          .checkingAccountTwoInstitutionName || "",
      CheckingAccountTwoBalance:
        hardshipResponseObj.coBorrowerDetails.checkingAccountTwoBalance || "",
      CheckingAccountThree:
        hardshipResponseObj.coBorrowerDetails.checkingAccountThree || "",
      CheckingAccountThreeInstitutionName:
        hardshipResponseObj.coBorrowerDetails
          .checkingAccountThreeInstitutionName || "",
      CheckingAccountThreeBalance:
        hardshipResponseObj.coBorrowerDetails.checkingAccountThreeBalance || "",
      SavingsAccountOne:
        hardshipResponseObj.coBorrowerDetails.savingsAccountOne || "",
      SavingsAccountOneInstitutionName:
        hardshipResponseObj.coBorrowerDetails
          .savingsAccountOneInstitutionName || "",
      SavingsAccountOneBalance:
        hardshipResponseObj.coBorrowerDetails.savingsAccountOneBalance || "",
      SavingsAccountTwo:
        hardshipResponseObj.coBorrowerDetails.savingsAccountTwo || "",
      SavingsAccountTwoInstitutionName:
        hardshipResponseObj.coBorrowerDetails
          .savingsAccountTwoInstitutionName || "",
      SavingsAccountTwoBalance:
        hardshipResponseObj.coBorrowerDetails.savingsAccountTwoBalance || "",
      SavingsAccountThree:
        hardshipResponseObj.coBorrowerDetails.savingsAccountThree || "",
      SavingsAccountThreeInstitutionName:
        hardshipResponseObj.coBorrowerDetails
          .savingsAccountThreeInstitutionName || "",
      SavingsAccountThreeBalance:
        hardshipResponseObj.coBorrowerDetails.savingsAccountThreeBalance || "",
      IRAAccount: hardshipResponseObj.coBorrowerDetails.iRAAccount || "",
      IRAInstitution:
        hardshipResponseObj.coBorrowerDetails.iRAInstitution || "",
      IRABalance: hardshipResponseObj.coBorrowerDetails.iRABalance || "",
      StockIdentifier:
        hardshipResponseObj.coBorrowerDetails.stockIdentifier || "",
      StockInstitution:
        hardshipResponseObj.coBorrowerDetails.stockInstitution || "",
      StockBalance: hardshipResponseObj.coBorrowerDetails.stockBalance || "",
      OtherAccount: hardshipResponseObj.coBorrowerDetails.otherAccount || "",
      OtherAccountInstitution:
        hardshipResponseObj.coBorrowerDetails.otherAccountInstitution || "",
      OtherAccountBalance:
        hardshipResponseObj.coBorrowerDetails.otherAccountBalance || "",
      TotalAssets: hardshipResponseObj.coBorrowerDetails.totalAssets || "",
      GrossMonthlyIncome:
        hardshipResponseObj.coBorrowerDetails.grossMonthlyIncome || "",
      UnemploymentIncome:
        hardshipResponseObj.coBorrowerDetails.unemploymentIncome || "",
      ChildSupport: hardshipResponseObj.coBorrowerDetails.childSupport || "",
      Rent: hardshipResponseObj.coBorrowerDetails.rent || "",
      OtherIncome: hardshipResponseObj.coBorrowerDetails.otherIncome || "",
      OthersIncomeType:
        hardshipResponseObj.coBorrowerDetails.othersIncomeType || "",
      TotalIncome: hardshipResponseObj.coBorrowerDetails.totalIncome || "",
      mailingAddress: {
        streetAddress:
          hardshipResponseObj.coBorrowerDetails.mailingAddress.streetAddress ||
          "",
        city: hardshipResponseObj.coBorrowerDetails.mailingAddress.city || "",
        state: hardshipResponseObj.coBorrowerDetails.mailingAddress.state || "",
        zip: hardshipResponseObj.coBorrowerDetails.mailingAddress.zip || "",
      },
      propertyAddress: {
        streetAddress:
          hardshipResponseObj.coBorrowerDetails.propertyAddress.streetAddress ||
          "",
        city: hardshipResponseObj.coBorrowerDetails.propertyAddress.city || "",
        state:
          hardshipResponseObj.coBorrowerDetails.propertyAddress.state || "",
        zip: hardshipResponseObj.coBorrowerDetails.propertyAddress.zip || "",
      },
    },
    HousingDetails: {
      Type: hardshipResponseObj.housingDetails.type || "",
      MonthlyRental: hardshipResponseObj.housingDetails.monthlyRental || "",
      PropertyAddress: hardshipResponseObj.housingDetails.propertyAddress || "",
      PropertyType: hardshipResponseObj.housingDetails.propertyType || "",
      OccupancyType: hardshipResponseObj.housingDetails.occupancyType || "",
      InsuranceTaxes: hardshipResponseObj.housingDetails.insuranceTaxes || "",
      Explainationsituation:
        hardshipResponseObj.housingDetails.explainationsituation || "",
      DateFiling: hardshipResponseObj.housingDetails.dateFiling
        ? getDateString(hardshipResponseObj.housingDetails.dateFiling)
        : "",
    },
  };

  return hardshipReturnObj;
};

export function formatNumberToFloat(data) {
  const parsedData = parseFloat(data);
  return isNaN(parsedData)
    ? parseFloat("0.00")
    : parseFloat(parsedData.toFixed(2));
}

const formatMonth = (date) => {
  if (date.getMonth() > 8) {
    return `${date.getMonth() + 1}`;
  }

  return `0${date.getMonth() + 1}`;
};

const formatDay = (date) => {
  if (date.getDate() > 9) {
    return `${date.getDate()}`;
  }

  return `0${date.getDate()}`;
};

export const convertDateToStringMMDDYYY = (date) =>
  `${formatMonth(date)}/${formatDay(date)}/${date.getFullYear()}`;

export const convertMMDDYYYYToMMDDYY = (datestring) => {
  if (
    datestring === null ||
    datestring === undefined ||
    datestring.length !== 10
  ) {
    return "";
  }

  return `${datestring.substring(0, 6)}${datestring.substring(8, 10)}`;
};
