import {
  curoffArry,
  lntypeArray,
  pmtfrqArray,
  currentDate,
  Coll,
  Hardship,
  CheckForExtensionEligibility,
  TwoMonthsExt,
  OneMonthExt,
  appHardshipArray,
  applCollArray
} from './ExtensionConstants';

export const getDateFrom = (dateObj: string) => {
  return (
    dateObj !== null &&
    dateObj.length > 0 &&
    dateObj.substr(0, 4) + '/' + dateObj.substr(4, 2) + '/' + dateObj.substr(6, 2)
  );
};
const diffBtw = (date2, date1) => {
  let diff = (date2.getTime() - date1.getTime()) / 1000;
  diff /= 60 * 60 * 24 * 7 * 4;
  return Math.abs(Math.round(diff));
};
const decisioningEngine = (questionObj, productType, daysPastDue, loanDetails) => {
  let option = '';
  if (parseInt(daysPastDue) > 60) {
    console.log('days past due > 60');
    option = Coll;
    return option;
  } else {
    if (questionObj.employed === 'no') {
      console.log('not employed');
      option = Coll;
      return option;
    } else {
      if (questionObj.hardshipReason === 'Natural disaster') {
        console.log('Natural disaster');
        option = Coll;
        return option;
      } else {
        if (questionObj.duration === 'more') {
          if (
            // productType === 'Loan' ||
            productType === 'Auto Loan' ||
            productType === 'Unsecured Installment Loan' ||
            productType === 'Secured Installment Loan' ||
            productType === 'Secured Line of Credit' ||
            productType === 'Unsecured Line of Credit'
          ) {
            if (
              ['715', '716', '721', '724', '726', '725'].includes(loanDetails.appCode) || // OR --Installment Loans
              ['500', '500', '551', '552', '553', '554', '555'].includes(loanDetails.appCode) || //OR  -- Lines of Credit
              ['715', '716', '717', '724', '725', '726'].includes(loanDetails.appCode) //  --Auto Loans
            ) {
              if (curoffArry.includes(loanDetails.curOffCode)) {
                console.log('curoff');
                option = Coll;
                return option;
              }
              if (['063', '163', '263', '98', '198', '298'].includes(loanDetails.lN_TYP)) {
                console.log('loan type in loan types given array ');
                option = Coll;
                return option;
              }
              if (['4'].includes(loanDetails.recordCode)) {
                console.log('record code in 4');
                option = Coll;
                return option;
              }
              if (['5', '13', '14', '15', '16'].includes(loanDetails.paymentFrequency)) {
                console.log('frequency in 5,13,14,15,16');
                option = Coll;
                return option;
              } else {
                console.log('more and product types match');
                option = Hardship;
                return option;
              }
            } else {
              option = Coll;
              return option;
            }
          } else if (
            productType === 'Credit Card' ||
            productType === 'Cards' ||
            productType === 'Card'
            //productType === 'Unsecured Credit Card'
          ) {
            option = Hardship;
            return option;
          } else {
            option = Coll;
            return option;
          }
        } else {
          if (
            productType === 'Credit Card' ||
            productType === 'Cards' ||
            productType === 'Card' ||
            productType === 'Unsecured Line of Credit' ||
            productType === 'Unsecured Credit Card' ||
            productType === 'Secured Line of Credit'
          ) {
            console.log('less and credits HArdship');
            option = Hardship;
            return option;
          } else if (
            // productType === 'Loan' ||
            productType === 'Auto Loan' ||
            productType === 'Secured Installment Loan' ||
            productType === 'Unsecured Installment Loan'
          ) {
            console.log('less and Loans Check For extension');
            option = CheckForExtensionEligibility;
            return option;
          } else {
            option = Coll;
            return option;
          }
        }
      }
    }
  }
};

const checkEligibilityforExtension = (loanDetails, productType: string, daysPastDue) => {
  console.log('Entering Extension eligibility check', productType.toLowerCase());
  // --Exclude these loans from all loss mit options
  // --Exclusion filter for ACLS product types (loans)
  let option = '';
  // if (!['savings', 'checking', 'cards', 'card'].includes(productType.toLowerCase())) {
  // if (
  //   !['715', '716', '721', '724', '726', '725'].includes(loanDetails.appCode) || // OR --Installment Loans
  //   !['500', '500', '551', '552', '553', '554', '555'].includes(loanDetails.appCode) || //OR  -- Lines of Credit
  //   !['715', '716', '717', '724', '725', '726'].includes(loanDetails.appCode) //  --Auto Loans
  // ) {
  //   console.log('app code in given extenison array');
  //   option = Coll;
  //   return option;
  // }
  // Exclude cash collateral
  if (['063', '163', '263', '98', '198', '298'].includes(loanDetails.lN_TYP)) {
    console.log('Exclude cash collateral');
    option = Coll;
    return option;
  }
  // Exclude Balloon
  if (['3', '4'].includes(loanDetails.recordCode)) {
    console.log('Exclude Balloon record code in 3, 4');
    option = Coll;
    return option;
  }
  // Exclude single pay
  if (['5', '13', '14', '15', '16'].includes(loanDetails.paymentFrequency)) {
    console.log('exclude single pay, frequency in 5,13,14,15,16');
    option = Coll;
    return option;
  }
  // }

  // --Exclude these loans from all loss mit options code Ends here
  // --Exclusion filter for ACLS product types (loans) code Ends here
  if (appHardshipArray.includes(loanDetails.appCode)) {
    console.log('app code in HardshipappcodeArray');
    option = Hardship;
    return option;
  }
  if (applCollArray.includes(loanDetails.appCode)) {
    console.log(`included '402', '403', '450', '451'`);
    option = Coll;
    return option;
  }
  // exclude loans in workout
  if (curoffArry.includes(loanDetails.curOffCode)) {
    console.log('exclude loans in workout');
    option = Coll;
    return option;
  }
  // Exclude bankruptcy
  if (/^B.*/.test(loanDetails.legalStatusCode)) {
    console.log('exclude bankruptcy Pattern legal status code');
    option = Hardship;
    return option;
  }
  if (lntypeArray.includes(loanDetails.lN_TYP)) {
    option = Hardship;
    return option;
  }
  if (parseInt(loanDetails.recordCode) === 4) {
    console.log('reccde');
    option = Hardship;
    return option;
  }
  if (pmtfrqArray.includes(loanDetails.paymentFrequency)) {
    console.log('payment freq');
    option = Hardship;
    return option;
  }
  // Check valid note date.
  if (new Date(loanDetails.loanOriginationDate).toLocaleDateString() === '01/01/1900') {
    console.log('Check valid note date - 1/1/1900');
    option = Hardship;
    return option;
  }
  // Check loan not originated in last 9 months
  if (diffBtw(currentDate, new Date(loanDetails.loanOriginationDate)) < 9) {
    console.log('Check loan not originated in last 9 months');
    option = Hardship;
    return option;
  }
  // Last extension date in last 12 months.
  if (
    diffBtw(
      currentDate,
      new Date(loanDetails.lastExtensionDate !== '0' && getDateFrom(loanDetails.lastExtensionDate))
    ) < 12
  ) {
    console.log('last ext date in last 12 months');
    option = Hardship;
    return option;
  }
  // Check if they have more than one extension
  if (parseInt(loanDetails.extensionLoanToDate) > 1) {
    console.log('user has more than one extension');
    option = Hardship;
    return option;
  }
  {
    // Check for due date more than two months
    if (diffBtw(currentDate, new Date(getDateFrom(loanDetails.currentDueDate))) > 2) {
      console.log('due date is greater than 2 months');
      option = Hardship;
      return option;
    } else {
      // Select extension options based on total months extended
      if (
        parseInt(loanDetails.monthsExtendedLoanToDate) < 5 &&
        parseInt(loanDetails.monthsExtendedLoanToDate) >= 0
      ) {
        console.log('monthsExt < 5');
        
        if (parseInt(daysPastDue) > 30){
          option = OneMonthExt
        }
        else {
          option = TwoMonthsExt;
        }
        
        return option;
      }
      if (parseInt(loanDetails.monthsExtendedLoanToDate) === 5) {
        console.log('monthsExt == 5');
        option = OneMonthExt;
        return option;
      }
      if (parseInt(loanDetails.monthsExtendedLoanToDate) > 5) {
        console.log('monthsExtLoan > 5');
        option = Hardship;
        return option;
      }
    }
  }
};
export const dynamicRouting = (withQuestionObj, forProductType, daysPastDue, loanDetails) => {
  if (loanDetails === undefined || loanDetails === null) {
    return "/contactHelpDesk";
  }  
  console.log('Utilities question object', withQuestionObj);
  let option = decisioningEngine(withQuestionObj, forProductType, daysPastDue, loanDetails);
  if (option === Coll) {
    return '/contactHelpDesk';
  } else if (option === CheckForExtensionEligibility) {
    option = checkEligibilityforExtension(loanDetails, forProductType, daysPastDue);
    console.log('setting the option from CheckExtension method: ', option);
    if (option === Hardship) {
      return '/hardships';
    } else if (option === OneMonthExt) {
      return '/oneMonthExtension';
    } else if (option === TwoMonthsExt) {
      return '/twoMonthExtension';
    } else if (option === Coll) {
      return '/contactHelpDesk';
    }
  } else if (option == Hardship) {
    return '/hardships';
  }
};
