import React, {useState} from 'react';

const Input = (props) => {
  return (
    <div className="form-group">
      <label htmlFor={props.name} className={props.errors ? 'text-danger' : 'form-label'}>
        <strong>{props.title}</strong>
      </label>
      {props.prepend && (
        <div className="input-group">
          <div className="input-group-prepend">
            <span
              className={
                props.errors ? 'border border-danger input-group-text' : 'input-group-text'
              }
            >
              $
            </span>
          </div>
          <input
            className={props.errors ? 'border border-danger form-control' : 'form-control'}
            id={props.name}
            name={props.name}
            type={props.type}
            onKeyDown={(evt) => props.type === 'number' && evt.key === 'e' && evt.preventDefault()}
            value={props.value}
            onChange={props.handleChange}
            placeholder={props.placeholder}
            aria-label={props.name}
            onWheel={props.onWheel}
            maxLength={props.maxLength}
            pattern={props.pattern}
          />
        </div>
      )}
      {!props.prepend && (
        <input
          className={props.errors ? 'border border-danger form-control' : 'form-control'}
          id={props.name}
          name={props.name}
          type={props.type}
          onKeyDown={(evt) => props.type === 'number' && evt.key === 'e' && evt.preventDefault()}
          value={props.value}
          onChange={props.handleChange}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          aria-label={props.name}
          onWheel={props.onWheel}
          // onselectStart={props.onselectstart}
          onPaste={props.onPaste}
          onCopy={props.onCopy}
          onCut={props.onCut}
          onDrag={props.onDrag}
          onDrop={props.onDrop}
          autoComplete={props.autocomplete}
          maxLength={props.maxLength}
          pattern={props.pattern}
        />
      )}
      {props.errors && <div className="chinstrap form-control">{props.errors}</div>}
    </div>
  );
};

export default Input;
