import React, {Fragment} from 'react';
import './PageHeading.scss';
interface IPageHeader {
  title: string;
}
function PageHeading(props: IPageHeader) {
  const {title} = props;
  return (
    <Fragment>
      <h1 className="sr-only">{title}</h1>
      <h1 className={'page-header'}>
        <span className={'regions-container center-align'}>{title}</span>
      </h1>
    </Fragment>
  );
}

export default PageHeading;
