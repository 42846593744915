import 'babel-polyfill';
import React, {useContext, useState, Fragment} from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import 'bootstrap/scss/bootstrap.scss';
import {BrowserRouter} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import SessionTimeOut from './components/SessionTimeOut/SessionTimeOut';
import {AppSessionTimeout} from './sessiontimer.config';

interface ISSCPContext {
  sscpState: string;
  setSscpState?: React.Dispatch<string>;
  isRefreshed: string;
  setIsRefreshed?: React.Dispatch<string>;
}
const SSCPContext = React.createContext<ISSCPContext>({sscpState: '', isRefreshed: ''});
export const useSSCPContext = () => useContext(SSCPContext);
const Index = () => {
  const [sscpState, setSscpState] = useState('collections');
  const [isRefreshed, setIsRefreshed] = useState<string>(
    sessionStorage.getItem('isRefreshed') ? sessionStorage.getItem('isRefreshed') : 'false'
  );
  window.onbeforeunload = (e) => {
    setIsRefreshed('true');
    sessionStorage.setItem('isRefreshed', 'true');
  };
  const initialContextValue = {
    sscpState: sscpState,
    setSscpState: setSscpState,
    isRefreshed: isRefreshed,
    setIsRefreshed: setIsRefreshed
  };

  return (
    <Fragment>
      <SSCPContext.Provider value={initialContextValue}>
        <BrowserRouter>
          <SessionTimeOut BaseComponent={App} IdleTime={AppSessionTimeout} AppSession />
        </BrowserRouter>
      </SSCPContext.Provider>
    </Fragment>
  );
};
export default Index;

ReactDOM.render(<Index />, document.getElementById('root'));
