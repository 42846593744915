import React, {Fragment} from 'react';
import './Footer.scss';
import {useAppContext} from '../../contexts/AppContext';
import equalHousing from '../../assets/img/icons_package/icon-equal-housing-lender-full-text.svg';
import fdic from '../../assets/img/icons_package/icon-member-fdic.svg';
function Footer() {
  const {IsActiveCollections} = useAppContext();
  return (
    <Fragment>
      <div
        className={
          IsActiveCollections ? 'footer custom-footer' : 'footer custom-footer recovery-footer'
        }
      >
        <div className={'footer-container'}>
          <div className={'footer-links'}>
            <ul>
              <li>
                <a href="https://www.regions.com/about-regions" target="blank">
                  About Regions
                </a>
              </li>
              <li>
                <a href="https://ir.regions.com/" target="blank">
                  Investor Relations
                </a>
              </li>
              <li>
                <a href="https://www.regions.com/about-regions/privacy-security" target="blank">
                  Privacy &amp; Security
                </a>
              </li>
              <li>
                <a
                  href="https://www.regions.com/about-regions/privacy-security/terms-conditions"
                  target="blank"
                >
                  Website Terms of Use
                </a>
              </li>
              <li>
                <a href="https://www.regions.com/about-regions/privacy-security/online-privacy-notice" target="blank">
                  Online Tracking and Advertising
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-copyrights">
            <p>
              <a href="tel:+1(800)734-4667" id="callRegions">
                Call 1-800-REGIONS
              </a>
            </p>
            <p>
              Regions, Regions logo, LifeGreen color and LifeGreen bike are registered trademarks of
              Regions Bank.
            </p>
            <p>&copy;2022 Regions Bank. All Rights Reserved.</p>
          </div>
          <div className="footer-logos">
            <span>
              <img src={equalHousing} alt="Regions Bank" />
            </span>
            <span>
              <img src={fdic} alt="Members FDIC logo" />
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Footer;
