import axios from 'axios';
import {EXPERIENCE_API_URL} from '../utilities/constants';

export const getTransactionToken = async (data) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/Payment/lightbox/token`,
      // 'https://sscp-exp-api-dev.regionstest.com/Payment/lightbox/token',
      data,
      options
    );
    const responseObj = res.data;

    return responseObj;
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};

export const getDebitToken = async (data) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/Payment/lightbox/validate/debit/token`,
      // 'https://sscp-exp-api-dev.regionstest.com/Payment/lightbox/validate/debit/token',
      data,
      options
    );
    const responseObj = res.data;
    return responseObj;
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
  }
};
