/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {FC, useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import addDays from 'date-fns/addDays';
import {
  Link, 
  useNavigate
} from 'react-router-dom';
import {DEBIT_CARD_ACTIVE_FLAG} from '../../../utilities/constants';
import FormInput from '../../common/FormInput';
import Select from '../../common/Select';
import Button from '../../common/Button';
import useForm from '../../common/UseForm';
import Alert from '../../Alert/Alert';
import {
  validate, 
  isValidDate
} from '../../common/FormValidationRules';
import {constants} from '../../common/Constants';
import './PaymentCard.scss';
import {useAppContext} from '../../../contexts/AppContext';
import {
  numberWithCommas, 
  getDebitCard, 
  getAccountDisplayStr, 
  convertDateToStringMMDDYYY
} from '../../../utilities/Utilities';
import calendarIcon from '../../../assets/img/icons_package/icon-calendar-lines.svg';
import {getBankDetails} from '../../../services/AccountDetailsService';
import {
  pendingPayments, 
  getDefaultPaymentDate,
  ValidateAccountPaymentInformation
} from '../../../services/PaymentService';

const PaymentCard = () => {
  const [pendingPaymentsState, setPendingPaymentsState] = useState([]);
  const [startDatePlaceHolder, setStartDatePlaceHolder] = useState('');

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
  } = useForm(validate, '/authorize');

  const {
    userToken,
    setUserToken,
    selectedAccount,
    PaymentMethod,
    setPaymentMethod,
    setAchStoredPymtMethods,
    StoredPaymentMethods,
    AuthorizedRegionsAccounts,
    setPaymentDetails,
    setRoutingErrText,
    ExistingRegAcctBal,
    setExistingRegAcctBal,
    DebitStoredPymtMethods,
    IsActiveCollections,
    setIsLoading,
    setAccountList,
    HasMatchedPendingPayments,
    RoutingErrText,
    setHasMatchedPendingPayments,
  } = useAppContext();

  const {
    useExistingAccountText,
    otherSavedAcctText,
    addCheckingOrSavingText,
    addDebitCardTxt
  } = constants;

  // A7AW0 2022.02.15 - Disable external ach payments for now
  // let paymentOptions = [addCheckingOrSavingText];
  let paymentOptions = [];
                        
  if (selectedAccount.isEligibleForAchPayment) {
    paymentOptions = [addCheckingOrSavingText];
  }  
  
  const navigate = useNavigate();
  const unableToPayText = 'Unable to pay? See more options that may be available to you.';
  const alertText = 'Couldn’t verify debit card. Please check your card details and confirm it is not a credit card, or try again with another payment method.';
  
  const [isDebitCardTxt, setIsDebitCardTxt] = useState(false);
  const [existingAcctValues, setExistingAcctValues] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [bankName, setBankName] = useState('');
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showEwsAlert, setShowEwsAlert] = useState<boolean>(false);

  const [frmAcctTypeId, setFrmAcctTypeId] = useState('');
  const [existingRoutingNbr, setExistingRoutingNbr] = useState();
  const [existingAcctNbr, setExistingAcctNbr] = useState();
  const [existingAcctNumId, setExistingAcctNumId] = useState();
  const [debitCardObj, setDebitCardObj] = useState({expDt: '', debitToken: ''});

  let payOptionsForRegions = [];
  let payOptionsForOthers = [];

  if (AuthorizedRegionsAccounts.length > 0) {
    AuthorizedRegionsAccounts.filter((item) => item.authRegAcctBalance > 0).map((item) => {
      const str = item.authRegAcctName + '****' + item.authRegAcctNbr.substr(-4, 4);
      payOptionsForRegions = [...payOptionsForRegions, str];
    });
    if (payOptionsForRegions.length > 0) {
      paymentOptions = [...paymentOptions, useExistingAccountText];
    }
  }
  if (StoredPaymentMethods.length > 0 || DebitStoredPymtMethods.length > 0) {
    paymentOptions = [...paymentOptions, otherSavedAcctText];
  }

  const storedPaymentOptions = StoredPaymentMethods.map((x) => ({
    label: x.nickName,
    value: x.achId,
  }));   

  const storedDebitPaymentOptions = DebitStoredPymtMethods.map((x) => ({
    label: x.nickName,
    value: x.dbtId,
  }));   

  payOptionsForOthers.push(...storedPaymentOptions);
  payOptionsForOthers.push(...storedDebitPaymentOptions);

  if (DEBIT_CARD_ACTIVE_FLAG) {
    paymentOptions = [...paymentOptions, addDebitCardTxt];
  }

  useEffect(() => {
    setValues({
      ...values,
      amtPayVal: selectedAccount && selectedAccount.delqAcctPastDueAmt.toFixed(2)
    });

    const pendingPayloadData = {
      token: userToken,
      transType: 'Collections',
      PmtToId: selectedAccount.delId
    };
    const pendingPaymentsResponse = pendingPayments(pendingPayloadData);    

    pendingPaymentsResponse.then((pendingRes) => {
      setUserToken(pendingRes.token);
      setPendingPaymentsState(pendingRes.pendingPayments);

      const defaultDateResponse = getDefaultPaymentDate();
      defaultDateResponse
        .then((res) => {
          setStartDate(new Date(res));
          setStartDatePlaceHolder(res);
          validatePaymentDate(convertDateToStringMMDDYYY(new Date(res)), pendingRes.pendingPayments);
        });
            
    });
  }, []);

  function otherSavedAcctsHandleChange(e) {
    setExistingRegAcctBal(null);
    const selectedVal = e.target.value;

      DebitStoredPymtMethods.map((item) => {
        if (selectedVal === item.dbtId) {
          setBankName(item.dbtCardNbr);
          setExistingAcctNumId(item.dbtId);
          setDebitCardObj({expDt: item.dbtCardExp, debitToken: item.dbtCardTkn});
        }
      });

      StoredPaymentMethods.map((item) => {
        if (selectedVal === item.achId) {
          setExistingRoutingNbr(item.achStrdAcctRtngNbr);
          setExistingAcctNbr(item.achStrdAcctNbr);
          setExistingAcctNumId(item.achId);
          setBankName(item.achStrdAcctBnkName);
        }
      });    
  }

  function existingHandleChange(e) {
    setExistingAcctValues(e.target.value);
    const selectedVal = e.target.value;
    AuthorizedRegionsAccounts.map((item) => {
      if (selectedVal.substr(-4, 4) == item.authRegAcctNbr.substr(-4, 4)) {
        setExistingRegAcctBal(item.authRegAcctBalance);
        setExistingRoutingNbr(item.authRegAcctRtngNbr);
        setExistingAcctNbr(item.authRegAcctNbr);
        setExistingAcctNumId(item.authRegId);
        
        if (item.authRegAcctType == 3) {
          setFrmAcctTypeId('1');
        } else {
          setFrmAcctTypeId('2');
        }
      }
    });
  }

  const doesDateMatchPendingPaymentDate = (currentDate, pendingPaymentsArray) => {
    if (pendingPaymentsArray === null){
      pendingPaymentsArray = pendingPaymentsState;
    }

    const currentPaymentDate = new Date(currentDate);
    const matchingPendingPayment = pendingPaymentsArray.find((obj) => {
      const pendingDate = new Date(obj.pmtDate);
      return (
        pendingDate.getDate() === currentPaymentDate.getDate() &&
        pendingDate.getFullYear() === currentPaymentDate.getFullYear() &&
        pendingDate.getMonth() === currentPaymentDate.getMonth()
      );
    });

    if (matchingPendingPayment && matchingPendingPayment != null) {      
      return true;
    }

    return false;
  };  

  function validatePaymentDate(dateString, pendingPaymentsArray) {
    if (isValidDate(dateString, true)) {
      if (doesDateMatchPendingPaymentDate(dateString, pendingPaymentsArray)) {
        setHasMatchedPendingPayments(true);
        setErrors({
          ...errors,
          payDate: 'Cannot schedule a payment on same date as another payment',
        });

      }
      else {
        setHasMatchedPendingPayments(false);

        setErrors({
          ...errors,
          payDate: '',
        });
      }
    }
  }

  function handleDate(date) {
    if (date === null){
      date = new Date();
    } else {
      setStartDate(date);
    }      

    const dateString = convertDateToStringMMDDYYY(date);

    validatePaymentDate(dateString, null);
  }

  const onSelectChange = (e) => {
    e.preventDefault();
    setShowAlert(false);
    setShowEwsAlert(false);
    setExistingRegAcctBal(null);
    setExistingRoutingNbr(null);
    setExistingAcctNbr(null);
    setDebitCardObj({expDt: '', debitToken: ''});
    setExistingAcctNumId(null);
    setExistingAcctValues(null);
    
    if (values.payAcctType) {
      if (values.payAcctType === 'Checking') {
        setFrmAcctTypeId('1');
      } else {
        setFrmAcctTypeId('2');
      }
    }
    
    if (e.target.value == otherSavedAcctText) {
      //otherSavedAcctText;
    }

    if (e.target.value == addDebitCardTxt) {
      setIsDebitCardTxt(true);
    } else {
      setIsDebitCardTxt(false);
    }
  };

  const IsAccountPaymentInformationValid = () => {
    let data = 
    {
      Token: userToken,
      PaymentInfo: {
        AccountNumber: values.acctNum,
        RoutingNumber: values.routingNum,
        PaymentAmount: values.paymentAmountGC,
      },
    };

    let isValid = false;
    setIsLoading(true);
    const apiRepModel = ValidateAccountPaymentInformation(data);
    apiRepModel.then((res) => {

      if(res != null && res.data != null && res.data.token != null){
        setUserToken(res.data.token);
      }    

      if (
        res === null ||
        res.data === null ||
        res.canProceedWithPayment === null
      ) {        
        isValid = false;
        setIsLoading(false);
        setShowEwsAlert(true);
      } else {
        setIsLoading(false);
        isValid = res.canProceedWithPayment;
        setShowEwsAlert(!isValid);
        if (isValid){
          submitCallBack(null, null, null);
        }
      }
    });    
  };  

  function getBankName(e) {
    setErrors({
      ...errors,
      routingNum: '',
      acctNum: ''
    });
    setRoutingErrText('');

    if (values.routingNum && values.acctNum) {
      const data = {
        token: userToken,
        bnkRtngNbr: values.routingNum,
        bnkacctNbr: values.acctNum,
        transType: 'Collections'
      };
      const bankRes = getBankDetails(data);

      bankRes
        .then((res) => {
          if (res && res.bankDetails) {
            if (res.priorPaymentIssue) {
              setRoutingErrText(
                'Please use another account/routing number for payment as the account had issues earlier.'
              );
              setUserToken(res.token);
            } else {
              setBankName(res.bankDetails);
              setUserToken(res.token);
              setRoutingErrText('');
            }
          } else {
            setRoutingErrText('Please enter valid routing number.');
            setUserToken(res.response.data.token);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  function submitCallBack(pmtFrmDbtExpDate, pmtFrmDbtTokenNum, pmtFrmBnkName) {  
    let paymentMethodTypeId = '';

    if(values.paymentMethod == addCheckingOrSavingText){
      paymentMethodTypeId = '1';
    }
    else if(values.paymentMethod == useExistingAccountText){
      paymentMethodTypeId = '2';
    }
    else if(values.paymentMethod == addDebitCardTxt){
      paymentMethodTypeId = '3';
    }    
    
    const newPayDetails = {
      amtPay: values.amtPayVal,
      payDate: startDate || new Date(),
      existingAcctVal: existingAcctValues,
      acctName: values.acctName,
      acctNum: values.acctNum || existingAcctNbr || '',
      routingNum: values.routingNum || existingRoutingNbr || '',
      bankDetails: pmtFrmBnkName !== null ? pmtFrmBnkName : bankName,
      emailId: values.actHolderEmailId || selectedAccount.delqCustEmail,
      pmtFromId: existingAcctNumId,
      payFrmAcctId: frmAcctTypeId || '1',
      debitCardObj: pmtFrmDbtExpDate !== null ? {expDt: pmtFrmDbtExpDate, debitToken: pmtFrmDbtTokenNum} : debitCardObj,
      savePmtMthdInd: isChecked ? '1' : '0',
      pmtFrmNickname: values.savePaymentNickname,
      pmtFrmType:
        values.paymentMethod == addDebitCardTxt || bankName.includes('Card ending')
          ? 'DC'
          : existingAcctValues
          ? 'DDA'
          : 'ACH',
      paymentMethodTypeId: paymentMethodTypeId
    };
    
    setPaymentDetails(newPayDetails);
    handleSubmit(null);
  }

  function renderSavedPaymentMethodNickname() {
    if (isChecked)
    {
    return (
      <FormInput
      id="nick-name"
      title="Payment Name"
      type="text"
      name="savePaymentNickname"
      handleChange={handleChange}
      value={values.savePaymentNickname}
    />
    );
    }

    return null;
  }

  function submitForm(e) {
    if (e) e.preventDefault();
    let errorsFound = validate(values, selectedAccount, ExistingRegAcctBal, RoutingErrText, HasMatchedPendingPayments);

    if (Object.keys(errorsFound).length === 0
    && values.paymentMethod == constants.addCheckingOrSavingText){
      IsAccountPaymentInformationValid();
    } else if (Object.keys(errorsFound).length === 0) {
      setShowEwsAlert(false);
      if (isDebitCardTxt) {
        setShowAlert(false);
        const debitData = getDebitCard(
          setShowAlert,
          userToken,
          setUserToken,
          setDebitCardObj,
          setBankName,
          IsActiveCollections,
          setIsLoading,
          values.amtPayVal,
          submitCallBack
        );
      } else {
        submitCallBack(null, null, null);
      }      
    } else{
      setErrors(errorsFound);
    }
  }

  let calendar: DatePicker;
  const openDatepicker = () => calendar.setOpen(true);

  return (
    <div className={'page-content-container payCard active-collections'}>
      <div className="two-column-layout">
        <div className="column-one">
          <h2 className="payment-header">
            <span className="account-number">Current Account Details</span>
          </h2>
          <div className={'inner-block bg-grey'}>
            <h3 className="payment-header">
              <span className={'account-type'}>
                {selectedAccount && selectedAccount.delqProdType}
              </span>
              <span className={'account-number'}> {getAccountDisplayStr(selectedAccount)}</span>
            </h3>
            <div id="amt-due">
              <span>
                <strong>Amount Past Due:</strong>{' '}
              </span>
              <span>
                ${selectedAccount && numberWithCommas(selectedAccount.delqAcctPastDueAmt)}
              </span>
            </div>
            <div id="total-balance" className="amt-Details">
              <span>
                <strong>Total Balance:</strong>
              </span>
              <span>${selectedAccount && numberWithCommas(selectedAccount.delqAcctTotBal)}</span>
            </div>
            <div className={'bal-msg'}> 
              <p>This balance may not reflect all outstanding fees associated with your account.</p>
              <p>For questions concerning your total balance due, please call 1-800-333-4104.</p>
              <p></p>
            </div>
            <div id="days-past-due" className="amt-Details">
              <span>
                <strong>Days Past Due:</strong>
              </span>
              <span>{selectedAccount && selectedAccount.delqAcctDaysPastDue}</span>
            </div>
            <div id="late-payment-dt" className="amt-Details">
              <span>
                <strong>Last Paid On: </strong>
              </span>
              <span>{selectedAccount && selectedAccount.delqAcctLstPymtDt}</span>
            </div>
            <div>
              {selectedAccount &&
                selectedAccount.delqProdType !== 'Savings' &&
                selectedAccount.delqProdType !== 'Checking' && (
                  <p className={'unable-to-pay'}>
                    <Link to={'/questionnaire'}>{unableToPayText}</Link>
                  </p>
                )}
            </div>
          </div>
        </div>

        <div className="column-two">
          {showAlert && <Alert alertText={alertText} srText={alertText} />}
          {showEwsAlert && <Alert alertText={'EWS Did it'} srText={'EWS Did it'} />}
          <form onSubmit={submitForm} noValidate>
            <div className="form-group">
              <label htmlFor="amtPayVal" className={errors.amtPayVal && 'text-danger'}>
                <strong>Payment Amount</strong>
              </label>

              <div className={'input-group'}>
                <div className="input-group-prepend">
                  <span
                    className={
                      errors.amtPayVal
                        ? 'border border-danger input-group-text'
                        : 'input-group-text'
                    }
                  >
                    $
                  </span>
                </div>
                <input
                  id="amtPayVal"
                  name="amtPayVal"
                  type="number"
                  step="0.01"
                  pattern="^\d+(?:\.\d{1,2})?$"
                  className={
                    errors.amtPayVal ? 'border border-danger form-control' : 'form-control'
                  }
                  value={values.amtPayVal}
                  onChange={handleChange}
                  required
                  onBlur={(e) => {
                    setValues({
                      ...values,
                      amtPayVal: parseFloat(values.amtPayVal).toFixed(2)
                    });
                  }}
                  onWheel={(e) => {
                    e.currentTarget.blur();
                  }}
                />
              </div>
              {errors.amtPayVal && <div className="chinstrap form-control">{errors.amtPayVal}</div>}
            </div>

            <div className="form-group" >
              <label htmlFor="datePicker" className={errors.payDate && "text-danger"}>
                <strong>Payment Date</strong>
              </label>
            
              <div>
              {
                selectedAccount.sameDayPaymentOnly ? 
                (
                  <div className={'input-group '}>
                  <div className="input-group-prepend">
                    <span className={'input-group-text'}>
                      <img
                        className="img-wrapper recoveryWrapper"
                        src={calendarIcon}
                        alt="Calendar Icon"
                      />
                    </span>
                  </div>
                  <input
                    id="paymentDt"
                    name="paymentDt"
                    type="text"
                    placeholder={startDatePlaceHolder}
                    className={'form-control'}
                    disabled
                  />
                </div>
                ) : (
                  <div className={'position-rel'}>
                  <DatePicker
                    id="date-picker"
                    ref={(c) => (calendar = c)}
                    className="form-control date-picker"
                    selected={startDate}
                    placeholderText={startDatePlaceHolder}
                    onChange={handleDate}
                    minDate={new Date()}
                    maxDate={addDays(new Date(), 30)}
                    dateFormat="MM / dd / yyyy"
                    showDisabledMonthNavigation
                    aria-label="date picker"
                  />
                  <div className="image-wrapper-div">
                    <img
                      className="img-wrapper"
                      src={calendarIcon}
                      onClick={openDatepicker}
                      alt="Calendar icon"
                    />
                  </div>
                </div>
                )}
              </div>
            </div>
            {errors.payDate && (
                <div className="chinstrap form-control">{errors.payDate}</div>
                )}

            <Select
              id="payment-method"
              name="paymentMethod"
              title="Payment Method"
              options={paymentOptions}
              placeholder="Select payment method"
              handleChange={(e) => {
                handleChange(e);
                onSelectChange(e);
              }}
              errors={errors.paymentMethod}
            />

            {values.paymentMethod == useExistingAccountText &&
              AuthorizedRegionsAccounts.length > 0 &&
              payOptionsForRegions.length > 0 && (
                <div>
                  <Select
                    id="existing-account"
                    name="existingAcct"
                    options={payOptionsForRegions}
                    placeholder="Select account"
                    handleChange={(e) => {
                      handleChange(e);
                      existingHandleChange(e);
                    }}
                    errors={errors.existingAcct}
                  />
                  <FormInput
                    id="RegionsAcctHolderNm"
                    title="Account Holder Name"
                    type="text"
                    name="acctName"
                    value={values.acctName || ''}
                    handleChange={handleChange}
                    errors={errors.acctName}
                  />
                </div>
              )}
            {values.paymentMethod == otherSavedAcctText && payOptionsForOthers.length > 0 && (
              <div>
                <Select
                  id="otherSavedAcnt"
                  name="otherSavedAcnt"
                  options={payOptionsForOthers}
                  isArrayOfObjects={true}
                  placeholder="Select bank account"
                  handleChange={(e) => {
                    handleChange(e);
                    otherSavedAcctsHandleChange(e);
                  }}
                  errors={errors.otherSavedAcnt}
                />
                <FormInput
                  id="otherSavedAcctNm"
                  title="Account Holder Name"
                  type="text"
                  name="acctName"
                  value={values.acctName || ''}
                  handleChange={handleChange}
                  errors={errors.acctName}
                />
              </div>
            )}
            {values.paymentMethod == addCheckingOrSavingText && (
              <div className={'connected-inputs'}>
                <FormInput
                  id="account-holder-name"
                  title="Account Holder Name"
                  type="text"
                  name="acctName"
                  value={values.acctName || ''}
                  handleChange={handleChange}
                  errors={errors.acctName}
                />
                <FormInput
                  id="account-number"
                  title="Account Number"
                  type="text"
                  name="acctNum"
                  value={values.acctNum || ''}
                  handleChange={handleChange}
                  onBlur={getBankName}
                  errors={errors.acctNum}
                />
                <FormInput
                  id="confirmAcctNum"
                  title="Confirm Account Number"
                  type="text"
                  name="confirmAcctNum"
                  value={values.confirmAcctNum || ''}
                  handleChange={handleChange}
                  errors={errors.confirmAcctNum}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCut={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onDrag={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onDrop={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  autocomplete="off"
                />
                <FormInput
                  id="routing-number"
                  title="Routing Number"
                  type="text"
                  name="routingNum"
                  value={values.routingNum}
                  handleChange={handleChange}
                  onBlur={getBankName}
                  errors={errors.routingNum}
                />
                <FormInput
                  id="bank-name"
                  title="Bank Name"
                  type="text"
                  name="bankName"
                  value={bankName}
                  disabled={true}
                />
                <Select
                  id="payAcctType"
                  title="Account Type"
                  name="payAcctType"
                  options={['Checking', 'Savings']}
                  placeholder="Select account type:"
                  onSelect={onSelectChange}
                  handleChange={handleChange}
                />
                <div className="form-group form-check">
                  <label className="form-check-label">
                    <input
                      id="save-payment"
                      className="form-check-input"
                      type="checkbox"
                      name="savePayment"
                      checked={isChecked}
                      onClick={handleChange}
                      onChange={() => {
                        setIsChecked(!isChecked);
                      }}
                    />
                    <span className="checkmark"></span>
                    <strong> Save Payment Method</strong>
                  </label>
                </div>
                {renderSavedPaymentMethodNickname()}
              </div>
            )}
            {values.paymentMethod == addDebitCardTxt && (
              <div>
                <FormInput
                  id="debit-account-holder-name"
                  title="Account Holder Name"
                  type="text"
                  name="acctName"
                  value={values.acctName || ''}
                  handleChange={handleChange}
                  errors={errors.acctName}
                />

                <div className="form-group form-check">
                  <label className="form-check-label">
                    <input
                      id="saveDebitPayment"
                      className="form-check-input"
                      type="checkbox"
                      name="saveDebitPayment"
                      checked={isChecked}
                      onClick={handleChange}
                      onChange={() => {
                        setIsChecked(!isChecked);
                      }}
                    />
                    <span className="checkmark"></span>
                    <strong> Save Payment Method</strong>
                  </label>
                </div>
                {renderSavedPaymentMethodNickname()}
              </div>
            )}
            <FormInput
              title="Email"
              type="email"
              name="actHolderEmailId"
              defaultValue={selectedAccount && selectedAccount.delqCustEmail}
              value={values.actHolderEmailId}
              handleChange={handleChange}
              errors={errors.actHolderEmailId}
            />

            <div className="float-right column-two-buttons">
              <Button
                title="Next"
                type="submit"
                className={'next-button'}
              />
            </div>
          </form>
          <div className="float-left column-two-buttons">
            <Button
              title="Cancel"
              className=" cancel-button"
              action={() => {
                navigate('/landing');
                setAccountList([]);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
