export interface IEnsighten {
  id: string,
  page: string,
  url: string,
  subdomain: string,
  channel: string,
  accounts: string
};

export const initialEnsighten: IEnsighten = {
  id: '',
  page: '',
  url: '',
  subdomain: 'Ind',
  channel: 'self service portal',
  accounts: '0'
};

export function ResetEnsighten(ensightenEvent) {
  ensightenEvent.id = '';
  ensightenEvent.page = '';
  ensightenEvent.url = '';
  ensightenEvent.accounts = '0';
}