import React, {FC, useState, useEffect} from 'react';
import {DEBIT_CARD_ACTIVE_FLAG} from '../../../utilities/constants';
import {useNavigate} from 'react-router-dom';
import {IAccountCardProps} from '../../AccountDetails/AccountDetails.types';
import FormInput from '../../common/FormInput';
import Select from '../../common/Select';
import Button from '../../common/Button';
import useForm from '../../common/UseForm';
import {constants} from '../../common/Constants';
import {recoveryPaymentValidate} from '../../common/FormValidationRules';
import {getBankDetails} from '../../../services/AccountDetailsService';
import './RecoveryPaymentCard.scss';
import {useAppContext} from '../../../contexts/AppContext';
import {getDebitCard} from '../../../utilities/Utilities';
import calendarIcon from '../../../assets/img/icons_package/icon-calendar-lines.svg';
import Alert from '../../Alert/Alert';
import {getDefaultPaymentDate, ValidateAccountPaymentInformation} from '../../../services/PaymentService'
import dispatchEvent from '../../../ensighten/index';

const RecoveryPaymentCard = () => {
  const {values, errors, handleChange, handleSubmit, setValues, setErrors} = useForm(
    recoveryPaymentValidate,
    '/authorize'
  );
  const navigate = useNavigate();
  const {useExistingAccountText, addCheckingOrSavingText, addDebitCardTxt} = constants;
  const alertText =
    'Couldn’t verify debit card. Please check your card details and confirm it is not a credit card, or try again with another payment method.';
  const {
    PaymentMethod,
    IsActiveCollections,
    setPaymentDetails,
    setRoutingErrText,
    RecoveryAcctNbr,
    userToken,
    setUserToken,
    setIsLoading,
    EnsightenDetails,
    selectedAccount, 
    ExistingRegAcctBal, 
    RoutingErrText,
    IsAchPaymentEnabled
  } = useAppContext();

  const [startDate, setStartDate] = useState(new Date(''));
  const [startDatePlaceHolder, setStartDatePlaceHolder] = useState('');
  const [bankName, setBankName] = useState();
  const [isDebitCardTxt, setIsDebitCardTxt] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showEwsAlert, setShowEwsAlert] = useState<boolean>(false);
  const [debitCardObj, setDebitCardObj] = useState({expDt: '', debitToken: ''});
  
  // A7AW0 2022.02.15 - Disable external ach payments for now
  // let paymentOptions = [addCheckingOrSavingText];
  let paymentOptions = [];

  if (IsAchPaymentEnabled) {
    paymentOptions = [addCheckingOrSavingText];
  }
  
  useEffect(() => {
    const response = getDefaultPaymentDate();
    response
      .then((res) => {
        setStartDate(new Date(res));
        setStartDatePlaceHolder(res);
      });

  }, []);



  if (DEBIT_CARD_ACTIVE_FLAG) {
    paymentOptions = [...paymentOptions, addDebitCardTxt];
  }

  useEffect(() => {
    dispatchEvent(EnsightenDetails);
  }, []);

  const getBankName = () => {
    setErrors({
      ...errors,
      routingNum: '',
      acctNum: ''
    });
    setRoutingErrText('');
    if (values.routingNum && values.acctNum) {
      const data = {
        token: '',
        bnkRtngNbr: values.routingNum,
        bnkacctNbr: values.acctNum,
        transType: 'Recovery'
      };
      
      const bankRes = getBankDetails(data);
      bankRes
        .then((res) => {
          
          if (res && res.bankDetails) {
            if (res.priorPaymentIssue) {
              // setErrors({
              //   ...errors,
              //   acctNum: 'Please use another account for payment as the account had issues earlier.'
              // });
              setRoutingErrText(
                'Please use another account/routing number for payment as the account had issues earlier.'
              );
            } else {
              setBankName(res.bankDetails);

              setRoutingErrText('');
            }
          } else {
            console.log('in else', res.response.data);
            // setErrors({
            //   ...errors,
            //   routingNum: 'Please enter valid routing number.'
            // });
            setRoutingErrText('Please enter valid routing number.');
          }

          console.log('resPayment', res);
        })
        .catch((err) => console.log(err));
    }
  };
  const onSelectChange = (e) => {
    setShowAlert(false);
    setShowEwsAlert(false);
    setDebitCardObj({expDt: '', debitToken: ''});
    if (e.target.value == addDebitCardTxt) {
      setIsDebitCardTxt(true);
    }
    else {
      setIsDebitCardTxt(false);
    }
  };

  const IsAccountPaymentInformationValid = () => {
    let data = 
    {
      Token: userToken,
      PaymentInfo: {
        AccountNumber: values.acctNum,
        RoutingNumber: values.routingNum,
        PaymentAmount: values.paymentAmountGC,
      },
    };

    let isValid = false;
    setIsLoading(true);
    const apiRepModel = ValidateAccountPaymentInformation(data);
    apiRepModel.then((res) => {

      if(res != null && res.data != null && res.data.token != null){
        setUserToken(res.data.token);
      }    

      if (
        res === null ||
        res.data === null ||
        res.canProceedWithPayment === null
      ) {
        isValid = false;
        setIsLoading(false);
        setShowEwsAlert(true);
      } else {
        setIsLoading(false);
        isValid = res.canProceedWithPayment;
        setShowEwsAlert(!isValid);
        if (isValid){
          submitCallBack(null, null, null);
        }
      }
    });

    return isValid;
  };

  function submitCallBack(pmtFrmDbtExpDate, pmtFrmDbtTokenNum, pmtFrmBnkName) {    
    console.log('submitHandleChange2', values.amtPayVal, showAlert);

    let paymentMethodTypeId = '';

    if(values.paymentMethod == addCheckingOrSavingText){
      paymentMethodTypeId = '1';
    }
    else if(values.paymentMethod == useExistingAccountText){
      paymentMethodTypeId = '2';
    }
    else if(values.paymentMethod == addDebitCardTxt){
      paymentMethodTypeId = '3';
    }       

    const newPayDetails = {
      amtPay: values.amtPayRecovery,
      acctName: values.acctName,
      acctNum: values.acctNum || '',
      routingNum: values.routingNum || '',
      payDate: startDate,
      bankDetails: pmtFrmBnkName !== null ? pmtFrmBnkName : bankName,
      emailId: values.actHolderEmailId,
      payFrmAcctId: values.payAcctType === 'Savings' ? '2' : '1',
      debitCardObj: pmtFrmDbtExpDate !== null ? {expDt: pmtFrmDbtExpDate, debitToken: pmtFrmDbtTokenNum} : debitCardObj,
      pmtFrmType: values.paymentMethod == addDebitCardTxt ? 'DC' : 'ACH',
      paymentMethodTypeId: paymentMethodTypeId      
    };

    setPaymentDetails(newPayDetails);
    handleSubmit(null);
  }

  function submitForm(e) {
    if (e) e.preventDefault();
    let errorsFound = recoveryPaymentValidate(values, selectedAccount, ExistingRegAcctBal, RoutingErrText);

    if (Object.keys(errorsFound).length === 0
    && values.paymentMethod == constants.addCheckingOrSavingText){
      IsAccountPaymentInformationValid();
    } else if (Object.keys(errorsFound).length === 0){
      setShowEwsAlert(false);      
      if (isDebitCardTxt) {
        setShowAlert(false);
        console.log('in inside debit', e.target.value);
        getDebitCard(
          setShowAlert,
          null,
          setUserToken,
          setDebitCardObj,
          setBankName,
          IsActiveCollections,
          setIsLoading,
          parseFloat(values.amtPayRecovery),
          submitCallBack
        );
      }
      else {
        submitCallBack(null, null, null);
      }
      
    }
    else{
      setErrors(errorsFound);
    }
  }

  return (
    <div className={'account-card payment-card recovery-card'}>
      <div className="row">
        <div className={'two-column-layout'}>
          <div className="column-one">
            <h2 className="payment-header">
              <span className={'account-number'}>
                Account Ending in {RecoveryAcctNbr.substr(-4, 4)}
              </span>
            </h2>
          </div>
          <div className="column-two">
            {showAlert && <Alert alertText={alertText} srText={alertText} />}
            {showEwsAlert && <Alert alertText={'EWS Did it'} srText={'EWS Did it'} />}
            <form onSubmit={submitForm} noValidate>
              <div className="form-group">
                <label htmlFor="amtPayRecovery" className={errors.amtPayRecovery && 'text-danger'}>
                  <strong>Payment Amount</strong>
                </label>

                <div className={'input-group'}>
                  <div className="input-group-prepend">
                    <span
                      className={
                        errors.amtPayRecovery
                          ? 'border border-danger input-group-text'
                          : 'input-group-text'
                      }
                    >
                      $
                    </span>
                  </div>
                  <input
                    id="amtPayRecovery"
                    name="amtPayRecovery"
                    type="number"
                    className={
                      errors.amtPayRecovery ? 'border border-danger form-control' : 'form-control'
                    }
                    value={values.amtPayRecovery}
                    onChange={handleChange}
                    required
                    onBlur={(e) => {
                      setValues({
                        ...values,
                        amtPayRecovery: parseFloat(values.amtPayRecovery).toFixed(2)
                      });
                    }}
                    onWheel={(e) => {
                      e.currentTarget.blur();
                    }}
                  />
                </div>
                {errors.amtPayRecovery && (
                  <div className="chinstrap form-control">{errors.amtPayRecovery}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="paymentDt">
                  <strong>Payment Date</strong>
                </label>
                <div className={'input-group '}>
                  <div className="input-group-prepend">
                    <span className={'input-group-text'}>
                      <img
                        className="img-wrapper recoveryWrapper"
                        src={calendarIcon}
                        alt="Calendar Icon"
                      />
                    </span>
                  </div>
                  <input
                    id="paymentDt"
                    name="paymentDt"
                    type="text"
                    placeholder={startDatePlaceHolder}
                    className={'form-control'}
                    disabled
                  />
                </div>
              </div>

              <Select
                id="paymentMethod"
                name="paymentMethod"
                title="Payment Method"
                options={paymentOptions}
                placeholder="Select payment method"
                //onSelect={onSelectChange}
                //handleChange={handleChange}
                handleChange={(e) => {
                  handleChange(e);
                  onSelectChange(e);
                }}
                errors={errors.paymentMethod}
              />

              {values.paymentMethod == constants.addCheckingOrSavingText && (
                <div>
                  <FormInput
                    title="Account Holder Name"
                    type="text"
                    name="acctName"
                    value={values.acctName || ''}
                    handleChange={handleChange}
                    errors={errors.acctName}
                  />
                  <FormInput
                    title="Account Number"
                    type="text"
                    name="acctNum"
                    value={values.acctNum || ''}
                    handleChange={handleChange}
                    onBlur={getBankName}
                    errors={errors.acctNum}
                  />
                  <FormInput
                    id="confirmAcctNum"
                    title="Confirm Account Number"
                    type="text"
                    name="confirmAcctNum"
                    value={values.confirmAcctNum || ''}
                    handleChange={handleChange}
                    errors={errors.confirmAcctNum}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCut={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onDrag={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    autocomplete="off"
                  />
                  <FormInput
                    title="Routing Number"
                    type="text"
                    name="routingNum"
                    value={values.routingNum || ''}
                    handleChange={handleChange}
                    onBlur={getBankName}
                    errors={errors.routingNum}
                  />
                  <FormInput
                  id="bank-name"
                  title="Bank Name"
                  type="text"
                  name="bankName"
                  value={bankName}
                  disabled={true}
                />
                  <Select
                    id="payAcctType"
                    title="Account Type"
                    name="payAcctType"
                    options={['Checking', 'Savings']}
                    placeholder="Select account type"
                    handleChange={handleChange}
                  />
                </div>
              )}

              {values.paymentMethod == addDebitCardTxt && (
                <div>
                  <FormInput
                    id="debit-account-holder-name"
                    title="Account Holder Name"
                    type="text"
                    name="acctName"
                    value={values.acctName || ''}
                    handleChange={handleChange}
                    errors={errors.acctName}
                  />
                </div>
              )}
              <FormInput
                title="Email"
                type="email"
                name="actHolderEmailId"
                value={values.actHolderEmailId || ''}
                handleChange={handleChange}
                errors={errors.actHolderEmailId}
              />

              <div className="float-right column-two-buttons">
                <Button
                  title="Next"
                  type="submit"
                  className={'next-button'}
                />
              </div>
              <div className="float-left column-two-buttons">
                <Button
                  title="Cancel"
                  className={'cancel-button'}
                  action={() => {
                    navigate('/');
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecoveryPaymentCard;
