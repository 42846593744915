import React, {FC, useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Button from '../../common/Button';
import './HardshipPlanInfoCard.scss';
import {useAppContext} from '../../../contexts/AppContext';
import {numberWithCommas, getAccountDisplayStr} from '../../../utilities/Utilities';
import dispatchEvent from '../../../ensighten/index';

const HardshipPlanInfoCard = () => {
  const {
    selectedAccount,
    EnsightenDetails
  } = useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    dispatchEvent(EnsightenDetails);
  }, []);  

  return (
    <div className={'hardship-plan-info page-content-container'}>
      <div className="row">
        <div className={'two-column-layout'}>
          {selectedAccount && (
            <div className="column-one">
              <div className={'well'}>
                <h2 className="payment-header">
                  <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                  <span className={'account-number'}>{getAccountDisplayStr(selectedAccount)}</span>
                </h2>
                <div id="hardship-amt-due">
                  <span>
                    <strong>Amount Due:</strong>
                  </span>
                  <span>${numberWithCommas(selectedAccount.delqAcctPastDueAmt)}</span>
                </div>
                <div id="hardship-total-balance">
                  <span>
                    <strong>Total Balance:</strong>
                  </span>
                  <span>${numberWithCommas(selectedAccount.delqAcctTotBal)}</span>
                </div>
                <div id="hardship-days-past-due">
                  <span>
                    <strong>Days Past Due:</strong>
                  </span>
                  <span>{selectedAccount.delqAcctDaysPastDue}</span>
                </div>
                <div id="hardship-last-payment-dt">
                  <span>
                    <strong>Last Paid On:</strong>
                  </span>
                  <span>{selectedAccount.delqAcctLstPymtDt}</span>
                </div>
              </div>
            </div>
          )}

          <div className="column-two">
            <p>
              You are eligible to apply for a financial hardship plan. Once you begin the
              application you have 24 hours to complete it. You can save your progress and continue
              later, but after this period the application will expire.
            </p>
            <p>
              Regions will determine if you qualify for an extension, payment plan or other hardship
              assistance. This will include an evaluation of your current financial status and
              ability to make payments. While your application is being reviewed, you should
              continue to make payments by the scheduled due date.
            </p>

            <div className="row text-right">
              <div className="col-sm-12 col-lg-12 column-two-buttons">
                <Button
                  title="Begin Application"
                  className={'hardship-button float-right'}
                  action={() => navigate('/bankruptcy')}
                />
                <Button
                  title="Back"
                  className={'cancel-button float-left'}
                  action={() => navigate('/questionnaire')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HardshipPlanInfoCard;
