import React, {FC, useState, useEffect, Fragment} from 'react';
import useForm from '../common/UseForm';
import {noValidate} from '../common/FormValidationRules';
import Button from '../common/Button';
import {useAppContext} from '../../contexts/AppContext';
import FormInput from '../common/FormInput';
import {useNavigate} from 'react-router';
import PageHeading from '../PageHeading/PageHeading';
import {saveHardshipApplicationData} from '../../services/HardshipService';
import {
  numberWithCommas,
  formatNumberToFloat,
  getAccountDisplayStr,
  restrictNumericInput
} from '../../utilities/Utilities';
import dispatchEvent from '../../ensighten/index';

const HardshipsLiquidAssets: FC = () => {
  const {values, errors, handleChange, handleSubmit, isSubmitting, isSent} = useForm(
    noValidate,
    ''
  );
  const {
    selectedAccount,
    coBorrowerPath,
    setModalProps,
    modalProps,
    hardshipAppDetails,
    setHardshipAppDetails,
    userToken,
    setUserToken,
    setIsLoading,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  const applicant = coBorrowerPath ? 'Coborrower' : '';
  const [submitLater, setSubmitLater] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchEvent(EnsightenDetails, true, false, false, coBorrowerPath);
  }, []);
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent) {
      submitHandleChange();
    }
  }, [errors]);
  const [laState, setLaState] = useState(
    applicant === 'Coborrower'
      ? {
          Checking1: hardshipAppDetails.coBorrowerDetails.CheckingAccountOne,
          Checking1Bal: hardshipAppDetails.coBorrowerDetails.CheckingAccountOneBalance,
          Checking2: hardshipAppDetails.coBorrowerDetails.CheckingAccountTwo,
          Checking2Bal: hardshipAppDetails.coBorrowerDetails.CheckingAccountTwoBalance,
          Checking3: hardshipAppDetails.coBorrowerDetails.CheckingAccountThree,
          Checking3Bal: hardshipAppDetails.coBorrowerDetails.CheckingAccountThreeBalance,
          Savings1: hardshipAppDetails.coBorrowerDetails.SavingsAccountOne,
          Savings1Bal: hardshipAppDetails.coBorrowerDetails.SavingsAccountOneBalance,
          Savings2: hardshipAppDetails.coBorrowerDetails.SavingsAccountTwo,
          Savings2Bal: hardshipAppDetails.coBorrowerDetails.SavingsAccountTwoBalance,
          Savings3: hardshipAppDetails.coBorrowerDetails.SavingsAccountThree,
          Savings3Bal: hardshipAppDetails.coBorrowerDetails.SavingsAccountThreeBalance
        }
      : {
          Checking1: hardshipAppDetails.borrowerDetails.CheckingAccountOne,
          Checking1Bal: hardshipAppDetails.borrowerDetails.CheckingAccountOneBalance,
          Checking2: hardshipAppDetails.borrowerDetails.CheckingAccountTwo,
          Checking2Bal: hardshipAppDetails.borrowerDetails.CheckingAccountTwoBalance,
          Checking3: hardshipAppDetails.borrowerDetails.CheckingAccountThree,
          Checking3Bal: hardshipAppDetails.borrowerDetails.CheckingAccountThreeBalance,
          Savings1: hardshipAppDetails.borrowerDetails.SavingsAccountOne,
          Savings1Bal: hardshipAppDetails.borrowerDetails.SavingsAccountOneBalance,
          Savings2: hardshipAppDetails.borrowerDetails.SavingsAccountTwo,
          Savings2Bal: hardshipAppDetails.borrowerDetails.SavingsAccountTwoBalance,
          Savings3: hardshipAppDetails.borrowerDetails.SavingsAccountThree,
          Savings3Bal: hardshipAppDetails.borrowerDetails.SavingsAccountThreeBalance
        }
  );

  const submitHandleChange = () => {
    setIsLoading(true);
    if (applicant === 'Coborrower') {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          LastUpdatedPageId: '16',
          actionType: 'update',
          coBorrowerDetails: {
            ...hardshipAppDetails.coBorrowerDetails,
            CheckingAccountOne: laState.Checking1,
            CheckingAccountOneInstitutionName: laState.Checking1,
            CheckingAccountOneBalance: laState.Checking1Bal,
            CheckingAccountTwo: laState.Checking2,
            CheckingAccountTwoInstitutionName: laState.Checking2,
            CheckingAccountTwoBalance: laState.Checking2Bal,
            CheckingAccountThree: laState.Checking3,
            CheckingAccountThreeInstitutionName: laState.Checking3,
            CheckingAccountThreeBalance: laState.Checking3Bal,
            SavingsAccountOne: laState.Savings1,
            SavingsAccountOneInstitutionName: laState.Savings1,
            SavingsAccountOneBalance: laState.Savings1Bal,
            SavingsAccountTwo: laState.Savings2,
            SavingsAccountTwoInstitutionName: laState.Savings2,
            SavingsAccountTwoBalance: laState.Savings2Bal,
            SavingsAccountThree: laState.Savings3,
            SavingsAccountThreeInstitutionName: laState.Savings3,
            SavingsAccountThreeBalance: laState.Savings3Bal,
            TotalAssets: `${(
              formatNumberToFloat(laState.Checking1Bal) +
              formatNumberToFloat(laState.Checking2Bal) +
              formatNumberToFloat(laState.Checking3Bal) +
              formatNumberToFloat(laState.Savings1Bal) +
              formatNumberToFloat(laState.Savings2Bal) +
              formatNumberToFloat(laState.Savings3Bal)
            ).toFixed(2)}`
          }
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('LA CoBorrower Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            console.log('\nLA Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              LastUpdatedPageId: '16',
              actionType: 'update',
              coBorrowerDetails: {
                ...hardshipAppDetails.coBorrowerDetails,
                CheckingAccountOne: laState.Checking1,
                CheckingAccountOneInstitutionName: laState.Checking1,
                CheckingAccountOneBalance: laState.Checking1Bal,
                CheckingAccountTwo: laState.Checking2,
                CheckingAccountTwoInstitutionName: laState.Checking2,
                CheckingAccountTwoBalance: laState.Checking2Bal,
                CheckingAccountThree: laState.Checking3,
                CheckingAccountThreeInstitutionName: laState.Checking3,
                CheckingAccountThreeBalance: laState.Checking3Bal,
                SavingsAccountOne: laState.Savings1,
                SavingsAccountOneInstitutionName: laState.Savings1,
                SavingsAccountOneBalance: laState.Savings1Bal,
                SavingsAccountTwo: laState.Savings2,
                SavingsAccountTwoInstitutionName: laState.Savings2,
                SavingsAccountTwoBalance: laState.Savings2Bal,
                SavingsAccountThree: laState.Savings3,
                SavingsAccountThreeInstitutionName: laState.Savings3,
                SavingsAccountThreeBalance: laState.Savings3Bal,
                TotalAssets: `${(
                  formatNumberToFloat(laState.Checking1Bal) +
                  formatNumberToFloat(laState.Checking2Bal) +
                  formatNumberToFloat(laState.Checking3Bal) +
                  formatNumberToFloat(laState.Savings1Bal) +
                  formatNumberToFloat(laState.Savings2Bal) +
                  formatNumberToFloat(laState.Savings3Bal)
                ).toFixed(2)}`
              }
            });
            setUserToken(resp.token);

            if (submitLater) {
              setSubmitLater(false);
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate('/hardships-retirement');
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
          }
        })
        .catch((err) => console.log(err));
    } else {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          LastUpdatedPageId: '6',
          actionType: 'update',
          borrowerDetails: {
            ...hardshipAppDetails.borrowerDetails,
            CheckingAccountOne: laState.Checking1,
            CheckingAccountOneInstitutionName: laState.Checking1,
            CheckingAccountOneBalance: laState.Checking1Bal,
            CheckingAccountTwo: laState.Checking2,
            CheckingAccountTwoInstitutionName: laState.Checking2,
            CheckingAccountTwoBalance: laState.Checking2Bal,
            CheckingAccountThree: laState.Checking3,
            CheckingAccountThreeInstitutionName: laState.Checking3,
            CheckingAccountThreeBalance: laState.Checking3Bal,
            SavingsAccountOne: laState.Savings1,
            SavingsAccountOneInstitutionName: laState.Savings1,
            SavingsAccountOneBalance: laState.Savings1Bal,
            SavingsAccountTwo: laState.Savings2,
            SavingsAccountTwoInstitutionName: laState.Savings2,
            SavingsAccountTwoBalance: laState.Savings2Bal,
            SavingsAccountThree: laState.Savings3,
            SavingsAccountThreeInstitutionName: laState.Savings3,
            SavingsAccountThreeBalance: laState.Savings3Bal,
            TotalAssets: `${(
              formatNumberToFloat(laState.Checking1Bal) +
              formatNumberToFloat(laState.Checking2Bal) +
              formatNumberToFloat(laState.Checking3Bal) +
              formatNumberToFloat(laState.Savings1Bal) +
              formatNumberToFloat(laState.Savings2Bal) +
              formatNumberToFloat(laState.Savings3Bal)
            ).toFixed(2)}`
          }
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('LiquidAsets Borrower Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            console.log('\nLiquid Assets Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              LastUpdatedPageId: '6',
              actionType: 'update',
              borrowerDetails: {
                ...hardshipAppDetails.borrowerDetails,
                CheckingAccountOne: laState.Checking1,
                CheckingAccountOneInstitutionName: laState.Checking1,
                CheckingAccountOneBalance: laState.Checking1Bal,
                CheckingAccountTwo: laState.Checking2,
                CheckingAccountTwoInstitutionName: laState.Checking2,
                CheckingAccountTwoBalance: laState.Checking2Bal,
                CheckingAccountThree: laState.Checking3,
                CheckingAccountThreeInstitutionName: laState.Checking3,
                CheckingAccountThreeBalance: laState.Checking3Bal,
                SavingsAccountOne: laState.Savings1,
                SavingsAccountOneInstitutionName: laState.Savings1,
                SavingsAccountOneBalance: laState.Savings1Bal,
                SavingsAccountTwo: laState.Savings2,
                SavingsAccountTwoInstitutionName: laState.Savings2,
                SavingsAccountTwoBalance: laState.Savings2Bal,
                SavingsAccountThree: laState.Savings3,
                SavingsAccountThreeInstitutionName: laState.Savings3,
                SavingsAccountThreeBalance: laState.Savings3Bal,
                TotalAssets: `${(
                  formatNumberToFloat(laState.Checking1Bal) +
                  formatNumberToFloat(laState.Checking2Bal) +
                  formatNumberToFloat(laState.Checking3Bal) +
                  formatNumberToFloat(laState.Savings1Bal) +
                  formatNumberToFloat(laState.Savings2Bal) +
                  formatNumberToFloat(laState.Savings3Bal)
                ).toFixed(2)}`
              }
            });
            setUserToken(resp.token);
            if (submitLater) {
              setSubmitLater(false);
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate('/hardships-retirement');
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Fragment>
      <PageHeading title="Liquid Assets - Checking and Savings Account"></PageHeading>
      {selectedAccount && (
        <div className={' regions-container'}>
          <div className={'page-content-container hardship-card'}>
            <div className="row">
              <div className={'two-column-layout'}>
                <div className="column-one">
                  <h2 className="payment-header">
                    <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                    <span className={'account-number'}>
                      {getAccountDisplayStr(selectedAccount)}
                    </span>
                  </h2>
                  <div className={'well'}>
                    <div id="income">
                      <p className={'confirmation-number'}>{hardshipAppDetails.hardshipAppId}</p>
                      <p>
                        <strong>
                          {applicant ? applicant + ' ' : ''}Liquid Assets - Checking and Savings
                          Account
                        </strong>
                      </p>
                      <p>
                        Your answers in the following fields will provide information about your
                        current financial accounts. If a field does not apply, you can leave it
                        blank.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="column-two">
                  <form onSubmit={handleSubmit} noValidate className={'connected-groups'}>
                    <FormInput
                      id="checking-1"
                      title={
                        (applicant ? applicant + ' ' : '') +
                        'Checking Account 1 Financial Institution'
                      }
                      type="text"
                      name="Checking Account 1 Financial Institution"
                      value={laState.Checking1}
                      handleChange={(event) => {
                        setLaState({...laState, Checking1: event.target.value});
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="checking-1-amount"
                      title={(applicant ? applicant + ' ' : '') + 'Checking Account 1 Balance'}
                      type="text"
                      name="Checking Account 1 Balance"
                      value={laState.Checking1Bal}
                      handleChange={(event) => {
                        setLaState({
                          ...laState,
                          Checking1Bal: restrictNumericInput(
                            event.target.value,
                            laState.Checking1Bal
                          )
                        });
                      }}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="checking-2"
                      title={
                        (applicant ? applicant + ' ' : '') +
                        'Checking Account 2 Financial Institution'
                      }
                      type="text"
                      name="Checking Account 2 Financial Institution"
                      value={laState.Checking2}
                      handleChange={(event) => {
                        setLaState({...laState, Checking2: event.target.value});
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="checking-2-amount"
                      title={(applicant ? applicant + ' ' : '') + 'Checking Account 2 Balance'}
                      type="text"
                      name="Checking Account 2 Balance"
                      value={laState.Checking2Bal}
                      handleChange={(event) => {
                        setLaState({
                          ...laState,
                          Checking2Bal: restrictNumericInput(
                            event.target.value,
                            laState.Checking2Bal
                          )
                        });
                      }}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="checking-3"
                      title={
                        (applicant ? applicant + ' ' : '') +
                        'Checking Account 3 Financial Institution'
                      }
                      type="text"
                      name="Checking Account 3 Financial Institution"
                      value={laState.Checking3}
                      handleChange={(event) => {
                        setLaState({...laState, Checking3: event.target.value});
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="checking-3-amount"
                      title={(applicant ? applicant + ' ' : '') + 'Checking Account 3 Balance'}
                      type="text"
                      name="Checking Account 3 Balance"
                      value={laState.Checking3Bal}
                      handleChange={(event) => {
                        setLaState({
                          ...laState,
                          Checking3Bal: restrictNumericInput(
                            event.target.value,
                            laState.Checking3Bal
                          )
                        });
                      }}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="savings-1"
                      title={
                        (applicant ? applicant + ' ' : '') +
                        'Savings / Money Market 1 Financial Institution'
                      }
                      type="text"
                      name="Savings / Money Market 1 Financial Institution"
                      value={laState.Savings1}
                      handleChange={(event) => {
                        setLaState({...laState, Savings1: event.target.value});
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="savings-1-amount"
                      title={
                        (applicant ? applicant + ' ' : '') + 'Savings / Money Market 1 Balance'
                      }
                      type="text"
                      name="Savings / Money Market 1 Balance"
                      value={laState.Savings1Bal}
                      handleChange={(event) => {
                        setLaState({
                          ...laState,
                          Savings1Bal: restrictNumericInput(event.target.value, laState.Savings1Bal)
                        });
                      }}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="checking-1"
                      title={
                        (applicant ? applicant + ' ' : '') +
                        'Savings / Money Market 2 Financial Institution'
                      }
                      type="text"
                      name="Savings / Money Market 2 Financial Institution"
                      value={laState.Savings2}
                      handleChange={(event) => {
                        setLaState({...laState, Savings2: event.target.value});
                      }}
                    />

                    <FormInput
                      id="savings-2-amount"
                      title={
                        (applicant ? applicant + ' ' : '') + 'Savings / Money Market 2 Balance'
                      }
                      type="text"
                      name="Savings / Money Market 2 Balance"
                      value={laState.Savings2Bal}
                      handleChange={(event) => {
                        setLaState({
                          ...laState,
                          Savings2Bal: restrictNumericInput(event.target.value, laState.Savings2Bal)
                        });
                      }}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="savings-3"
                      title={
                        (applicant ? applicant + ' ' : '') +
                        'Savings / Money Market 3 Financial Institution'
                      }
                      type="text"
                      name="Savings / Money Market 3 Financial Institution"
                      value={laState.Savings3}
                      handleChange={(event) => {
                        setLaState({...laState, Savings3: event.target.value});
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="savings-3-amount"
                      title={
                        (applicant ? applicant + ' ' : '') + 'Savings / Money Market 3 Balance'
                      }
                      type="text"
                      name="Savings / Money Market 3 Balance"
                      value={laState.Savings3Bal}
                      handleChange={(event) => {
                        setLaState({
                          ...laState,
                          Savings3Bal: restrictNumericInput(event.target.value, laState.Savings3Bal)
                        });
                      }}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />
                    <div className={'column-two-buttons float-right'}>
                      <Button
                        title="Next"
                        type="submit"
                        className={'next-button float-right'}
                        action={() => setSubmitLater(false)}
                      />
                    </div>
                    <div className="float-left column-two-buttons">
                      <Button
                        title="Back"
                        className="cancel-button float-left"
                        action={() => {
                          navigate('/hardships-income');
                        }}
                      />
                    </div>
                    <div className="">
                      <Button
                        title="Save and Continue Later"
                        className={'third-button save-button'}
                        action={(e) => {
                          handleSubmit(e);
                          setSubmitLater(true);
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default HardshipsLiquidAssets;
