export const applCollArray = ['402', '403', '450', '451'];
export const curoffArry = ['Z99M', 'Z99R', 'Z97', 'Z92', 'Z90'];
export const appHardshipArray = [
  '205',
  '333',
  '452',
  '453',
  '454',
  '455',
  '456',
  '457',
  '458',
  '459',
  '460',
  '461',
  '462',
  '463',
  '464',
  '465',
  '466',
  '467',
  '468',
  '469',
  '470',
  '471',
  '472',
  '473',
  '474',
  '475',
  '476',
  '477',
  '478',
  '479',
  '480',
  '481',
  '482',
  '483',
  '484',
  '485',
  '486',
  '487',
  '488',
  '489',
  '490',
  '491',
  '492',
  '493',
  '494',
  '495',
  '496',
  '497',
  '498',
  '499',
  '500',
  '501',
  '502',
  '503',
  '504',
  '505',
  '506',
  '507',
  '508',
  '509',
  '510',
  '511',
  '512',
  '513',
  '514',
  '515',
  '516',
  '517',
  '518',
  '519',
  '520',
  '521',
  '522',
  '523',
  '524',
  '525',
  '526',
  '527',
  '528',
  '529',
  '530',
  '531',
  '532',
  '533',
  '534',
  '535',
  '536',
  '537',
  '538',
  '539',
  '540',
  '541',
  '542',
  '543',
  '544',
  '545',
  '546',
  '547',
  '548',
  '549',
  '550',
  '551',
  '552',
  '553',
  '554',
  '555',
  '607',
  '608',
  '609',
  '610',
  '611',
  '612',
  '613',
  '614',
  '615',
  '616',
  '617',
  '618',
  '619',
  '620',
  '621',
  '622',
  '623',
  '624',
  '625',
  '626',
  '627',
  '628',
  '629',
  '630',
  '631',
  '632',
  '633',
  '634',
  '635',
  '636',
  '637',
  '638',
  '639',
  '640',
  '641',
  '642',
  '643',
  '644',
  '645',
  '646',
  '647',
  '648',
  '649',
  '650',
  '651',
  '652',
  '653',
  '654',
  '655',
  '656',
  '657',
  '658',
  '659',
  '660',
  '661',
  '662',
  '663',
  '664',
  '665',
  '666',
  '667',
  '668',
  '669',
  '670',
  '671',
  '672',
  '673',
  '674',
  '675',
  '676',
  '677',
  '678',
  '679',
  '680',
  '681',
  '682',
  '683',
  '684',
  '685',
  '686',
  '687',
  '688',
  '689',
  '690',
  '691',
  '692',
  '693',
  '694',
  '695',
  '696',
  '697',
  '698',
  '699',
  '700',
  '701',
  '702',
  '703',
  '704',
  '705',
  '706',
  '707',
  '708',
  '709',
  '719',
  '720',
  '710',
  '713',
  '729',
  '730',
  '731',
  '732',
  '734',
  '735',
  '736',
  '737',
  '738',
  '739'
];
export const lntypeArray = ['63', '163', '263', '98', '198', '298'];
export const pmtfrqArray = ['5', '13', '14', '15', '16'];
export const currentDate = new Date();

export const Hardship = 'Hardship';
export const Coll = 'Coll';
export const CheckForExtensionEligibility = 'Check for Extension Eligibility';
export const OneMonthExt = 'one-month-extension';
export const TwoMonthsExt = 'two-months-extension';
const loanAccountDetails = {
  loanDetail: [
    {
      acctNum: '000000000000881805',
      borrowerName: 'FAIRBANKS, JEFFREY',
      borrowerDOB: '19601119',
      borrowerSSN: '431230453',
      coBorrowerName: '',
      coBorrowerDOB: '0',
      coBorrowerSSN: '0',
      borrowerEmail: 'jpsairbank@att.net',
      coBorrowerEmail: '',
      borrowerMailingAddress: '2120 COASTAL DRIVE',
      borrowerMailingCity: 'PRINCETON',
      borrowerMailingState: 'LA',
      borrowerMailingZip: '71067',
      borrowerPropertyAddress: '',
      borrowerPropertyCity: '',
      borrowerPropertyState: '',
      borrowerPropertyZip: '',
      coBorrowerMailingAddress: '',
      coBorrowerMailingCity: '',
      coBorrowerMailingState: '',
      coBorrowerMailingZip: '',
      productType: 'Loan',
      vehicleYear: '2004',
      vehicleMake: 'SKEETER',
      vehicleModel: 'ZX225',
      vehicleVIN: 'STE71714D404',
      loanOriginationDate: '04/06/2005',
      legalStatusCode: '',
      appCode: '716',
      lastExtensionDate: '20091113',
      extensionLoanToDate: '1',
      monthsExtendedLoanToDate: '6',
      currentDueDate: '20201103',
      paymentFrequency: '1',
      recordCode: '2',
      daysPastDue: '14',
      bankNumber: '1',
      curOffCode: '8',
      lntype: '737'
    }
  ]
};
export const loanDetails = loanAccountDetails.loanDetail[0];
