import React, {FC, useState, useEffect, Fragment} from 'react';
import DatePicker from 'react-datepicker';
import addDays from 'date-fns/addDays';
import {Link, useNavigate} from 'react-router-dom';
import useForm from '../common/UseForm';
import {addressValidate} from '../common/FormValidationRules';
import {numberWithCommas, getAccountDisplayStr} from '../../utilities/Utilities';
import Select from '../common/Select';
import {constants} from '../common/Constants';
import Button from '../common/Button';
import {useAppContext} from '../../contexts/AppContext';
import FormInput from '../common/FormInput';
import {statesOfUSA, IHardshipProps} from './HardshipsConstants';
import PageHeading from '../PageHeading/PageHeading';
import {saveHardshipApplicationData} from '../../services/HardshipService';
import dispatchEvent from '../../ensighten/index';

const HardshipsAddress: FC = () => {
  const {
    selectedAccount,
    coBorrowerPath,
    modalProps,
    setModalProps,
    hardshipAppDetails,
    setHardshipAppDetails,
    userToken,
    setUserToken,
    setIsLoading,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  const applicant = coBorrowerPath ? 'Coborrower' : '';
  const {values, errors, handleChange, handleSubmit, setValues, isSubmitting, isSent} = useForm(
    addressValidate,
    ''
  );
  const [submitLater, setSubmitLater] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (isChecked) {
      setValues({
        ...values,
        propertyStreet: values.mailingStreet,
        propertyCity: values.mailingCity,
        propertyState: values.mailingState,
        propertyZip: values.mailingZip
      });
    } else {
      setValues({
        ...values,
        propertyStreet: '',
        propertyCity: '',
        propertyState: '',
        propertyZip: ''
      });
    }
  }, [isChecked]);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchEvent(EnsightenDetails, true, false, false, coBorrowerPath);
  }, []);
  useEffect(() => {
    applicant === 'Coborrower'
      ? setValues({
          ...values,
          mailingStreet: hardshipAppDetails.coBorrowerDetails.mailingAddress.streetAddress,
          mailingCity: hardshipAppDetails.coBorrowerDetails.mailingAddress.city,
          mailingState: hardshipAppDetails.coBorrowerDetails.mailingAddress.state,
          mailingZip:
            hardshipAppDetails.coBorrowerDetails.mailingAddress.zip &&
            hardshipAppDetails.coBorrowerDetails.mailingAddress.zip.length > 5
              ? hardshipAppDetails.coBorrowerDetails.mailingAddress.zip.substr(0, 5)
              : hardshipAppDetails.coBorrowerDetails.mailingAddress.zip,

          propertyStreet: hardshipAppDetails.coBorrowerDetails.propertyAddress.streetAddress,
          propertyCity: hardshipAppDetails.coBorrowerDetails.propertyAddress.city,
          propertyState: hardshipAppDetails.coBorrowerDetails.propertyAddress.state,
          propertyZip:
            hardshipAppDetails.coBorrowerDetails.propertyAddress.zip &&
            hardshipAppDetails.coBorrowerDetails.propertyAddress.zip.length > 5
              ? hardshipAppDetails.coBorrowerDetails.propertyAddress.zip.substr(0, 5)
              : hardshipAppDetails.coBorrowerDetails.propertyAddress.zip
        })
      : setValues({
          mailingStreet: hardshipAppDetails.borrowerDetails.mailingAddress.streetAddress,
          mailingCity: hardshipAppDetails.borrowerDetails.mailingAddress.city,
          mailingState: hardshipAppDetails.borrowerDetails.mailingAddress.state,
          mailingZip:
            hardshipAppDetails.borrowerDetails.mailingAddress.zip &&
            hardshipAppDetails.borrowerDetails.mailingAddress.zip.length > 5
              ? hardshipAppDetails.borrowerDetails.mailingAddress.zip.substr(0, 5)
              : hardshipAppDetails.borrowerDetails.mailingAddress.zip,
          propertyStreet: hardshipAppDetails.borrowerDetails.propertyAddress.streetAddress,
          propertyCity: hardshipAppDetails.borrowerDetails.propertyAddress.city,
          propertyState: hardshipAppDetails.borrowerDetails.propertyAddress.state,
          propertyZip:
            hardshipAppDetails.borrowerDetails.propertyAddress.zip &&
            hardshipAppDetails.borrowerDetails.propertyAddress.zip.length > 5
              ? hardshipAppDetails.borrowerDetails.propertyAddress.zip.substr(0, 5)
              : hardshipAppDetails.borrowerDetails.propertyAddress.zip
        });
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent) {
      submitHandleChange();
    }
  }, [errors]);

  const navigate = useNavigate();
  const submitHandleChange = () => {
    setIsLoading(true);
    if (applicant === 'Coborrower') {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          actionType: 'update',
          LastUpdatedPageId: '14',
          coBorrowerDetails: {
            ...hardshipAppDetails.coBorrowerDetails,
            mailingAddress: {
              streetAddress: values.mailingStreet,
              city: values.mailingCity,
              state: values.mailingState,
              zip: values.mailingZip
            },
            propertyAddress: {
              streetAddress: values.propertyStreet,
              city: values.propertyCity,
              state: values.propertyState,
              zip: values.propertyZip
            }
          }
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('address CoBorrower Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            setUserToken(resp.token);
            console.log('\nAddress Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              actionType: 'update',
              LastUpdatedPageId: '14',
              coBorrowerDetails: {
                ...hardshipAppDetails.coBorrowerDetails,
                mailingAddress: {
                  streetAddress: values.mailingStreet,
                  city: values.mailingCity,
                  state: values.mailingState,
                  zip: values.mailingZip
                },
                propertyAddress: {
                  streetAddress: values.propertyStreet,
                  city: values.propertyCity,
                  state: values.propertyState,
                  zip: values.propertyZip
                }
              }
            });
            if (submitLater) {
              setSubmitLater(false);
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate('/hardships-income');
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
            // history.push('/hardships-income');
          }
        })
        .catch((err) => console.log(err));
    } else {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          LastUpdatedPageId: '4',
          actionType: 'update',
          borrowerDetails: {
            ...hardshipAppDetails.borrowerDetails,
            mailingAddress: {
              streetAddress: values.mailingStreet,
              city: values.mailingCity,
              state: values.mailingState,
              zip: values.mailingZip
            },
            propertyAddress: {
              streetAddress: values.propertyStreet,
              city: values.propertyCity,
              state: values.propertyState,
              zip: values.propertyZip
            }
          }
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('address Borrower Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            setUserToken(resp.token);
            console.log('\nAddress Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              actionType: 'update',
              LastUpdatedPageId: '4',
              borrowerDetails: {
                ...hardshipAppDetails.borrowerDetails,
                mailingAddress: {
                  streetAddress: values.mailingStreet,
                  city: values.mailingCity,
                  state: values.mailingState,
                  zip: values.mailingZip
                },
                propertyAddress: {
                  streetAddress: values.propertyStreet,
                  city: values.propertyCity,
                  state: values.propertyState,
                  zip: values.propertyZip
                }
              }
            });
            if (submitLater) {
              setSubmitLater(false);
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate('/hardships-income');
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
            // history.push('/hardships-income');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Fragment>
      <PageHeading title="Address"></PageHeading>
      {selectedAccount && (
        <div className="regions-container">
          <div className={'page-content-container hardship-card'}>
            <div className="row">
              <div className={'two-column-layout'}>
                <div className="column-one">
                  <h2 className="payment-header">
                    <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                    <span className={'account-number'}>
                      {getAccountDisplayStr(selectedAccount)}
                    </span>
                  </h2>
                  <div className={'well'}>
                    <div id="address">
                      <p className={'confirmation-number'}>{hardshipAppDetails.hardshipAppId}</p>
                      <p>
                        <strong>{applicant ? applicant + ' ' : ''}Address</strong>
                      </p>
                      <p>
                        Your answers in the following fields will provide additional personal and
                        contact information.
                      </p>
                      <p>*Fields marked with an asterisk are required.</p>
                    </div>
                  </div>
                </div>

                <div className="column-two">
                  <form onSubmit={handleSubmit} noValidate>
                    <FormInput
                      id="mailing-street-address"
                      title={(applicant ? applicant + ' ' : '') + 'Mailing Address Street*'}
                      type="text"
                      name={'mailingStreet'}
                      value={values.mailingStreet}
                      handleChange={handleChange}
                      errors={errors.mailingStreet}
                      maxLength={100}
                    />
                    <FormInput
                      id="mailing-address-city"
                      title={(applicant ? applicant + ' ' : '') + 'Mailing Address City*'}
                      type="text"
                      name="mailingCity"
                      //pattern="[a-zA-Z]+"
                      value={values.mailingCity}
                      handleChange={handleChange}
                      errors={errors.mailingCity}
                      maxLength={50}
                    />

                    <Select
                      id="mailing-state"
                      name={'mailingState'}
                      title={(applicant ? applicant + ' ' : '') + 'Mailing Address State*'}
                      value={values.mailingState}
                      options={statesOfUSA}
                      placeholder="Select state"
                      handleChange={handleChange}
                      errors={errors.mailingState}
                      maxLength={2}
                    />
                    <FormInput
                      id="mailing-zip-code"
                      title={(applicant ? applicant + ' ' : '') + 'Mailing Address ZIP*'}
                      type="number"
                      name={'mailingZip'}
                      value={values.mailingZip}
                      handleChange={handleChange}
                      errors={errors.mailingZip}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />
                    <div className="form-group form-check">
                      <label className="form-check-label">
                        <input
                          id="sameAsMailingAddress"
                          className="form-check-input"
                          type="checkbox"
                          name={'sameasMailingAddress'}
                          onChange={() => setIsChecked(!isChecked)}
                        />
                        <span className="checkmark"></span>
                        <strong>
                          {' '}
                          Select this box if your mailing address and property address are the same.
                        </strong>
                      </label>
                    </div>
                    {
                      <Fragment>
                        <FormInput
                          id="property-street-address"
                          title={(applicant ? applicant + ' ' : '') + 'Property Address Street*'}
                          type="text"
                          name={'propertyStreet'}
                          value={values.propertyStreet}
                          handleChange={handleChange}
                          errors={errors.propertyStreet}
                          disabled={isChecked ? true : false}
                          maxLength={100}
                        />
                        <FormInput
                          id="property-address-city"
                          title={(applicant ? applicant + ' ' : '') + 'Property Address City*'}
                          type="text"
                          name={'propertyCity'}
                          value={values.propertyCity}
                          handleChange={handleChange}
                          errors={errors.propertyCity}
                          disabled={isChecked ? true : false}
                          maxLength={50}
                        />

                        <Select
                          id="property-state"
                          name={'propertyState'}
                          title={(applicant ? applicant + ' ' : '') + 'Property Address State*'}
                          options={statesOfUSA}
                          value={values.propertyState}
                          placeholder="Select state"
                          handleChange={handleChange}
                          errors={errors.propertyState}
                          disabled={isChecked ? true : false}
                          maxLength={2}
                        />
                        <FormInput
                          id="property-zip-code"
                          title={(applicant ? applicant + ' ' : '') + 'Property Address ZIP*'}
                          type="number"
                          name={'propertyZip'}
                          value={values.propertyZip}
                          handleChange={handleChange}
                          errors={errors.propertyZip}
                          disabled={isChecked ? true : false}
                          onWheel={(e) => {
                            e.target.blur();
                          }}
                          maxLength={20}
                        />
                      </Fragment>
                    }
                    <div className={'column-two-buttons float-right'}>
                      <Button
                        title="Next"
                        type="submit"
                        className={'next-button'}
                        action={() => setSubmitLater(false)}
                      />
                    </div>
                    <div className="float-left column-two-buttons">
                      <Button
                        title="Back"
                        className=" cancel-button"
                        action={() => {
                          navigate('/driversLicense');
                        }}
                      />
                    </div>

                    <div className="">
                      <Button
                        title="Save and Continue Later"
                        className={'third-button save-button'}
                        action={(e) => {
                          setSubmitLater(true);
                          handleSubmit(e);
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default HardshipsAddress;
