import React, {Fragment, useState, useEffect} from 'react';
import './SecurityQuestion.scss';
import {useNavigate} from 'react-router-dom';
import Button from '../common/Button';
import PageHeading from '../PageHeading/PageHeading';
import {validateChallengeQuestion} from '../../services/UserLoginService';
import {useAppContext} from '../../contexts/AppContext';
import {getAccounts} from '../../services/AccountDetailsService';
import dispatchEvent from '../../ensighten/index';

function SecurityQuestion() {
  const [challengeAnswer, setChallengeAnswer] = useState('');
  const [errorState, setErrorState] = useState<{
    error: boolean;
    errorMsg: string;
  }>({error: false, errorMsg: ''});
  const navigate = useNavigate();
  const {
    primaryToken,
    setUserToken,
    setAccountList,
    setPrimaryToken,
    setAchStoredPymtMethods,
    setDebitStoredPymtMethods,
    setAuthorizedRegionsAccounts,
    setIsLoading,
    EnsightenDetails
  } = useAppContext();
  const securityQuestionText = primaryToken.question;
  const validateSecurityQuestion = () => {
    if (challengeAnswer.trim() === '') {
      setErrorState({
        ...errorState,
        error: true,
        errorMsg: 'This field is required. Please enter a value.'
      });
    } else {
      setErrorState({
        error: false,
        errorMsg: ''
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    validateSecurityQuestion();

    if (!errorState.error) {
      const requestObj = {
        token: primaryToken.token,
        sessionId: primaryToken.sessionId,
        questionId: primaryToken.questionId,
        answer: challengeAnswer,
        profileVersion: primaryToken.profileVersion
      };
      
      setIsLoading(true);
      const response = validateChallengeQuestion(requestObj);
      
      response
        .then((res) => {
          if ('token' in res) {
            setIsLoading(false);
            setUserToken(res.token);
            navigate('/landing');
          } else {
            setIsLoading(false);
            setPrimaryToken({...primaryToken, token: res.response.data.token});
            setErrorState({
              ...errorState,
              error: true,
              errorMsg: 'Invalid response. Please try again or call 1-866-298-1113 for assistance.'
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    dispatchEvent(EnsightenDetails);
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <PageHeading title={'Account Solutions Portal'} />
      <div className={'regions-container'}>
        <div className={' security-question-block page-content-container'}>
          <div className={'row'}>
            <div className={'col-lg-12'}>
              <div className={'row'}>
                <div className={'content-block'}>
                  <h2>Please answer the security question.</h2>
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="field animated-label text required error">
                      <label
                        className="control-label"
                        htmlFor="securityQuestion"
                        id="security-question-label"
                      >
                        {securityQuestionText}
                      </label>
                      <input
                        type="text"
                        id="securityQuestion"
                        name="security-question"
                        value={challengeAnswer}
                        onChange={(e) => setChallengeAnswer(e.target.value)}
                        className={
                          errorState.error
                            ? 'border border-danger form-control to-switch'
                            : 'form-control to-switch'
                        }
                        aria-labelledby="security-question-label"
                        aria-invalid={errorState.error ? true : false}
                        aria-describedby={errorState.error && 'error-securityQuestion'}
                        onBlur={() => validateSecurityQuestion()}
                      />
                      {errorState.error && (
                        <div className="error-block">
                          <p className="help-block error" id="error-securityQuestion">
                            {errorState.errorMsg}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className={'submit-cancel-button-group'}>
                      <Button title="Submit" type="submit" className={'next-button float-right'} />
                    </div>
                  </form>
                  <div>
                    <button
                      title="Cancel"
                      className={'cancel-button float-left'}
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SecurityQuestion;
