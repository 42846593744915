import axios from 'axios';
import {e200, e401, e500} from './StatusConstants';
import {EXPERIENCE_API_URL} from '../utilities/constants';

export const getHardshipData = async (hardshipObj) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    console.log('Hardship request Object:\n', hardshipObj);
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/Hardship/Loan/details`,
      // 'https://sscp-exp-api-dev.regionstest.com/Hardship/Loan/details',
      hardshipObj,
      options
    );
    const responseObj = res.data;
    console.log('Hardship response Object:\n', res);
    switch (responseObj) {
      case responseObj.errorCode === 500:
        return responseObj;
      case responseObj.errorCode === 401:
        return responseObj;
      case responseObj.errorCode === 200:
        return responseObj;
      default:
        return responseObj;
    }
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};

export const saveHardshipApplicationData = async (hardshipAppDataObj) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall',
      'Content-type': 'application/JSON'
    }
  };
  try {
    console.log('Hardship App save request Object:\n', hardshipAppDataObj);
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/Hardship/Application/save`,
      // 'https://sscp-exp-api-dev.regionstest.com/Hardship/Application/save',
      hardshipAppDataObj,
      options
    );
    const responseObj = res.data;
    console.log('Hardship response Object:\n', responseObj);
    switch (responseObj) {
      case responseObj.errorCode === 500:
        return responseObj;
      case responseObj.errorCode === 401:
        return responseObj;
      case responseObj.errorCode === 200:
        return responseObj;
      default:
        return responseObj;
    }
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};

export const submitExtension = async (extnObj) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    console.log('submit extension  request Object:\n', extnObj);
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/Hardship/Extension/submit`,
      // 'https://sscp-exp-api-dev.regionstest.com/Hardship/Extension/submit',
      extnObj,
      options
    );
    const responseObj = res.data;
    console.log('submit extension response Object:\n', responseObj);
    switch (responseObj) {
      case responseObj.errorCode === 500:
        return responseObj;
      case responseObj.errorCode === 401:
        return responseObj;
      case responseObj.errorCode === 200:
        return responseObj;
      default:
        return responseObj;
    }
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};

export const getApplicationDetails = async (appDetailsObj) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    console.log('get application details request Object:\n', appDetailsObj);
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/Hardship/Application/details`,
      // 'https://sscp-exp-api-dev.regionstest.com/Hardship/Application/details',
      appDetailsObj,
      options
    );
    const responseObj = res.data;
    console.log('submit extension response Object:\n', responseObj);
    switch (responseObj) {
      case responseObj.errorCode === 500:
        return responseObj;
      case responseObj.errorCode === 401:
        return responseObj;
      case responseObj.errorCode === 200:
        return responseObj;
      default:
        return responseObj;
    }
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};
