import React, {useEffect, createRef, Fragment, useState} from 'react';
import AccountCard from './AccountCard/AccountCard';
import {IAccount} from './AccountDetails.types';
import {getAccounts} from '../../services/AccountDetailsService';
import './AccountDetails.scss';
import {useAppContext} from '../../contexts/AppContext';
import PageHeading from '../PageHeading/PageHeading';
import dispatchEvent from '../../ensighten/index';

function AccountDetails() {
  const {
    AccountsDetails,
    userToken,
    setAccountList,
    setAchStoredPymtMethods,
    setDebitStoredPymtMethods,
    setAuthorizedRegionsAccounts,
    setUserToken,
    setIsLoading,
    setCardNbrStr,
    isLoading,
    CardNbrStr,
    EnsightenDetails,
    setEnsightenDetails
  } = useAppContext();

  const [noAccountsFlag, setNoAccountsFlag] = useState(false);
  const [hasBlockedAccountsFlag, setHasBlockedAccountsFlag] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (userToken !== null && userToken !== undefined && userToken != '') {
      const response = getAccounts(userToken);

      response
        .then((res) => {
          setIsLoading(false);
  
          const {
            accounts,
            storedPaymentMethods,
            storedDebitPymtMethods,
            authorizedRegionsAccounts,
            token
          } = res;
  
          let hasLockedAccount = false;
  
          setUserToken(token);
          // dispatchAccountsEvent(accounts.length);
          EnsightenDetails.accounts = accounts.length;
          setEnsightenDetails(EnsightenDetails);
  
          if (accounts.length == 0) {
            setNoAccountsFlag(true);
            setHasBlockedAccountsFlag(false);
          } else {
            setNoAccountsFlag(false);
            hasLockedAccount = accounts.find((account) => {
              if (account.isAccountLocked === true) {
                return true;
              }
              return false;
            });
          }
  
          if (hasLockedAccount) {
            setHasBlockedAccountsFlag(true);
          } else {
            setAccountList(accounts);
            setAchStoredPymtMethods(storedPaymentMethods);
            setDebitStoredPymtMethods(storedDebitPymtMethods);
            setAuthorizedRegionsAccounts(authorizedRegionsAccounts);
          }
  
          dispatchEvent(EnsightenDetails);
        })
        .catch((err) => console.log(err));
    }
    
  }, []);

  const topRef = createRef<HTMLDivElement>();
  useEffect(() => {
    topRef.current.scrollIntoView();
  }, [topRef]);

  return (
    <Fragment>
      <div className={'regions-container'}>
        <div className={'row'} ref={topRef}>
          <div className="landing-container">
            <div className={'col-xl-12'}>
              <h1 className={'first-header'}>Past Due Account Summary</h1>
            </div>
            <div className={'col-xl-12'}>
              <h2 className={'second-header'}>
                {noAccountsFlag
                  ? `Please contact Regions at (800)-333-4104 for assistance.`
                  : ''}
                {hasBlockedAccountsFlag
                  ? `Please call Regions Default solutions at 1-800-333-4104`
                  : ''}
              </h2>
            </div>

            <div className={'col-xl-12'}>
              {AccountsDetails && AccountsDetails.length > 0 ? (
                AccountsDetails.map((item: IAccount) => (
                  <AccountCard key={item.delqAcctNbr} account={item} />
                ))
              ) : (
                <Fragment></Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AccountDetails;
