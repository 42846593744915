import React, {FC, useState, useEffect, Fragment} from 'react';
import useForm from '../common/UseForm';
import {houseRentValidate} from '../common/FormValidationRules';
import Button from '../common/Button';
import {useAppContext} from '../../contexts/AppContext';
import {useNavigate} from 'react-router-dom';
import PageHeading from '../PageHeading/PageHeading';
import FormInput from '../common/FormInput';
import {numberWithCommas, getAccountDisplayStr} from '../../utilities/Utilities';
import {saveHardshipApplicationData} from '../../services/HardshipService';
import dispatchEvent from '../../ensighten/index';

function HardshipsRentalHousing() {
  const {values, errors, handleChange, handleSubmit, setValues, isSubmitting, isSent} = useForm(
    houseRentValidate,
    ''
  );
  const [submitLater, setSubmitLater] = useState<boolean>(false);
  useEffect(() => {
    setValues({
      ...values,
      monthlyRentAmount: hardshipAppDetails.HousingDetails.MonthlyRental || ''
    });
  }, []);
  const {
    selectedAccount,
    setModalProps,
    modalProps,
    hardshipAppDetails,
    setHardshipAppDetails,
    userToken,
    setUserToken,
    setIsLoading,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent) {
      submitHandleChange();
    }
  }, [errors]);
  useEffect(() => {
    dispatchEvent(EnsightenDetails);
  }, []);
  const submitHandleChange = () => {
    setIsLoading(true);
    const reqObj = {
      token: userToken,
      transType: 'Collections',
      hardshipAppDetails: {
        ...hardshipAppDetails,
        LastUpdatedPageId: '10',
        actionType: 'update',
        HousingDetails: {
          ...hardshipAppDetails.HousingDetails,
          MonthlyRental: values.monthlyRentAmount,
          PropertyType: '',
          PropertyAddress: '',
          OccupancyType: '',
          InsuranceTaxes: '',
          Explainationsituation: ''
        },
        borrowerDetails: {
          ...hardshipAppDetails.borrowerDetails,
          LienspropertyOne: '',
          PresentValueOne: '',
          AmountMortgageOne: '',
          MonthlyPaymentOne: '',
          MontlyIncomeOne: ''
        }
      }
    };
    const resp = saveHardshipApplicationData(reqObj);
    console.log(' Housing Obj is ', reqObj);
    resp
      .then((resp) => {
        if ('saveHardshipAppStatus' in resp) {
          setIsLoading(false);
          console.log('\nRental housing Response :', resp);
          setHardshipAppDetails({
            ...hardshipAppDetails,
            hardshipAppId: resp.saveHardshipAppStatus.confNbr,
            LastUpdatedPageId: '10',
            actionType: 'update',
            HousingDetails: {
              ...hardshipAppDetails.HousingDetails,
              MonthlyRental: values.monthlyRentAmount,
              PropertyType: '',
              PropertyAddress: '',
              OccupancyType: '',
              InsuranceTaxes: '',
              Explainationsituation: ''
            },
            borrowerDetails: {
              ...hardshipAppDetails.borrowerDetails,
              LienspropertyOne: '',
              PresentValueOne: '',
              AmountMortgageOne: '',
              MonthlyPaymentOne: '',
              MontlyIncomeOne: ''
            }
          });
          setUserToken(resp.token);
          if (submitLater) {
            setSubmitLater(false);
            setModalProps({
              show: true,
              title: 'Save and Continue Confirmation.',
              body:
                'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
              onSuccessClick: () => {
                setAccountList([]);
                navigate('/landing');
                setModalProps({...modalProps, show: false});
              }
            });
          } else {
            navigate('/hardships-employment');
          }
        } else {
          setIsLoading(false);
          setUserToken(resp.response.data.token);
          // navigate('/hardships-employment');
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <PageHeading title="Home Expense"></PageHeading>
      {selectedAccount && (
        <div className={'regions-container'}>
          <div className={'page-content-container hardship-card '}>
            <div className="row">
              <div className={'two-column-layout'}>
                <div className="column-one">
                  <h2 className="payment-header">
                    <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                    <span className={'account-number'}>
                      {getAccountDisplayStr(selectedAccount)}
                    </span>
                  </h2>
                  <div className={'well'}>
                    <div id="housing">
                      <p className={'confirmation-number'}>{hardshipAppDetails.hardshipAppId}</p>
                      <p>
                        <strong>Housing - Renters</strong>
                      </p>
                      <p>Please enter your monthly rent</p>
                    </div>
                  </div>
                </div>

                <div className="column-two">
                  <form onSubmit={handleSubmit} noValidate>
                    <FormInput
                      id="monthlyRent"
                      title="Monthly Rent Amount*"
                      type="number"
                      name="monthlyRentAmount"
                      value={values.monthlyRentAmount || ''}
                      handleChange={handleChange}
                      errors={errors.monthlyRentAmount}
                      maxLength={20}
                    />
                    <div className={'column-two-buttons float-right'}>
                      <Button
                        title="Next"
                        type="submit"
                        className={'next-button float-right'}
                        action={() => setSubmitLater(false)}
                      />
                    </div>
                    <div className="float-left column-two-buttons">
                      <Button
                        title="Back"
                        className="cancel-button float-left"
                        action={() => {
                          navigate('/hardships-housing');
                        }}
                      />
                    </div>
                    <div className="">
                      <Button
                        title="Save and Continue Later"
                        className={'third-button save-button'}
                        action={(e) => {
                          setSubmitLater(true);
                          handleSubmit(e);
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default HardshipsRentalHousing;
