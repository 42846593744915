import React, {useState, useEffect} from 'react';
import {useAppContext} from '../../contexts/AppContext';
import AccountDetails from '../AccountDetails/AccountDetails';
import RecoveryLanding from '../RecoveryLanding/RecoveryLanding';
import './LandingPage.scss';

function LandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={'landing-page'}>
      <AccountDetails />
    </div>
  );
}

export default LandingPage;
