/* eslint-disable no-restricted-globals */
import React, {Fragment, useState, useEffect, useContext} from 'react';
import LandingPage from './pages/LandingPage';
import {Route, Routes, useNavigate, redirect, Navigate } from 'react-router-dom';
import './App.scss';
import PaymentDetails from './PaymentDetails/PaymentDetails';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import {IAccount} from './AccountDetails/AccountDetails.types';
import {IcontextValue} from '../contexts/AppContext.types';
import AppContext from '../contexts/AppContext';
import SuccessDetails from '../components/PaymentSuccess/SuccessDetails';
import SessionTimeOut from '../components/SessionTimeOut/SessionTimeOut';
import {SensitivePageSessionTimeout} from '../sessiontimer.config';
import AuthorizePayment from '../components/AuthorizePayment/AuthorizePayment';
import RecoveryLanding from '../components/RecoveryLanding/RecoveryLanding';
import RecoveryPaymentDetails from '../components/RecoveryPayment/RecoveryPaymentDetails';
import GuestCheckoutLanding from '../components/GuestCheckoutLanding/GuestCheckoutLanding';
import GuestCheckoutPaymentDetails from '../components/GuestCheckoutPayment/GuestCheckoutPaymentDetails';
import Login from './Login/Login';
import LogInLoading from './LogInLoading/LogInLoading';
import LogInLoadingCallback from './LogInLoadingCallback/LogInLoadingCallback';
import SecurityQuestion from './SecurityQuestion/SecurityQuestion';
import HardshipPlanInfoDetails from './HardshipPlanInfo/HardshipPlanInfoDetails';
import BankruptcyDetails from './Bankruptcy/BankruptcyDetails';
import PersonalInfoDetails from './PersonalInfo/PersonalInfoDetails';
import DriversLicenseDetails from './DriversLicense/DriversLicenseDetails';
import HardshipsAddress from './Hardships/HardshipsAddress';
import {onLogOut} from '../services/UserLoginService';
import HardshipsIncome from './Hardships/HardshipsIncome';
import HardshipsLiquidAssets from './Hardships/HardshipsLiquidAssests';
import HardshipsRetirement from './Hardships/HardshipsRetirement';
import HardshipsHousing from './Hardships/HardshipsHousing';
import HardshipsOwnHousing from './Hardships/HardshipsOwnHousing';
import HardshipsRentalHousing from './Hardships/HardshipsRentalHousing';
import HardshipsEmploymentInfo from './Hardships/HardshipsEmploymentInfo';
import ModalBox, {IModalProps} from './Modal/ModalBox';
import CoborrowerIntro from './Hardships/CoborrowerIntro';
import HardshipsSuccess from './Hardships/HardshipsSuccess';
import Questionnaire from './Questionnaire/Questionnaire';
import OneMonthExtension from './Questionnaire/OneMonthExtension';
import ExtensionApplied from './Questionnaire/ExtensionApplied';
import TwoMonthExtension from './Questionnaire/TwoMonthExtension';
import CallHelpDesk from './Questionnaire/CallHelpDesk';
import ManagePayments from './ManagePayments/ManagePayments';
import Spinner from './Spinner/Spinner';
import Health from './Health';
import {useSSCPContext} from '../index';
import {
  IHardshipAppDetails,
  initialHardshipData,
  initialLoanObjDetails,
  ILoanDetailResponseObj
} from './Hardships/HardshipsConstants';
import SiteUnderConstruction from './SiteUnderConstruction/SiteUnderConstruction';
import {
  IEnsighten,
  initialEnsighten,
  ResetEnsighten
} from '../ensighten/Ensighten.types'

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const [isActiveCollections, setIsActiveCollections] = useState<boolean>(true);
  const [isRecovery, setIsRecovery] = useState<boolean>(false);
  const [isGuestCheckout, setIsGuestCheckout] = useState<boolean>(false);
  const [accountList, setAccountList] = useState<IAccount[]>([]);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [successDetails, setSuccessDetails] = useState({});
  const [routingErrText, setRoutingErrText] = useState('');
  const [cardNbrStr, setCardNbrStr] = useState('');
  const [existingRegAcctBal, setExistingRegAcctBal] = useState();
  const [selectedAccount, setSelectedAccount] = useState<IAccount>(null);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [achStoredPymtMethods, setAchStoredPymtMethods] = useState([]);
  const [debitStoredPymtMethods, setDebitStoredPymtMethods] = useState([]);
  const [authorizedRegionsAccounts, setAuthorizedRegionsAccounts] = useState([]);
  const [coBorrower, setCoBorrower] = useState<boolean>(true);
  const [coBorrowerPath, setCoBorrowerPath] = useState(false);
  const [primaryTokenObj, setPrimaryTokenObj] = useState({});
  const [userToken, setUserToken] = useState<string>(
    sessionStorage.getItem('ut') ? sessionStorage.getItem('ut') : ''
  );

  const [isAchPaymentEnabled, setIsAchPaymentEnabled] = useState<boolean>(false);
  const [recoveryAcctNbr, setRecoveryAcctNbr] = useState<string>('');
  const [guestCheckoutAcctNbr, setGuestCheckoutAcctNbr] = useState<string>('');
  const [guestCheckoutDisplayNbr, setGuestCheckoutDisplayNbr] = useState<string>('');
  const [guestCheckoutMinPayment, setGuestCheckoutMinPayment] = useState<string>('');
  const [guestCheckoutMaxPayment, setGuestCheckoutMaxPayment] = useState<string>('');
  const [guestCheckoutProductType, setGuestCheckoutProductType] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMatchedPendingPayments, setHasMatchedPendingPayments] = useState<boolean>(false);
  const [hardshipReason, sethardshipReason] = useState('');
  const [hardshipDuration, sethardshipDuration] = useState('');
  const [confNmbr, setConfNmbr] = useState('');
  const [hardshipLoanDetails, setHardshipLoanDetails] = useState<ILoanDetailResponseObj>(
    initialLoanObjDetails
  );
  const [hardshipAppDetails, setHardshipAppDetails] = useState<IHardshipAppDetails>(
    initialHardshipData
  );
  const [modalProps, setModalProps] = useState<IModalProps>({
    show: false,
    title: 'Modal Title',
    body: 'Modal Body',
    onSuccessClick: () => {
      return null;
    }
  });
  const [ensightenDetails, setEnsightenDetails] = useState<IEnsighten>(initialEnsighten);

  const {setSscpState, isRefreshed, sscpState} = useSSCPContext();
  const navigate = useNavigate();
  let recoveryPath = false;
  let guestCheckoutPath = false;

  history.pushState(null, null, location.href);
  window.onpopstate = function () {
    logOutHandler();
  };
  useEffect(() => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      if (isLoading) {
        let deg = 0;
        const frame = () => {
          if (deg == 360) {
            deg = 0;
          } else {
            deg++;
            ele.style.transform = `rotate(${deg}deg)`;
          }
        };
        const id = setInterval(frame, 5);
        const ele = window.document.getElementById('spinner');
      }
    }
  }, [isLoading]);
  useEffect(() => {
    if (window.location.pathname.toLowerCase() === '/rds') {
      setIsActiveCollections(false);
      setIsRecovery(true);
      setIsGuestCheckout(false);
      setSscpState('recovery');

      recoveryPath = true;
    } else if (window.location.pathname.toLowerCase() === '/guest') {
      setIsActiveCollections(false);
      setIsRecovery(false);
      setIsGuestCheckout(true);
      setSscpState('guestcheckout');

      guestCheckoutPath = true;
    }
    return setPrimaryTokenObj({});
  }, []);
  useEffect(() => {
    if ((userToken === undefined || userToken === null) && isActiveCollections) {
      console.log('user Token Undefined');
      navigate('/sessionExpired');
    }
  }, [userToken]);
  const logOutHandler = () => {
    if (isActiveCollections) {
      const data = {
        messsage: '',
        token: userToken
      };
      setIsLoading(true);
      const response = onLogOut(data);
      response
        .then((res) => {
          setIsLoading(false);
          navigate('/login');

          setUserToken('');
        })
        .catch((err) => console.log(err));
    } else if (isRecovery) {
      navigate('/rds');
    } else if (isGuestCheckout) {
      navigate('/login');
    }

    sessionStorage.clear();

    ResetEnsighten(ensightenDetails);

    setUserToken('');
    setAccountList([]);
    setSelectedAccount(null);
    setCardNbrStr('');
    setAchStoredPymtMethods([]);
    setDebitStoredPymtMethods([]);
    setAuthorizedRegionsAccounts([]);
    setHardshipAppDetails(initialHardshipData);
    setHardshipLoanDetails(initialLoanObjDetails);
    setEnsightenDetails(ensightenDetails);
  };

  const contextValue: IcontextValue = {
    AccountsDetails: accountList,
    setAccountList: setAccountList,
    PaymentDetails: paymentDetails,
    setPaymentDetails: setPaymentDetails,
    SuccessDetails: successDetails,
    setSuccessDetails: setSuccessDetails,
    selectedAccount: selectedAccount,
    setSelectedAccount: setSelectedAccount,
    PaymentMethod: paymentMethod,
    IsActiveCollections: isActiveCollections,
    IsRecovery: isRecovery,
    IsGuestCheckout: isGuestCheckout,
    isAuthenticated: isAuthenticated,
    setPaymentMethod: setPaymentMethod,
    StoredPaymentMethods: achStoredPymtMethods,
    setAchStoredPymtMethods: setAchStoredPymtMethods,
    modalProps: modalProps,
    setModalProps: setModalProps,
    coBorrower: coBorrower,
    setCoBorrower: setCoBorrower,
    coBorrowerPath: coBorrowerPath,
    setCoBorrowerPath: setCoBorrowerPath,
    userToken: userToken,
    setUserToken: setUserToken,
    primaryToken: primaryTokenObj,
    setPrimaryToken: setPrimaryTokenObj,
    AuthorizedRegionsAccounts: authorizedRegionsAccounts,
    setAuthorizedRegionsAccounts: setAuthorizedRegionsAccounts,
    DebitStoredPymtMethods: debitStoredPymtMethods,
    setDebitStoredPymtMethods: setDebitStoredPymtMethods,
    isLoading: isLoading,
    setIsLoading: setIsLoading,
    hardshipLoanDetails: hardshipLoanDetails,
    setHardshipLoanDetails: setHardshipLoanDetails,
    hardshipAppDetails: hardshipAppDetails,
    setHardshipAppDetails: setHardshipAppDetails,
    RoutingErrText: routingErrText,
    setRoutingErrText: setRoutingErrText,
    RecoveryAcctNbr: recoveryAcctNbr,
    setRecoveryAcctNbr: setRecoveryAcctNbr,
    IsAchPaymentEnabled: isAchPaymentEnabled,
    setIsAchPaymentEnabled: setIsAchPaymentEnabled,
    HasMatchedPendingPayments: hasMatchedPendingPayments,
    setHasMatchedPendingPayments: setHasMatchedPendingPayments,
    ExistingRegAcctBal: existingRegAcctBal,
    setExistingRegAcctBal: setExistingRegAcctBal,
    hardshipReason: hardshipReason,
    sethardshipReason: sethardshipReason,
    hardshipDuration: hardshipDuration,
    sethardshipDuration: sethardshipDuration,
    CardNbrStr: cardNbrStr,
    setCardNbrStr: setCardNbrStr,
    confNmbr: confNmbr,
    setConfNmbr: setConfNmbr,
    GuestCheckoutAcctNbr: guestCheckoutAcctNbr,
    setGuestCheckoutAcctNbr: setGuestCheckoutAcctNbr,
    GuestCheckoutDisplayNbr: guestCheckoutDisplayNbr,
    setGuestCheckoutDisplayNbr: setGuestCheckoutDisplayNbr,
    GuestCheckoutMinPayment: guestCheckoutMinPayment,
    setGuestCheckoutMinPayment: setGuestCheckoutMinPayment,
    GuestCheckoutMaxPayment: guestCheckoutMaxPayment,
    setGuestCheckoutMaxPayment: setGuestCheckoutMaxPayment,
    GuestCheckoutProductType: guestCheckoutProductType,
    setGuestCheckoutProductType: setGuestCheckoutProductType,
    EnsightenDetails: ensightenDetails,
    setEnsightenDetails: setEnsightenDetails
  };
  useEffect(() => {
    if (sessionStorage.getItem('ut')) {
      setUserToken(sessionStorage.getItem('ut'));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('ut', userToken);
  }, [userToken]);
  window.onbeforeunload = (e) => {
    e.preventDefault();

    sessionStorage.setItem('ut', userToken);
    sessionStorage.setItem('isRefreshed', 'true');

    const message = 'Are you sure?';
    e.returnValue = message;
    return message;
  };

const NavigateToLandingPage = (isActiveCollections, userToken, isRecovery) => {
if (isActiveCollections) {
  <Navigate to="/login" />
}
else if (userToken) {
  <Navigate to="/landing" />
}
else if (isRecovery) {
  <Navigate to="/rds" />
}
else {
  <Navigate to="/guest" />
}
};

  useEffect(() => {

    if (isRefreshed === 'true' && sessionStorage.getItem('ut')) {
      navigate('/landing');      
    } 
    else if (window.location.pathname.toLowerCase() === '/sign-in' || window.location.pathname.toLowerCase() === '/sign-in/callback') {

    }    
    else if (
      isRefreshed === 'true' &&
      (window.location.pathname.toLowerCase() === '/challenge' ||
        window.location.pathname.toLowerCase() === '/login')
    ) {
      navigate('/login');
    } else if (!userToken && !sessionStorage.getItem('ut')) {
      if (isRecovery || recoveryPath){
        setIsActiveCollections(false);
        setIsRecovery(true);
        setIsGuestCheckout(false);
        
        navigate('/rds');
      }
      else if (isGuestCheckout || guestCheckoutPath){
        setIsActiveCollections(false);
        setIsRecovery(false);
        setIsGuestCheckout(true);
        
        navigate('/guest');
      } else {
        setIsActiveCollections(true);
        setIsRecovery(false);
        setIsGuestCheckout(false);
        navigate('/login');
      }
    }
    sessionStorage.removeItem('isRefreshed');

    NavigateToLandingPage(isActiveCollections, userToken, isRecovery)
  }, [isRefreshed]);
  return (
    <Fragment>
      <AppContext.Provider value={contextValue}>
        <div className={'container-fluid'}>
          <ModalBox
            show={modalProps.show}
            title={modalProps.title}
            body={modalProps.body}
            onSuccessClick={modalProps.onSuccessClick}
          ></ModalBox>
          <Header logOutHandler={logOutHandler} />
          <Spinner showSpinner={isLoading} />
          <Routes>
            {/* {NavigateToLandingPage(isActiveCollections, userToken, isRecovery)} */}
            <Route path="/actuator/health" element={<Health />} />
            <Route path="/login" element={<Login />} />
            <Route path="/rds" element={<RecoveryLanding />} />
            <Route path="/recoveryPayment" element={<RecoveryPaymentDetails />} />
            <Route path="/guest" element={<GuestCheckoutLanding />} />
            <Route path="/guestPayment" element={<GuestCheckoutPaymentDetails />} />
            <Route path="/challenge" element={<SecurityQuestion />} />
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/managePayments" element={<ManagePayments />} />
            <Route path="/payments" element={
                  <SessionTimeOut
                    BaseComponent={PaymentDetails}
                    IdleTime={SensitivePageSessionTimeout}
                  />} />
              <Route path="/authorize" element={
                <SessionTimeOut
                  BaseComponent={AuthorizePayment}
                  IdleTime={SensitivePageSessionTimeout}
                />} />
            <Route path="/questionnaire" element={<Questionnaire />} />
            <Route path="/oneMonthExtension" element={<OneMonthExtension />} />
            <Route path="/twoMonthExtension" element={<TwoMonthExtension />} />
            <Route path="/extensionApplied" element={<ExtensionApplied />} />
            <Route path="/contactHelpDesk" element={<CallHelpDesk />} />
            <Route path="/hardships" element={<HardshipPlanInfoDetails />} />
            <Route path="/bankruptcy" element={<BankruptcyDetails />} />
            <Route path="/personalInfo" element={<PersonalInfoDetails />} />
            <Route path="/driversLicense" element={<DriversLicenseDetails />} />
            <Route path="/hardships-address" element={<HardshipsAddress />} />
            <Route path="/hardships-income" element={<HardshipsIncome />} />
            <Route path="/hardships-liquidassets" element={<HardshipsLiquidAssets />} />
            <Route path="/hardships-retirement" element={<HardshipsRetirement />} />
            <Route path="/hardships-housing" element={<HardshipsHousing />} />
            <Route path="/hardships-ownHousing" element={<HardshipsOwnHousing />} />
            <Route path="/hardships-rentalHousing" element={<HardshipsRentalHousing />} />
            <Route path="/hardships-employment" element={<HardshipsEmploymentInfo />} />
            <Route path="/CBhardships" element={<CoborrowerIntro />} />
            <Route path="/hardshipSuccess" element={<HardshipsSuccess />} />
            <Route path="/success" element={<SuccessDetails />} />
            <Route path="/sessionExpired" element={<SiteUnderConstruction />} />
            <Route path="about" element={<Navigate to="/login"  />} />
            <Route path="/sign-in" element={<LogInLoading />} />
            <Route path="/sign-in/callback" element={<LogInLoadingCallback />} />            
          </Routes>
        </div>
        <div className="col-sm-12">
          {userToken && userToken.trim() !== '' && (
            <button className={'mobile-signout-button logout-app'} onClick={logOutHandler}>
              Log Out
            </button>
          )}
        </div>
        <Footer />
      </AppContext.Provider>
    </Fragment>
  );
}

export default App;
