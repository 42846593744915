import React, {useEffect, Fragment} from 'react';
import SuccessCard from './SuccessCard/SuccessCard';
import '../AccountDetails/AccountDetails.scss';
import PageHeading from '../PageHeading/PageHeading';
function SuccessDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <PageHeading title={'Make a Payment'} />
      <div className={'regions-container'}>
        <SuccessCard />
      </div>
    </Fragment>
  );
}

export default SuccessDetails;
