import React, {FC, useState, useEffect, Fragment, useRef} from 'react';
import Button from '../common/Button';
import {useAppContext} from '../../contexts/AppContext';
import PageHeading from '../PageHeading/PageHeading';
import {useNavigate} from 'react-router-dom';
import Select from '../common/Select';
import {extensionValidate} from '../common/FormValidationRules';
import useForm from '../common/UseForm';
import {dynamicRouting} from './ExtensionUtilities';
import {getHardshipData} from '../../services/HardshipService';
import {numberWithCommas, getAccountDisplayStr} from '../../utilities/Utilities';
import {initialLoanObjDetails} from 'components/Hardships/HardshipsConstants';
import FormInput from '../common/FormInput';
import dispatchEvent from '../../ensighten/index';
const Questionnaire: FC = () => {
  const {
    selectedAccount,
    setHardshipLoanDetails,
    setHardshipAppDetails,
    hardshipAppDetails,
    hardshipLoanDetails,
    userToken,
    setUserToken,
    isLoading,
    setIsLoading,
    hardshipReason,
    sethardshipReason,
    hardshipDuration,
    sethardshipDuration,
    setCoBorrower,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  let pathObj = '';
  const [pathVar, setPathVar] = useState<{path: string}>({path: ''});
  const {values, errors, handleSubmit, handleChange} = useForm(extensionValidate, pathVar.path);
  const [questionObj, setQuestionObj] = useState<{
    employed: string;
    duration: string;
    hardshipReason: string;
    reportableIncome: string;
  }>({employed: '', duration: '', hardshipReason: '', reportableIncome: ''});
  let hardshipReasonText = '';
  const navigate = useNavigate();
  const hardshipReasons = [
    'Became unemployed',
    'Employment income was reduced',
    'Automotive repairs or accident',
    'Medical expenses',
    'Natural disaster',
    'Unexpected expense',
    // 'Disaster',
    'Other'
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchEvent(EnsightenDetails);
  }, []);
  const handleDynamic = (questionObj, selectedAccount, loanDetailsObj) => {
    console.log('Data from response to make decision: ', loanDetailsObj);
    const decision = dynamicRouting(
      questionObj,
      selectedAccount.delqProdType,
      selectedAccount.delqAcctDaysPastDue,
      loanDetailsObj
    );
    return decision;
  };
  const onBlurHandler = () => {
    setIsLoading(true);
    const response = getHardshipData({
      Token: userToken,
      TransType: 'Collections',
      AcctId: selectedAccount.delId
    });
    let loanDetails;
    response
      .then((res) => {
        if (res.loanDetail) {
          loanDetails = res.loanDetail[0];
          setHardshipLoanDetails(loanDetails);
          setHardshipAppDetails({
            ...hardshipAppDetails,
            AcctId: selectedAccount.delId,
            Reason: values.extensionReason
          });

          if (
            loanDetails === undefined ||
            loanDetails.coBorrowerName === undefined ||
            loanDetails.coBorrowerName === null ||
            loanDetails.coBorrowerName === '' ||
            loanDetails.coBorrowerSSN === '0' ||
            !loanDetails.coBorrowerName ||
            !loanDetails.coBorrowerSSN
          ) {
            setCoBorrower(false);
          } else {
            setCoBorrower(true);
          }
          setUserToken(res.token);
          // sethardshipReason(values.extensionReason);
          const responseDecision = handleDynamic(
            {
              employed: values.extensionEmployed,
              duration: values.extensionDuration,
              hardshipReason: hardshipReasonText
            },
            selectedAccount,
            res.loanDetail[0]
          );
          console.log('hardshipReason is :', hardshipReason);
          console.log('onBlur decisionpath: ', responseDecision);
          setPathVar({...pathVar, path: responseDecision});
          pathObj = responseDecision;
          console.log(pathObj, responseDecision);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setUserToken(res.response.data.token);
        }
      })
      .catch((err) => console.log(err));
  };
  const submitHadleChange = () => {
    console.log('Submit HAndle change', pathVar.path);
    // sethardshipReason(values.extensionReason);
  };

  const renderConsistentReportableIncome = () => {
    if (values.extensionEmployed === 'no'){
    return (<fieldset className="form-group">
    <legend className="col-form-label">
      <strong>Do You Have Consistent Reportable Income?*</strong>
    </legend>

    <div className="form-check">
      <label className="form-check-label radio-check" htmlFor="reportableIncome">
        Yes
        <input
          className={'form-check-input'}
          type="radio"
          name="reportableIncomeRadioButton"
          id="reportableIncome"
          value={'yes'}
          checked={questionObj.reportableIncome === 'yes'}
          onChange={(event) => {
            setQuestionObj({...questionObj, reportableIncome: event.target.value});
            values.extensionReportableIncome = event.target.value;
            handleChange(event);
          }}
        />
        <span className="radioDot"></span>
      </label>
    </div>
    <div className="form-check">
      <label className="form-check-label radio-check" htmlFor="reportableIncome-no">
        No
        <input
          className={'form-check-input'}
          type="radio"
          name="reportableIncomeRadioButton"
          id="reportableIncome-no"
          value={'no'}
          checked={questionObj.reportableIncome === 'no'}
          onChange={(event) => {
            setQuestionObj({...questionObj, reportableIncome: event.target.value});
            values.extensionReportableIncome = event.target.value;
            handleChange(event);
          }}
        />
        <span className="radioDot"></span>
      </label>
    </div>
    {errors.extensionReportableIncome && (
      <div className="chinstrap form-control">{errors.extensionReportableIncome}</div>
    )}
  </fieldset>);
    }

    return null;
  };

  return (
    <Fragment>
      <PageHeading title="Apply for Financial Hardship Plan"></PageHeading>
      {selectedAccount && (
        <div className={'regions-container'}>
          <div className={'page-content-container hardship-card'}>
            <div className="row">
              <div className={'two-column-layout'}>
                <div className="column-one">
                  <h2 className="payment-header">
                    <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                    <span className={'account-number'}>
                      {getAccountDisplayStr(selectedAccount)}
                    </span>
                  </h2>
                  <div className={'well'}>
                    <div id="questionnaire">
                      <p>
                        On the following screens, you will be asked a few questions to help
                        determine the hardship plan that is best for you. Then you will have the
                        opportunity to apply for a financial hardship plan, make a payment on a
                        past-due account and more.
                      </p>
                      <p>*Fields marked with an asterisk are required.</p>
                    </div>
                  </div>
                </div>

                <div className="column-two">
                  <form onSubmit={handleSubmit} noValidate>
                    <fieldset className="form-group">
                      <legend className="col-form-label">
                        <strong>Are you currently employed?*</strong>
                      </legend>

                      <div className="form-check">
                        <label className="form-check-label radio-check" htmlFor="employed">
                          Yes
                          <input
                            className={'form-check-input'}
                            type="radio"
                            name="employedRadioButton"
                            id="employed"
                            value={'yes'}
                            checked={questionObj.employed === 'yes'}
                            onChange={(event) => {
                              setQuestionObj({...questionObj, employed: event.target.value});
                              values.extensionEmployed = event.target.value;
                              handleChange(event);
                            }}
                          />
                          <span className="radioDot"></span>
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label radio-check" htmlFor="employed-no">
                          No
                          <input
                            className={'form-check-input'}
                            type="radio"
                            name="employedRadioButton"
                            id="employed-no"
                            value={'no'}
                            checked={questionObj.employed === 'no'}
                            onChange={(event) => {
                              setQuestionObj({...questionObj, employed: event.target.value});
                              values.extensionEmployed = event.target.value;
                              handleChange(event);
                            }}
                          />
                          <span className="radioDot"></span>
                        </label>
                      </div>
                      {errors.extensionEmployed && (
                        <div className="chinstrap form-control">{errors.extensionEmployed}</div>
                      )}
                    </fieldset>
                    {renderConsistentReportableIncome()}
                    <fieldset className="form-group">
                      <legend className="col-form-label">
                        <strong>How long do you expect your hardship to last?*</strong>
                      </legend>

                      <div className="form-check">
                        <label className="form-check-label radio-check" htmlFor="lessthankninty">
                          90 days or fewer
                          <input
                            className="form-check-input"
                            type="radio"
                            name="durationRadioButton"
                            id="lessthankninty"
                            value={'less'}
                            checked={questionObj.duration === 'less'}
                            onChange={(event) => {
                              setQuestionObj({...questionObj, duration: event.target.value});
                              values.extensionDuration = event.target.value;
                              sethardshipDuration('90 days or less');
                              handleChange(event);
                            }}
                          />
                          <span className="radioDot"></span>
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label radio-check" htmlFor="morethanninty">
                          More than 90 days
                          <input
                            className="form-check-input"
                            type="radio"
                            name="durationRadioButton"
                            id="morethanninty"
                            value={'more'}
                            checked={questionObj.duration === 'more'}
                            onChange={(event) => {
                              setQuestionObj({...questionObj, duration: event.target.value});
                              values.extensionDuration = event.target.value;
                              sethardshipDuration('> than 90 days');
                              handleChange(event);
                            }}
                          />
                          <span className="radioDot"></span>
                        </label>
                      </div>
                      {errors.extensionDuration && (
                        <div className="chinstrap form-control">{errors.extensionDuration}</div>
                      )}
                    </fieldset>
                    <Select
                      id="extension-reason"
                      name="extensionReason"
                      title="What is the reason for your hardship?"
                      options={hardshipReasons}
                      value={values.extensionReason}
                      placeholder="Select a reason"
                      errors={errors.extensionReason}
                      handleChange={(e) => {
                        handleChange(e);
                        setQuestionObj({...questionObj, hardshipReason: values.extensionReason});
                        sethardshipReason(e.target.value);
                        hardshipReasonText = e.target.value;
                        onBlurHandler();
                      }}
                      // onSelect={onBlurHandler}
                    />
                    {values.extensionReason == 'Other' && (
                      <FormInput
                        id="otherReason"
                        title={'Other (Please Specify)'}
                        type="email"
                        name="emailIdCoBorrower"
                        value={values.extensionReasonOther}
                        handleChange={(e) => {
                          e.preventDefault();
                          sethardshipReason(e.target.value);
                          handleChange(e);
                        }}
                        maxLength={100}
                      />
                    )}                    
                    <div className="column-two-buttons float-right">
                      <Button
                        title="Begin Application"
                        type="submit"
                        className={'next-button begin-button float-right'}
                        action={submitHadleChange}
                      />
                    </div>
                  </form>
                  <div className="column-two-buttons float-left">
                    <Button
                      title="Cancel"
                      className="cancel-button"
                      action={() => {
                        setAccountList([]);
                        navigate('/landing');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Questionnaire;
