export const statesOfUSA = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY'
];

export interface ILoanDetailResponseObj {
  acctNum: string;
  borrowerName: string;
  borrowerDOB: string;
  borrowerSSN: string;
  coBorrowerName: string;
  coBorrowerDOB: string;
  coBorrowerSSN: string;
  borrowerEmail: string;
  borrowerCellPhoneNumber: string;
  coBorrowerEmail: string;
  coBorrowerCellPhoneNumber: string;
  borrowerMailingAddress: string;
  borrowerMailingCity: string;
  borrowerMailingState: string;
  borrowerMailingZip: string;
  borrowerPropertyAddress: string;
  borrowerPropertyCity: string;
  borrowerPropertyState: string;
  borrowerPropertyZip: string;
  coBorrowerMailingAddress: string;
  coBorrowerMailingCity: string;
  coBorrowerMailingState: string;
  coBorrowerMailingZip: string;
  productType: string;
  vehicleYear: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleVIN: string;
  loanOriginationDate: string;
  legalStatusCode: string;
  appCode: string;
  lastExtensionDate: string;
  extensionLoanToDate: string;
  monthsExtendedLoanToDate: string;
  currentDueDate: string;
  paymentFrequency: string;
  recordCode: string;
  daysPastDue: string;
  bankNumber: string;
  curOffCode: string;
  lN_TYP: string;
  AcctId?: string;
}
export interface ILoanDetailResponse {
  loanDetail: [ILoanDetailResponseObj];
}
export interface IHardshipProps {
  applicant?: 'Coborrower';
}

export interface IAutoLoan {
  year?: string;
  make?: string;
  model?: string;
  vin?: string;
}
export interface IAddress {
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
}
export interface IBorrowerDetails {
  name: string;
  email: string;
  ssn: string;
  dob: string;
  incOnW2: string;
  supIncome: string;
  HomePhoneNumber: string;
  WorkPhoneNumber: string;
  CellPhoneNumber: string;
  LicenseNumber: string;
  LicenseIssueDate: string;
  LicenseExpirationDate: string;
  EmployerName: string;
  StartDateWithEmployer: string;
  GrossMonthlyIncome: string;
  UnemploymentIncome: string;
  ChildSupport: string;
  Rent: string;
  OtherIncome: string;
  OthersIncomeType?: string;
  TotalIncome: string;
  PresentValueOne: string;
  AmountMortgageOne: string;
  MontlyIncomeOne: string;
  MonthlyPaymentOne: string;
  NetIncomeOne: string;
  LienspropertyOne: string;
  ExplainationJudgementOne: string;
  AnyLawsuitOne: string;
  FiledBankruptcyOne: string;
  CheckingAccountOne: string;
  CheckingAccountOneInstitutionName: string;
  CheckingAccountOneBalance: string;
  CheckingAccountTwo: string;
  CheckingAccountTwoInstitutionName: string;
  CheckingAccountTwoBalance: string;
  CheckingAccountThree: string;
  CheckingAccountThreeInstitutionName: string;
  CheckingAccountThreeBalance: string;
  SavingsAccountOne: string;
  SavingsAccountOneInstitutionName: string;
  SavingsAccountOneBalance: string;
  SavingsAccountTwo: string;
  SavingsAccountTwoInstitutionName: string;
  SavingsAccountTwoBalance: string;
  SavingsAccountThree: string;
  SavingsAccountThreeInstitutionName: string;
  SavingsAccountThreeBalance: string;
  IRAAccount: string;
  IRAInstitution: string;
  IRABalance: string;
  StockIdentifier: string;
  StockInstitution: string;
  StockBalance: string;
  OtherAccount: string;
  OtherAccountInstitution: string;
  OtherAccountBalance: string;
  TotalAssets: string;
  mailingAddress: IAddress;
  propertyAddress: IAddress;
}
export interface ICoBorrowerDetails {
  name: string;
  email: string;
  ssn: string;
  dob: string;
  incOnW2: string;
  supIncome: string;
  HomePhoneNumber: string;
  WorkPhoneNumber: string;
  CellPhoneNumber: string;
  LicenseNumber: string;
  LicenseIssueDate: string;
  LicenseExpirationDate: string;
  EmployerName: string;
  StartDateWithEmployer: string;
  CheckingAccountOne: string;
  CheckingAccountOneInstitutionName: string;
  CheckingAccountOneBalance: string;
  CheckingAccountTwo: string;
  CheckingAccountTwoInstitutionName: string;
  CheckingAccountTwoBalance: string;
  CheckingAccountThree: string;
  CheckingAccountThreeInstitutionName: string;
  CheckingAccountThreeBalance: string;
  SavingsAccountOne: string;
  SavingsAccountOneInstitutionName: string;
  SavingsAccountOneBalance: string;
  SavingsAccountTwo: string;
  SavingsAccountTwoInstitutionName: string;
  SavingsAccountTwoBalance: string;
  SavingsAccountThree: string;
  SavingsAccountThreeInstitutionName: string;
  SavingsAccountThreeBalance: string;
  IRAAccount: string;
  IRAInstitution: string;
  IRABalance: string;
  StockIdentifier: string;
  StockInstitution: string;
  StockBalance: string;
  OtherAccount: string;
  OtherAccountInstitution: string;
  OtherAccountBalance: string;
  TotalAssets: string;
  GrossMonthlyIncome: string;
  UnemploymentIncome: string;
  ChildSupport: string;
  Rent: string;
  OtherIncome: string;
  OthersIncomeType?: string;
  TotalIncome: string;
  mailingAddress: IAddress;
  propertyAddress: IAddress;
}
export interface IHousingDetails {
  Type: string;
  MonthlyRental: string;
  PropertyAddress: string;
  PropertyType: string;
  OccupancyType: string;
  InsuranceTaxes: string;
  Explainationsituation: string;
  DateFiling: string;
}
export interface IHardshipAppDetails {
  actionType: string;
  hardshipAppId: string;
  loanAcctNbr: string;
  AcctId?: string;
  loanType: string;
  HardshipReason: string;
  HardshipDetails: string;
  HardshipTerm: string;
  LastUpdatedPageId: string;
  BankiD: string;
  Appl_ID: string;
  Reason: string;
  RequestedMonths: string;
  autoLoan: IAutoLoan;
  borrowerDetails: IBorrowerDetails;
  coBorrowerDetails: ICoBorrowerDetails;
  HousingDetails: IHousingDetails;
}

export const initialHardshipData: IHardshipAppDetails = {
  actionType: '',
  hardshipAppId: '',
  loanAcctNbr: '',
  loanType: '',
  AcctId: '',
  HardshipReason: '',
  HardshipDetails: '',
  HardshipTerm: '',
  LastUpdatedPageId: '',
  BankiD: '',
  Appl_ID: '',
  Reason: '',
  RequestedMonths: '',
  autoLoan: {
    year: '',
    make: '',
    model: '',
    vin: ''
  },
  borrowerDetails: {
    name: '',
    email: '',
    ssn: '',
    dob: '',
    incOnW2: '',
    supIncome: '',
    HomePhoneNumber: '',
    WorkPhoneNumber: '',
    CellPhoneNumber: '',
    LicenseNumber: '',
    LicenseIssueDate: '',
    LicenseExpirationDate: '',
    EmployerName: '',
    StartDateWithEmployer: '',
    GrossMonthlyIncome: '',
    UnemploymentIncome: '',
    ChildSupport: '',
    Rent: '',
    OtherIncome: '',
    OthersIncomeType: '',
    TotalIncome: '',
    PresentValueOne: '',
    AmountMortgageOne: '',
    MontlyIncomeOne: '',
    MonthlyPaymentOne: '',
    NetIncomeOne: '',
    LienspropertyOne: '',
    ExplainationJudgementOne: '',
    AnyLawsuitOne: '',
    FiledBankruptcyOne: '',
    CheckingAccountOne: '',
    CheckingAccountOneInstitutionName: '',
    CheckingAccountOneBalance: '',
    CheckingAccountTwo: '',
    CheckingAccountTwoInstitutionName: '',
    CheckingAccountTwoBalance: '',
    CheckingAccountThree: '',
    CheckingAccountThreeInstitutionName: '',
    CheckingAccountThreeBalance: '',
    SavingsAccountOne: '',
    SavingsAccountOneInstitutionName: '',
    SavingsAccountOneBalance: '',
    SavingsAccountTwo: '',
    SavingsAccountTwoInstitutionName: '',
    SavingsAccountTwoBalance: '',
    SavingsAccountThree: '',
    SavingsAccountThreeInstitutionName: '',
    SavingsAccountThreeBalance: '',
    IRAAccount: '',
    IRAInstitution: '',
    IRABalance: '',
    StockIdentifier: '',
    StockInstitution: '',
    StockBalance: '',
    OtherAccount: '',
    OtherAccountInstitution: '',
    OtherAccountBalance: '',
    TotalAssets: '',
    mailingAddress: {
      streetAddress: '',
      city: '',
      state: '',
      zip: ''
    },
    propertyAddress: {
      streetAddress: '',
      city: '',
      state: '',
      zip: ''
    }
  },
  coBorrowerDetails: {
    name: '',
    email: '',
    ssn: '',
    dob: '',
    incOnW2: '',
    supIncome: '',
    HomePhoneNumber: '',
    WorkPhoneNumber: '',
    CellPhoneNumber: '',
    LicenseNumber: '',
    LicenseIssueDate: '',
    LicenseExpirationDate: '',
    EmployerName: '',
    StartDateWithEmployer: '',
    CheckingAccountOne: '',
    CheckingAccountOneInstitutionName: '',
    CheckingAccountOneBalance: '',
    CheckingAccountTwo: '',
    CheckingAccountTwoInstitutionName: '',
    CheckingAccountTwoBalance: '',
    CheckingAccountThree: '',
    CheckingAccountThreeInstitutionName: '',
    CheckingAccountThreeBalance: '',
    SavingsAccountOne: '',
    SavingsAccountOneInstitutionName: '',
    SavingsAccountOneBalance: '',
    SavingsAccountTwo: '',
    SavingsAccountTwoInstitutionName: '',
    SavingsAccountTwoBalance: '',
    SavingsAccountThree: '',
    SavingsAccountThreeInstitutionName: '',
    SavingsAccountThreeBalance: '',
    IRAAccount: '',
    IRAInstitution: '',
    IRABalance: '',
    StockIdentifier: '',
    StockInstitution: '',
    StockBalance: '',
    OtherAccount: '',
    OtherAccountInstitution: '',
    OtherAccountBalance: '',
    TotalAssets: '',
    GrossMonthlyIncome: '',
    UnemploymentIncome: '',
    ChildSupport: '',
    Rent: '',
    OtherIncome: '',
    OthersIncomeType: '',
    TotalIncome: '',
    mailingAddress: {
      streetAddress: '',
      city: '',
      state: '',
      zip: ''
    },
    propertyAddress: {
      streetAddress: '',
      city: '',
      state: '',
      zip: ''
    }
  },
  HousingDetails: {
    Type: '',
    MonthlyRental: '',
    PropertyAddress: '',
    PropertyType: '',
    OccupancyType: '',
    InsuranceTaxes: '',
    Explainationsituation: '',
    DateFiling: ''
  }
};

export const initialLoanObjDetails: ILoanDetailResponseObj = {
  acctNum: '',
  borrowerName: '',
  borrowerDOB: '',
  borrowerSSN: '',
  coBorrowerName: '',
  coBorrowerDOB: '',
  coBorrowerSSN: '',
  borrowerEmail: '',
  borrowerCellPhoneNumber: '',
  coBorrowerEmail: '',
  coBorrowerCellPhoneNumber: '',
  borrowerMailingAddress: '',
  borrowerMailingCity: '',
  borrowerMailingState: '',
  borrowerMailingZip: '',
  borrowerPropertyAddress: '',
  borrowerPropertyCity: '',
  borrowerPropertyState: '',
  borrowerPropertyZip: '',
  coBorrowerMailingAddress: '',
  coBorrowerMailingCity: '',
  coBorrowerMailingState: '',
  coBorrowerMailingZip: '',
  productType: '',
  vehicleYear: '',
  vehicleMake: '',
  vehicleModel: '',
  vehicleVIN: '',
  loanOriginationDate: '',
  legalStatusCode: '',
  appCode: '',
  lastExtensionDate: '',
  extensionLoanToDate: '',
  monthsExtendedLoanToDate: '',
  currentDueDate: '',
  paymentFrequency: '',
  recordCode: '',
  daysPastDue: '',
  bankNumber: '',
  curOffCode: '',
  lN_TYP: ''
};

export interface IExtensionDetails {
  bank: string;
  app: string;
  acctNum: string;
  checkedBy: string;
  checkedDate: string;
  recommendation: string;
  reason: string;
  comment: string;
  eSign: string;
  makerEmail: string;
  coMakerEmail: string;
  processed: string;
  source: string;
  checkType: string;
  sendLimitLtr: string;
  limitLtrProcessed: string;
  requestedMonths: string;
}

export const initialExtensionDetails = {
  bank: '',
  app: '',
  acctNum: '',
  checkedBy: '',
  checkedDate: '',
  recommendation: '',
  reason: '',
  comment: '',
  eSign: '',
  makerEmail: '',
  coMakerEmail: '',
  processed: '',
  source: '',
  checkType: '',
  sendLimitLtr: '',
  limitLtrProcessed: '',
  requestedMonths: ''
};
