import React, {FC, useState, useEffect, Fragment} from 'react';
import useForm from '../common/UseForm';
import {employmentValidate} from '../common/FormValidationRules';
import Button from '../common/Button';
import {useAppContext} from '../../contexts/AppContext';
import {useNavigate} from 'react-router-dom';
import PageHeading from '../PageHeading/PageHeading';
import calendarIcon from '../../assets/img/icons_package/icon-calendar-lines.svg';
import FormInput from '../common/FormInput';
import DatePicker from 'react-datepicker';
import {
  numberWithCommas,
  getDateString,
  getAccountDisplayStr,
  useDateRange
} from '../../utilities/Utilities';
import {IHardshipProps} from './HardshipsConstants';
import {saveHardshipApplicationData} from '../../services/HardshipService';
import dispatchEvent from '../../ensighten/index';

const HardshipsEmploymentInfo: FC = () => {
  const {
    selectedAccount,
    modalProps,
    setModalProps,
    coBorrower,
    coBorrowerPath,
    setHardshipAppDetails,
    hardshipAppDetails,
    userToken,
    setUserToken,
    setIsLoading,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  const path = coBorrower ? '/CBhardships' : '/hardshipSuccess';
  const deeppath = coBorrowerPath ? '/hardshipSuccess' : path;
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    isSubmitting,
    isSent
  } = useForm(employmentValidate, '');
  const [submitLater, setSubmitLater] = useState<boolean>(false);
  const applicant = coBorrowerPath ? 'Coborrower' : '';
  const [incomeRep, setIncomeRep] = useState('');
  const [errorBlock, setErrorBlock] = useState<boolean>(false);
  const [supplementalIncome, setSupplementalIncome] = useState('');
  const [startDate, setStartDate] = useState();
  const {minDate, maxDate} = useDateRange();
  const navigate = useNavigate();
  useEffect(() => {
    setValues({
      ...values,
      employerName: applicant
        ? hardshipAppDetails.coBorrowerDetails.EmployerName
        : hardshipAppDetails.borrowerDetails.EmployerName,
      empStartDt: applicant
        ? hardshipAppDetails.coBorrowerDetails.StartDateWithEmployer
        : hardshipAppDetails.borrowerDetails.StartDateWithEmployer
    });
    setIncomeRep(
      applicant
        ? hardshipAppDetails.coBorrowerDetails.incOnW2 === '1'
          ? 'True'
          : 'False'
        : hardshipAppDetails.borrowerDetails.incOnW2 === '1'
        ? 'True'
        : 'False'
    );
    setSupplementalIncome(
      applicant
        ? hardshipAppDetails.coBorrowerDetails.supIncome === '1'
          ? 'True'
          : 'False'
        : hardshipAppDetails.borrowerDetails.supIncome === '1'
        ? 'True'
        : 'False'
    );
    
    dispatchEvent(EnsightenDetails, true, false, false, coBorrowerPath);
  }, []);
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent) {
      submitHandleChange();
    }
  }, [errors]);
  const [employmentState, setEmploymentState] = useState(
    applicant === 'Coborrower'
      ? {
          employerName: hardshipAppDetails.coBorrowerDetails.EmployerName,
          startDate: hardshipAppDetails.coBorrowerDetails.StartDateWithEmployer,
          w2: hardshipAppDetails.coBorrowerDetails.incOnW2,
          supplemental: hardshipAppDetails.coBorrowerDetails.supIncome
        }
      : {
          employerName: hardshipAppDetails.borrowerDetails.EmployerName,
          startDate: hardshipAppDetails.borrowerDetails.StartDateWithEmployer,
          w2: hardshipAppDetails.borrowerDetails.incOnW2,
          supplemental: hardshipAppDetails.borrowerDetails.supIncome
        }
  );

  const submitHandleChange = () => {
    setIsLoading(true);
    if (applicant === 'Coborrower') {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          actionType: submitLater ? 'update' : 'submit',
          LastUpdatedPageId: '18',
          coBorrowerDetails: {
            ...hardshipAppDetails.coBorrowerDetails,
            EmployerName: values.employerName,
            StartDateWithEmployer:
              hardshipAppDetails.coBorrowerDetails.StartDateWithEmployer ||
              getDateString(values.empStartDt),
            supIncome: supplementalIncome === 'True' ? '1' : '0',
            incOnW2: incomeRep === 'True' ? '1' : '0'
          }
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('Employment Borrower Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            setUserToken(resp.token);
            console.log('Employment Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              actionType: submitLater ? 'update' : 'submit',
              LastUpdatedPageId: '18',
              coBorrowerDetails: {
                ...hardshipAppDetails.coBorrowerDetails,
                EmployerName: values.employerName,
                StartDateWithEmployer:
                  hardshipAppDetails.coBorrowerDetails.StartDateWithEmployer ||
                  getDateString(values.empStartDt),
                supIncome: supplementalIncome === 'True' ? '1' : '0',
                incOnW2: incomeRep === 'True' ? '1' : '0'
              }
            });
            if (submitLater) {
              setSubmitLater(false);
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate(deeppath);
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
            // history.push(deeppath);
          }
        })
        .catch((err) => console.log(err));
    } else {
      let reqObj;
      if (coBorrower) {
        reqObj = {
          token: userToken,
          transType: 'Collections',
          hardshipAppDetails: {
            ...hardshipAppDetails,
            actionType: 'update',
            LastUpdatedPageId: '11',
            borrowerDetails: {
              ...hardshipAppDetails.borrowerDetails,
              EmployerName: values.employerName,
              StartDateWithEmployer:
                hardshipAppDetails.borrowerDetails.StartDateWithEmployer ||
                getDateString(values.empStartDt),
              supIncome: supplementalIncome === 'True' ? '1' : '0',
              incOnW2: incomeRep === 'True' ? '1' : '0'
            }
          }
        };
      } else {
        reqObj = {
          token: userToken,
          transType: 'Collections',
          hardshipAppDetails: {
            ...hardshipAppDetails,
            actionType: submitLater ? 'update' : 'submit',
            LastUpdatedPageId: '11',
            borrowerDetails: {
              ...hardshipAppDetails.borrowerDetails,
              EmployerName: values.employerName,
              StartDateWithEmployer:
                hardshipAppDetails.borrowerDetails.StartDateWithEmployer ||
                getDateString(values.empStartDt),
              supIncome: supplementalIncome === 'True' ? '1' : '0',
              incOnW2: incomeRep === 'True' ? '1' : '0'
            }
          }
        };
      }
      const resp = saveHardshipApplicationData(reqObj);
      console.log('employment Borrower Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            setUserToken(resp.token);
            console.log('employment Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              actionType: 'update',
              LastUpdatedPageId: '11',
              borrowerDetails: {
                ...hardshipAppDetails.borrowerDetails,
                EmployerName: values.employerName,
                StartDateWithEmployer:
                  hardshipAppDetails.borrowerDetails.StartDateWithEmployer ||
                  getDateString(values.empStartDt),
                supIncome: supplementalIncome === 'True' ? '1' : '0',
                incOnW2: incomeRep === 'True' ? '1' : '0'
              }
            });
            if (submitLater) {
              setSubmitLater(false);
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate(deeppath);
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  // let buttontext = 'Submit';
  useEffect(() => {
    window.scrollTo(0, 0);
    // if (applicant === 'Coborrower' && coBorrower) {
    //   buttontext = 'Submit';
    // } else if (applicant !== 'Coborrower') {
    //   buttontext = 'Submit';
    // } else {
    //   buttontext = 'Next';
    // }
  }, []);
  let calendar: DatePicker;
  const openDatepicker = () => calendar.setOpen(true);

  function handleStartDate(date) {
    setStartDate(date);
    setValues({...values, empStartDt: date});
    setErrorBlock(false);
    setErrors({...errors, empStartDt: undefined});
  }
  return (
    <Fragment>
      <PageHeading title="Employment"></PageHeading>
      {selectedAccount && (
        <div className={'regions-container'}>
          <div className={'page-content-container hardship-card '}>
            <div className="row">
              <div className={'two-column-layout'}>
                <div className="column-one">
                  <h2 className="payment-header">
                    <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                    <span className={'account-number'}>
                      {getAccountDisplayStr(selectedAccount)}
                    </span>
                  </h2>
                  <div className={'well'}>
                    <p className={'confirmation-number'}>{hardshipAppDetails.hardshipAppId}</p>
                    <div id="employment">
                      <p>
                        <strong>{applicant ? applicant + ' ' : ''}Employment</strong>
                      </p>
                      <p>
                        Your answers in the following fields will provide information about
                        employment circumstances. When your details are complete, you can select
                        Submit. Please note that after this you will not be able to select Back to
                        correct any information you have already provided.
                      </p>
                      <p>*Fields marked with an asterisk are required.</p>
                    </div>
                  </div>
                </div>

                <div className="column-two">
                  <form onSubmit={handleSubmit} noValidate>
                    <FormInput
                      id="employerName"
                      title={(applicant ? applicant + ' ' : '') + 'Employer Name*'}
                      type="text"
                      name="employerName"
                      value={values.employerName || ''}
                      handleChange={handleChange}
                      errors={errors.employerName}
                      maxLength={50}
                    />
                    <div className="form-group">
                      <label htmlFor="empStartDt">
                        <strong>{applicant ? applicant + ' ' : ''}Start Date with Employer*</strong>
                      </label>
                      <div className={'position-rel'}>
                        <DatePicker
                          id="empStartDt"
                          ref={(c) => (calendar = c)}
                          className="form-control date-picker"
                          selected={startDate}
                          placeholderText="MM/DD/YYYY"
                          onChange={handleStartDate}
                          name={'empStartDt'}
                          value={values.empStartDt}
                          dateFormat="MM / dd / yyyy"
                          aria-label="empStartDt"
                          minDate={minDate}
                          maxDate={maxDate}
                        />
                        <div className="image-wrapper-div">
                          <img
                            className="img-wrapper"
                            src={calendarIcon}
                            onClick={openDatepicker}
                            alt="Calendar icon"
                          />
                        </div>
                      </div>
                      {(errorBlock || errors.empStartDt) && (
                        <div className="chinstrap form-control">{errors.empStartDt}</div>
                      )}
                    </div>

                    <fieldset className="form-group">
                      <legend className="col-form-label">
                        <strong>Is your income reported on a W-2?</strong>
                      </legend>

                      <div className="form-check form-check-inline">
                        <label className="form-check-label radio-check" htmlFor="reported-yes">
                          Yes
                          <input
                            className="form-check-input"
                            type="radio"
                            name="incomeReported"
                            id="reported-yes"
                            value="True"
                            checked={incomeRep === 'True'}
                            onChange={() => setIncomeRep('True')}
                          />
                          <span className="radioDot"></span>
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label radio-check" htmlFor="reported-no">
                          No
                          <input
                            className="form-check-input"
                            type="radio"
                            name="incomeReported"
                            id="reported-no"
                            value="no"
                            checked={incomeRep === 'False'}
                            onChange={() => setIncomeRep('False')}
                          />
                          <span className="radioDot"></span>
                        </label>
                      </div>
                    </fieldset>
                    <fieldset className="form-group">
                      <legend className="col-form-label">
                        <strong>
                          Are you receiving supplemental Social Security income, pension or
                          disability?
                        </strong>
                      </legend>

                      <div className="form-check form-check-inline">
                        <label
                          className="form-check-label radio-check"
                          htmlFor="supplemental-income"
                        >
                          Yes
                          <input
                            className="form-check-input"
                            type="radio"
                            name="supplemental-income"
                            id="supplemental-income"
                            value="True"
                            checked={supplementalIncome === 'True'}
                            onChange={() => setSupplementalIncome('True')}
                          />
                          <span className="radioDot"></span>
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label
                          className="form-check-label radio-check"
                          htmlFor="no-supplemental-income"
                        >
                          No
                          <input
                            className="form-check-input"
                            type="radio"
                            name="no-supplemental-income"
                            id="no-supplemental-income"
                            value="False"
                            checked={supplementalIncome === 'False'}
                            onChange={() => setSupplementalIncome('False')}
                          />
                          <span className="radioDot"></span>
                        </label>
                      </div>
                    </fieldset>
                    <div className={'column-two-buttons float-right'}>
                      <Button
                        title={coBorrowerPath ? 'Submit' : coBorrower ? 'Next' : 'Submit'}
                        type="submit"
                        className={'next-button float-right'}
                        action={() => setSubmitLater(false)}
                      />
                    </div>
                    <div className="float-left column-two-buttons">
                      <Button
                        title="Back"
                        className="cancel-button float-left"
                        action={() => {
                          applicant === 'Coborrower'
                            ? navigate('/hardships-retirement')
                            : navigate('/hardships-housing');
                        }}
                      />
                    </div>
                    <div className="">
                      <Button
                        title="Save and Continue Later"
                        className={'third-button save-button'}
                        action={(e) => {
                          setSubmitLater(true);
                          handleSubmit(e);
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default HardshipsEmploymentInfo;
