import React, {FC, Fragment} from 'react';
import PageHeading from '../PageHeading/PageHeading';
import {useAppContext} from '../../contexts/AppContext';
import Button from '../common/Button';
import {useNavigate} from 'react-router-dom';
import {getAccountDisplayStr} from '../../utilities/Utilities';
const CallHelpDesk = () => {
  const {selectedAccount, setAccountList} = useAppContext();
  const navigate = useNavigate();
  return (
    <Fragment>
      <PageHeading title="Contact Us" />
      {selectedAccount && (
        <div className={'regions-container'}>
          <div className={'page-content-container hardship-card'}>
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <div className={'one-column-block'}>
                  <h1 className={'sr-only'}>Begin Extension Application</h1>
                  <h2 className="payment-header">
                    <span className={'account-type'}>
                      {selectedAccount.delqProdType} {getAccountDisplayStr(selectedAccount)}
                    </span>
                  </h2>
                  <div>
                    <p>
                      At this time, we are unable to determine hardship assistance options for this
                      account. Please call 1-866-298-1113 Monday through Friday, 8 a.m. to 4:30 p.m.
                      CT, and a Regions associate will be happy to help.
                    </p>
                  </div>

                  <div className="row text-right">
                    <div className="col-sm-12 col-lg-12 button-layout">
                      <Button
                        title="Return to Account Summary"
                        className={'next-button schedule-button float-right'}
                        action={() => {
                          navigate('/landing');
                          setAccountList([]);
                        }}
                      />
                      <Button
                        title="Back"
                        className={'cancel-button float-left'}
                        action={() => navigate('/questionnaire')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CallHelpDesk;
