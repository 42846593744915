import React, { Fragment, useState, createRef, useEffect } from "react";
import { useAppContext } from "../../contexts/AppContext";
import "./AuthorizePayment.scss";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../Alert/Alert";
import PageHeading from "../PageHeading/PageHeading";
import {
  convertMMDDYYYYToMMDDYY,
  numberWithCommas,
  getAccountDisplayStr,
} from "../../utilities/Utilities";
import {
  savePaymentDetails,
  pendingPayments,
} from "../../services/PaymentService";
import dispatchEvent from "../../ensighten/index";

function AuthorizePayment() {
  const navigate = useNavigate();
  const {
    PaymentDetails,
    IsActiveCollections,
    IsRecovery,
    IsGuestCheckout,
    selectedAccount,
    AuthorizedRegionsAccounts,
    setSuccessDetails,
    setUserToken,
    RecoveryAcctNbr,
    GuestCheckoutAcctNbr,
    GuestCheckoutProductType,
    userToken,
    setIsLoading,
    modalProps,
    setModalProps,
    EnsightenDetails,
  } = useAppContext();

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [debitFlag, setDebitFlag] = useState<boolean>(false);
  const [checkPendingFlag, setCheckPendingFlag] = useState<boolean>(false);
  const [authNum, setAuthNum] = useState();
  const [payToAcctType, setPayToAcctType] = useState("");
  const [alertText, setAlertText] = useState("");
  const srText = "Check box unchecked";

  useEffect(() => {
    // checkMultiplePayments(); - removed bc checking is now on payment form
    if ("DC" === PaymentDetails.pmtFrmType) {
      setDebitFlag(true);
    }
    if (selectedAccount) {
      // Collections
      if (
        selectedAccount.delqProdType === "Checking" ||
        selectedAccount.delqProdType === "Savings"
      ) {
        setPayToAcctType("1");
        AuthorizedRegionsAccounts.map((item) => {
          if (
            selectedAccount.delqAcctNbr.substr(-4, 4) ==
            item.authRegAcctNbr.substr(-4, 4)
          ) {
            setAuthNum(item.authRegAcctRtngNbr);
          }
        });
      } else if (
        selectedAccount.delqProdType === "Cards" ||
        selectedAccount.delqProdType === "Card"
      ) {
        setPayToAcctType("3");
      } else {
        setPayToAcctType("2");
      }
    } else if (IsGuestCheckout) {
      // Guest Checkout
      if (
        GuestCheckoutProductType == "Checking" ||
        GuestCheckoutProductType == "Savings"
      ) {
        setPayToAcctType("1");
      } else if (
        GuestCheckoutProductType === "Cards" ||
        GuestCheckoutProductType === "Card"
      ) {
        setPayToAcctType("3");
      } else {
        setPayToAcctType("2");
      }
    } else {
      // Recovery
      setPayToAcctType("6");
    }

    dispatchEvent(
      EnsightenDetails,
      IsActiveCollections,
      IsRecovery,
      IsGuestCheckout
    );
  }, []);

  const checkMultiplePayments = () => {
    if (IsActiveCollections && selectedAccount) {
      setIsLoading(true);
      const pendingPayloadData = {
        token: userToken,
        transType: "Collections",
        PmtToId: selectedAccount.delId,
      };
      const response = pendingPayments(pendingPayloadData);
      response
        .then((res) => {
          setIsLoading(false);
          if (res.token) {
            setUserToken(res.token);
          } else {
            setUserToken(res.response.data.token);
          }

          if (res.pendingPayments.length > 0) {
            res.pendingPayments.map((item) => {
              if (
                new Date(item.pmtDate).toLocaleDateString() ===
                new Date(date).toLocaleDateString()
              ) {
                setModalProps({
                  show: true,
                  title: "",
                  body: `You already have a $ ${formattedPayAmount} payment pending for ****${selectedAccount.delqAcctNbr.substr(
                    -4,
                    4
                  )} on ${formattedDate}. Please choose another payment date or click on Continue to manage payments. `,
                  onSuccessClick: () => {
                    navigate("/managePayments");
                    setModalProps({ ...modalProps, show: false });
                  },
                });
                setCheckPendingFlag(true);
              }
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const submitPayment = (resToken) => {
    setIsLoading(true);
    data.token = resToken;
    const response = savePaymentDetails(data);
    response
      .then((resData) => {
        setIsLoading(false);
        const { paymentSuccessRes, token } = resData;
        if (paymentSuccessRes) {
          const payResponse = {
            confirmationNum: paymentSuccessRes.trackingNbr,
            frmAcctNum: paymentSuccessRes.pmtFrmAcctNbr,
            payAmt: paymentSuccessRes.pmtAmt,
            pmtDate: paymentSuccessRes.pmtDate,
            pmtStatus: paymentSuccessRes.pmtStatus,
            pmtCreated: paymentSuccessRes.pmtCreated,
          };
          setSuccessDetails(payResponse);
          if (token) {
            setUserToken(token);
          }

          navigate("/success");
        } else {
          setUserToken(resData.response.data.token);
          setAlertText(
            `Payment transaction failed. Please try again with a different payment method. If you continue to receive the error message, please contact Regions at ${
              IsActiveCollections ? "866-298-1113" : "1-800-289-6720"
            } .`
          );
          setShowAlert(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const schedulePayment = () => {
    if (isChecked) {
      if (checkPendingFlag) {
        setModalProps({
          show: true,
          title: "",
          body: `You already have a $ ${formattedPayAmount} payment pending for ****${selectedAccount.delqAcctNbr.substr(
            -4,
            4
          )} on ${formattedDate}. Please choose another payment date or click on Continue to manage payments. `,
          onSuccessClick: () => {
            navigate("/managePayments");
            setModalProps({ ...modalProps, show: false });
          },
        });
      } else {
        submitPayment(userToken);
      }
    } else {
      setAlertText(
        "Please check the box authorizing this payment to continue."
      );
      setShowAlert(true);
    }
  };

  const cancelClick = () => {
    if (IsActiveCollections) {
      navigate("/payments");
    } else if (IsRecovery) {
      navigate("/recoveryPayment");
    } else if (IsGuestCheckout) {
      navigate("/guestPayment");
    } else {
      navigate("/payments");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showAlert]);
  const bankAccountNbr = PaymentDetails.existingAcctVal
    ? PaymentDetails.existingAcctVal
    : PaymentDetails.acctNum;
  const date = PaymentDetails.payDate;

  const formattedDate =
    date &&
    `${date.getMonth() + 1 < 10 ? "0" : ""}${date.getMonth() + 1}/${
      date.getDate() < 10 ? "0" : ""
    }${date.getDate()}/${date.getFullYear()}`;
  const accountHolderName = PaymentDetails.acctName;

  const formattedPayAmount = numberWithCommas(
    parseFloat(PaymentDetails.amtPay)
  );

  function getTransactionType() {
    if (IsActiveCollections) {
      return "Collections";
    } else if (IsRecovery) {
      return "Recovery";
    } else if (IsGuestCheckout) {
      return "GuestCheckout";
    }
  }

  function getPaymentVerbiage() {
    const phoneNumber =
      IsActiveCollections || IsGuestCheckout
        ? "1-800-333-4104"
        : "1-800-289-6720";

    const debitVerbiage = `This is to confirm that you are authorizing Regions Bank to process a one-time charge in the amount of $${formattedPayAmount} to your debit card ending in ${PaymentDetails.bankDetails.substr(
      -4,
      4
    )}. The debit card charge will be processed on ${convertMMDDYYYYToMMDDYY(
      formattedDate
    )}. If you want to revoke this authorization, you should contact us by 6PM CST on the business day before this processing date. If you contact us later, we may not have sufficient time to stop the processing of this transaction. If you have any questions regarding this transaction or want to revoke your authorization for it, you can contact Regions at ${phoneNumber} during normal business hours. Do you agree to these terms?`;

    const achVerbiage = `This is to confirm that you are authorizing Regions Bank to process a one-time electronic fund transfer in the amount of $${formattedPayAmount} from your deposit account ending in ${
      bankAccountNbr && bankAccountNbr.substr(-4, 4)
    }. The payment will be processed on ${convertMMDDYYYYToMMDDYY(
      formattedDate
    )} and will post to your account on or after the next business day. If you want to revoke this authorization, you should contact us by 6PM CST on the business day before this processing date. If you contact us later, we may not have sufficient time to stop the processing of this transaction. If you have any questions regarding this transaction or want to revoke your authorization for it, you can contact Regions at ${phoneNumber} during normal business hours. Do you agree to these terms?`;

    return debitFlag ? debitVerbiage : achVerbiage;
  }

  const transactionType = getTransactionType();

  const data = {
    token: userToken,
    transType: transactionType,
    paymentMethodTypeId: PaymentDetails.paymentMethodTypeId,
    makeAPayment: {
      customerId: "",
      pmtTransType: transactionType,
      paymentToAccount: {
        pmtToId: selectedAccount && selectedAccount.delId,
        pmtToAcctType: payToAcctType,
        pmtToAcctNbr:
          RecoveryAcctNbr.trim() ||
          (selectedAccount && selectedAccount.delqAcctNbr) ||
          GuestCheckoutAcctNbr.trim(),
        pmtToAcctPastDueAmt:
          (selectedAccount && selectedAccount.delqAcctPastDueAmt) || 0,
        pmtToAcctMinPymtAmt:
          (selectedAccount && selectedAccount.delqAcctMinPymtAmt) || 0,
        pmtToAuthNum: authNum || "",
        pmtToEmailId: PaymentDetails.emailId || "",
      },
      paymentFrom: {
        pmtFromId: PaymentDetails.pmtFromId || "",
        pmtFrmType: PaymentDetails.pmtFrmType, //set to DC for debit card
        pmtFrmExistingInd: PaymentDetails.existingAcctVal ? "1" : "0",
        pmtFrmNickname: PaymentDetails.pmtFrmNickname,
        pmtFrmAcctId: PaymentDetails.payFrmAcctId,
        pmtFrmHldrName: accountHolderName && accountHolderName.trim(),
        pmtFrmAcctNbr: PaymentDetails.acctNum,
        pmtFrmRtngNbr: PaymentDetails.routingNum,
        savePmtMthdInd: PaymentDetails.savePmtMthdInd || "0", ///need to set to 1 if save selected else 0
        pmtFrmDbtExpDate:
          (PaymentDetails.debitCardObj && PaymentDetails.debitCardObj.expDt) ||
          "",
        pmtFrmDbtTokenNum:
          (PaymentDetails.debitCardObj &&
            PaymentDetails.debitCardObj.debitToken) ||
          "",
        pmtFrmBnkName: PaymentDetails.existingAcctVal
          ? "Regions"
          : PaymentDetails.bankDetails,
      },
      paymentDetail: {
        reasonForLatePmt: "",
        pmtAmt: parseFloat(parseFloat(PaymentDetails.amtPay).toFixed(2)),
        pmtDate:
          date &&
          `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        usrAuthorizePmtInd: "1",
      },
    },
  };
  return (
    <Fragment>
      <PageHeading title={"Terms & Conditions"} />
      <div className="regions-container">
        <div className={"terms-block"}>
          {showAlert && <Alert alertText={alertText} srText={srText} />}
          <h2 className={"secondary-header"}>Authorize Payment</h2>
          <p>Please verify the following information is correct.</p>

          {IsActiveCollections && (
            <h3 className={"payment-details-header"}>
              {selectedAccount && selectedAccount.delqProdType}{" "}
              {" " + getAccountDisplayStr(selectedAccount)}
            </h3>
          )}
          <p className={"payment-details"}>
            <span>Payment Date:</span>
            <span>{formattedDate}</span>
          </p>
          <p className={"payment-details"}>
            <span>Payment Amount:</span>
            <span>${formattedPayAmount}</span>
          </p>
          <p className={"payment-details"}>
            <span>Account Holder Name:</span>
            <span>{accountHolderName}</span>
          </p>
          <p className={"payment-details"}>
            <span>{debitFlag ? "Account :" : "Bank Name:"}</span>
            <span>
              {PaymentDetails.existingAcctVal
                ? "Regions"
                : PaymentDetails.bankDetails}
            </span>
          </p>
          {!debitFlag && (
            <p className={"payment-details"}>
              <span>Bank Account:</span>
              <span>
                ****
                {bankAccountNbr && bankAccountNbr.substr(-4, 4)}
              </span>
            </p>
          )}
          <div className="form-check regions-input-checkbox">
            <label
              className="form-check-label checkbox-label"
              htmlFor="agreeToTerms"
              id="terms-conditions-agreement"
            >
              <input
                className="form-check-input"
                type="checkbox"
                name="AgreeTerms"
                checked={isChecked}
                onChange={() => {
                  setIsChecked(!isChecked);
                }}
                id="agreeToTerms"
                aria-labelledby="terms-conditions-agreement agreeToTerms"
              />
              <span className="checkmark"></span>
              {getPaymentVerbiage()}
            </label>
          </div>
          <p className="third-para">{`Select "Cancel" to edit or "Schedule Payment" to confirm.`}</p>
          <div className={"button-group"}>
            <button
              onClick={() => {
                window.print();
              }}
              className={"third-button"}
            >
              Print
            </button>

            <button onClick={cancelClick} className={"cancel-button"}>
              Cancel
            </button>

            <button
              onClick={() => schedulePayment()}
              type="submit"
              className={"next-button schedule-button"}
            >
              Schedule Payment
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AuthorizePayment;
