import React, {useState, useEffect, Fragment} from 'react';
import {IAccount} from '../AccountDetails/AccountDetails.types';
import PageHeading from '../PageHeading/PageHeading';
import '../AccountDetails/AccountDetails.scss';
import BankruptcyCard from './BankruptcyCard/BankruptcyCard';

function BankruptcyDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <PageHeading title={'Bankruptcy and Judgments'} />

      <BankruptcyCard />
    </Fragment>
  );
}

export default BankruptcyDetails;
