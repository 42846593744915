import React, {FC, Fragment} from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalTitle from 'react-bootstrap/ModalTitle';
import Button from '../common/Button';
import {useAppContext} from '../../contexts/AppContext';
export interface IModalProps {
  show: boolean;
  title: string;
  body: string;
  onSuccessClick: () => void;
}
const ModalBox: FC<IModalProps> = (props) => {
  const {modalProps, setModalProps} = useAppContext();
  const {show, title, body, onSuccessClick} = props;
  return (
    <Fragment>
      <Modal show={show}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Button
            title="Close"
            className="cancel-button"
            action={() => setModalProps({...modalProps, show: false})}
          />

          <Button
            title="Continue"
            type="submit"
            className={'next-button extra-width'}
            action={props.onSuccessClick}
          />
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ModalBox;
