import React, {useState, useEffect, Fragment} from 'react';

import {IAccount} from '../AccountDetails/AccountDetails.types';
import PageHeading from '../PageHeading/PageHeading';
import '../AccountDetails/AccountDetails.scss';
import HardshipPlanInfoCard from './HardshipPlanInfoCard/HardshipPlanInfoCard';

function HardshipPlanInfoDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <PageHeading title={'Application for Hardship Assistance'} />
      <div className={'regions-container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <HardshipPlanInfoCard />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default HardshipPlanInfoDetails;
