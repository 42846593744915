import React, {FC, useState, useEffect, Fragment} from 'react';
import useForm from '../common/UseForm';
import {noValidate} from '../common/FormValidationRules';
import Button from '../common/Button';
import Alert from '../Alert/Alert';
import {useAppContext} from '../../contexts/AppContext';
import {useNavigate} from 'react-router-dom';
import PageHeading from '../PageHeading/PageHeading';
import {numberWithCommas, getAccountDisplayStr} from '../../utilities/Utilities';
import {saveHardshipApplicationData} from '../../services/HardshipService';
import value from '*.jpg';
import dispatchEvent from '../../ensighten/index';

const HardshipsHousing = () => {
  const {
    selectedAccount,
    modalProps,
    setModalProps,
    hardshipAppDetails,
    setHardshipAppDetails,
    userToken,
    setUserToken,
    setIsLoading,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  const [housing, setHousing] = useState(hardshipAppDetails.HousingDetails.Type || '');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const navigate = useNavigate();
  const alertText = 'Please answer the required fields to continue.';
  const srText = 'Radio is not selected';
  // const [submitLater, setSubmitLater] = useState<boolean>(false);
  let submitLater = '';
  const submitHandleChange = () => {
    if (housing === '') {
      setShowAlert(true);
    } else {
      setIsLoading(true);
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          LastUpdatedPageId: '8',
          actionType: 'update',
          HousingDetails: {
            ...hardshipAppDetails.HousingDetails,
            Type: housing
          }
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log(' Housing Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            console.log('\nHousing Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              LastUpdatedPageId: '8',
              actionType: 'update',
              HousingDetails: {
                ...hardshipAppDetails.HousingDetails,
                Type: housing
              }
            });
            setUserToken(resp.token);
            if (submitLater === 'yes') {
              submitLater = '';
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else if (housing === 'own') {
              navigate('/hardships-ownHousing');
            } else if (housing === 'rent') {
              navigate('/hardships-rentalHousing');
            } else {
              setShowAlert(true);
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
            // if (housing === 'own') {
            //   navigate('/hardships-ownHousing');
            // } else if (housing === 'rent') {
            //   navigate('/hardships-rentalHousing');
            // } else {
            //   setShowAlert(true);
            // }
          }
        })
        .catch((err) => console.log(err));
    }
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatchEvent(EnsightenDetails);
  }, []);

  return (
    <Fragment>
      <PageHeading title="Housing"></PageHeading>
      {selectedAccount && (
        <div className={'regions-container'}>
          <div className={'page-content-container hardship-card'}>
            <div className="row">
              <div className={'two-column-layout'}>
                <div className="column-one">
                  <h2 className="payment-header">
                    <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                    <span className={'account-number'}>
                      {getAccountDisplayStr(selectedAccount)}
                    </span>
                  </h2>
                  <div className={'well'}>
                    <div id="housing">
                      <p className={'confirmation-number'}>{hardshipAppDetails.hardshipAppId}</p>
                      <p>
                        <strong>Housing</strong>
                      </p>
                      <p>
                        Your answer to the following question will provide information about your
                        current housing situation.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="column-two">
                  <form noValidate>
                    {showAlert && <Alert alertText={alertText} srText={srText} />}
                    <fieldset className="form-group">
                      <legend className="col-form-label">
                        <strong>Do you own your house or rent?*</strong>
                      </legend>

                      <div className="form-check form-check-inline">
                        <label className="form-check-label radio-check" htmlFor="housing-own">
                          Own
                          <input
                            className="form-check-input"
                            type="radio"
                            name="housingRadioButton"
                            id="housing-own"
                            value="own"
                            checked={housing === 'own'}
                            onChange={(event) => setHousing(event.target.value)}
                          />
                          <span className="radioDot"></span>
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label radio-check" htmlFor="housing-rent">
                          Rent
                          <input
                            className="form-check-input"
                            type="radio"
                            name="housingRadioButton"
                            id="housing-rent"
                            value="rent"
                            checked={housing === 'rent'}
                            onChange={(event) => setHousing(event.target.value)}
                          />
                          <span className="radioDot"></span>
                        </label>
                      </div>
                    </fieldset>
                    <div className={' column-two-buttons float-right'}>
                      <Button
                        title="Next"
                        type="submit"
                        className={'next-button float-right'}
                        action={(e) => {
                          e.preventDefault();
                          submitLater = '';
                          submitHandleChange();
                        }}
                      />
                    </div>
                    <div className="float-left column-two-buttons">
                      <Button
                        title="Back"
                        className="cancel-button float-left"
                        action={(e) => {
                          e.preventDefault();
                          navigate('/hardships-retirement');
                        }}
                      />
                    </div>
                    <div className="">
                      <Button
                        title="Save and Continue Later"
                        className={'third-button save-button'}
                        action={(e) => {
                          e.preventDefault();
                          if (housing === '') {
                            setShowAlert(true);
                          } else {
                            setShowAlert(false);
                            submitLater = 'yes';
                            submitHandleChange();
                          }
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default HardshipsHousing;
