// @ts-ignore
import React, {FC, Fragment, useState} from 'react';
import {IAccountCardProps} from '../AccountDetails.types';
import './AccountCard.scss';
import {Link, useNavigate} from 'react-router-dom';
import {
  numberWithCommas,
  getAccountDisplayStr,
  setResponseToHardshipObj
} from '../../../utilities/Utilities';
import {useAppContext} from '../../../contexts/AppContext';
import {getApplicationDetails} from '../../../services/HardshipService';
import {hardshipPageDictionary} from '../../../components/AppContants';
import {IHardshipAppDetails} from 'components/Hardships/HardshipsConstants';
import ChargeOffRdsInfo from '../../ChargeOffRdsInfo/ChargeOffRdsInfo'

const AccountCard: FC<IAccountCardProps> = (props: IAccountCardProps) => {
  const {account} = props;
  const {
    setSelectedAccount,
    setCoBorrower,
    setHardshipAppDetails,
    userToken,
    setUserToken,
    setIsLoading,
    setCoBorrowerPath
  } = useAppContext();
  const [hardshipObj, setHardshipObj] = useState<IHardshipAppDetails>();
  const navigate = useNavigate();
  const routingHandler = async () => {
    const reqObj = {
      token: userToken,
      transType: 'Collections',
      AcctId: account.delId,
      appStatus: 'P'
    };
    try {
      const response = await getApplicationDetails(reqObj);
      if ('hardshipAppDetail' in response) {
        const appId = response.hardshipAppDetail.lastUpdatedPageId;

        if (parseInt(appId) >= 12) {
          setCoBorrowerPath(true);
        } else {
          setCoBorrowerPath(false);
        }

        if (
          response.hardshipAppDetail.coBorrowerDetails.ssn === '0' ||
          response.hardshipAppDetail.coBorrowerDetails.name === '' ||
          !response.hardshipAppDetail.coBorrowerDetails.name ||
          !response.hardshipAppDetail.coBorrowerDetails.ssn
        ) {
          setCoBorrower(false);
        } else {
          setCoBorrower(true);
        }
        setHardshipObj(response.hardshipAppDetail);
        const hardshipResponseObj = setResponseToHardshipObj(response.hardshipAppDetail);
        setHardshipAppDetails(hardshipResponseObj);
        setUserToken(response.token);
        return appId ? hardshipPageDictionary[`${appId}`] : '/questionnaire';
      } else {
        setUserToken(response.response.data.token);
        navigate('/questionnaire');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const makePayment = () => {

    if (account.existsInRds === true){
      return (
        <Fragment>
          <p className={'card-disabled-link'}>
            Make or Schedule a Payment
            <ChargeOffRdsInfo /> 
          </p>
        </Fragment>
      );
    } else if (account.isApproachingChargeoff){
      return (
        <Fragment></Fragment>
      )
    } else {
      return (
        <Link
        to="/payments"
        onClick={() => {
          setSelectedAccount(account);
        }}
        aria-labelledby={`account-heading-text make-a-payment-${account.delqAcctNbr.substr(
          -4,
          4
        )}`}
      >
          <p
            className={'card-routing-link'}
            id={`make-a-payment-${account.delqAcctNbr.substr(-4, 4)}`}
          >
            Make or Schedule a Payment
          </p>
        </Link>
      )
    }
  }

  return (
    <div className={'accounts-summary'} id={account.delqAcctNbr.substr(-4, 4)}>
      <h2 className={'sr-only'}>Account Ending in {account.delqAcctNbr.substr(-4, 4)}</h2>
      <div className={'account-card'}>
        <div className={'row'}>
          <div className={'col-xl-6 col-lg-6 account-details'}>
            <h2 className={'account-header'}>
              <span className={'account-type'}>{account.delqProdType} </span>
              <span className={'account-number'} id="account-heading-text">
                {getAccountDisplayStr(account)}
              </span>
            </h2>
            <p>              
              <span>Amount Past Due: </span>
              <span>${numberWithCommas(account.delqAcctPastDueAmt)}</span>
            </p>
            <p>
              <span className={'total-balance'}>Total Balance: </span>
              <span>${numberWithCommas(account.delqAcctTotBal)}</span>
            </p>
            <div className={'bal-msg'}> 
              <p>This balance may not reflect all outstanding fees associated with your account.</p>
              <p>For questions concerning your total balance due, please call 1-800-333-4104.</p>
            </div>
            <p className="showDaysPastDue">
              <span>Days Past Due: </span>
              <span>{account.delqAcctDaysPastDue}</span>
            </p>
            <p>
              <span>Last Paid On: </span>
              <span>{account.delqAcctLstPymtDt}</span>
            </p>
          </div>
          <div className={'col-xl-6 col-lg-6 account-links'}>

            <h2 className={'account-header show-header'}>
              Days Past Due: {account.delqAcctDaysPastDue}
            </h2>
            
            {makePayment()}

            {account.delqProdType !== 'Savings' && account.delqProdType !== 'Checking' && (
              <a
                onClick={() => {
                  setIsLoading(true);
                  setSelectedAccount(account);
                  const resp = routingHandler();
                  resp
                    .then((res) => {
                      setIsLoading(false);
                      navigate(res);
                    })
                    .catch((err) => {
                      console.log(err);
                      setIsLoading(false);
                    });
                }}
                aria-labelledby={`account-heading-text apply-hardships-${account.delqAcctNbr.substr(
                  -4,
                  4
                )}`}
              >
                <p
                  className={'card-routing-link'}
                  id={`apply-hardships-${account.delqAcctNbr.substr(-4, 4)}`}
                >
                  Hardship Assistance Application
                </p>
              </a>
            )}
            <Link
              to="/managePayments"
              onClick={() => {
                setSelectedAccount(account);
              }}
              aria-labelledby={`manage-payments-${account.delqAcctNbr.substr(-4, 4)}`}
            >
              <p
                className={'card-routing-link'}
                id={`manage-payments-${account.delqAcctNbr.substr(-4, 4)}`}
              >
                Manage Payments
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountCard;
