import React, {FC, useEffect} from 'react';
import './SuccessCard.scss';
import {Link, useNavigate} from 'react-router-dom';
import {numberWithCommas, getAccountDisplayStr} from '../../../utilities/Utilities';
import {useAppContext} from '../../../contexts/AppContext';
import Button from '../../common/Button';
import dispatchEvent from '../../../ensighten/index';

const SuccessCard = () => {
  const payText = 'You have authorized a payment of:';
  const {
    SuccessDetails,
    PaymentDetails,
    selectedAccount,
    IsActiveCollections,
    IsRecovery,
    IsGuestCheckout,
    RecoveryAcctNbr,
    GuestCheckoutDisplayNbr,
    ExistingRegAcctBal,
    setExistingRegAcctBal,
    AuthorizedRegionsAccounts,
    setAuthorizedRegionsAccounts,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  
  useEffect(() => {
    dispatchEvent(EnsightenDetails, IsActiveCollections, IsRecovery, IsGuestCheckout);
  }, []);

  const navigate = useNavigate();
  const date = PaymentDetails.payDate;
  const formattedDate =
    date &&
    `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}/${
      date.getDate() < 10 ? '0' : ''
    }${date.getDate()}/${date.getFullYear()}`;
  
  const onSuccessHandle = () => {
    setAccountList([]);
    if (PaymentDetails.existingAcctVal) {
      const newArr = AuthorizedRegionsAccounts.map((item) => {
        if (SuccessDetails.frmAcctNum.substr(-4, 4) == item.authRegAcctNbr.substr(-4, 4)) {
          if (ExistingRegAcctBal > 0) {
            item.authRegAcctBalance = ExistingRegAcctBal - SuccessDetails.payAmt;
          }
        }
      });

      setAuthorizedRegionsAccounts([...AuthorizedRegionsAccounts]);
    }

    if (IsRecovery) {
      navigate('/rds');
    } else if (IsGuestCheckout) {
      navigate('/guest');
    } else {
      navigate('/landing');
    }
  };
  return (
    <div className={'inner-block success-card page-content-container'}>
      <div className={'row'}>
        <div className={'col-sm-12'}>
          <div className={'one-column-block'}>
            <h2 className={'secondary-header mb30'}>One-time Payment Scheduled</h2>
            <div className=" well">
              <div className="row ">
                <div className={'col-sm-12 mb30'}>
                  <div>
                    <strong>{payText}</strong>
                  </div>
                  ${SuccessDetails.payAmt && numberWithCommas(SuccessDetails.payAmt)}
                </div>
              </div>
              <div className="row">
                <div className={'col-sm-12'}>
                  <div>
                    <strong>Pay To: </strong>
                  </div>
                  <div>
                    {selectedAccount && selectedAccount.delqProdType}{' '}
                    {(RecoveryAcctNbr && `Account Ending in ${RecoveryAcctNbr.substr(-4, 4)}`) ||
                      (GuestCheckoutDisplayNbr &&
                        `Account Ending in ${GuestCheckoutDisplayNbr.substr(-4, 4)}`) ||
                      getAccountDisplayStr(selectedAccount)}
                  </div>
                </div>
              </div>
              <div className="row success-header">
                <div className={'col-xl-12'}>
                  <p className="pay-header">
                    <span>
                      <strong>Confirmation Number:</strong>
                    </span>
                    <span className="pay-details">{SuccessDetails.confirmationNum}</span>
                  </p>
                  <p className="pay-header">
                    <span>
                      <strong>Pay From:</strong>
                    </span>
                    <span className="pay-details">
                      {'DC' === PaymentDetails.pmtFrmType
                        ? PaymentDetails.bankDetails
                        : `****${
                            SuccessDetails.frmAcctNum && SuccessDetails.frmAcctNum.substr(-4, 4)
                          }`}
                    </span>
                  </p>
                  <p className="pay-header">
                    <span>
                      <strong>Payment Date:</strong>
                    </span>
                    <span className="pay-details">{formattedDate}</span>
                  </p>
                  <p className="pay-header">
                    <span>
                      <strong>Payment Submitted:</strong>
                    </span>
                    {/* <span className="pay-details">{formattedSubmitDate}</span> */}
                    <span className="pay-details">{SuccessDetails.pmtCreated}</span>
                  </p>
                  <p className="pay-header">
                    <span>
                      <strong>Payment Status:</strong>
                    </span>
                    <span className="pay-details last-span">Pending</span>
                  </p>
                </div>
              </div>
            </div>
            <div className={'button-group'}>
              <button
                onClick={() => {
                  window.print();
                }}
                className={'cancel-button float-left'}
              >
                Print
              </button>
              <button className={'next-button float-right'} onClick={onSuccessHandle}>
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessCard;
