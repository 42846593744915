const dispatchEvent = (ensightenEvent, isCollections, isRecovery, isGuestCheckout, isCoborrower) => {
  // if (!('ttd_dom_ready' in window)) {
  //   window.ttd_dom_ready = () => {};
  // }

  // const pageName = () => {
  //   let currPage = window.location.pathname;
  //   let basePage = 'Ind|self service portal|';
  //   let baseHardship = 'hardship assistance ';
    
  //   if (currPage === '/login') {
  //     window.tracking = ensightenEvent;
  //     basePage += 'login';
  //   } else if (currPage === '/challenge') {
  //     basePage += 'security question';
  //   } else if (currPage === '/landing') {
  //     basePage += 'past due account summary';
  //   } else if (currPage === '/managePayments') {
  //     basePage += 'manage payments';
  //   } else if (currPage === '/guest') {    
  //     window.tracking = ensightenEvent;
  //     basePage += 'guest checkout account info';
  //   } else if (currPage === '/guestPayment') {
  //     basePage += 'guest checkout payment'
  //   } else if (currPage === '/authorize') {
  //     if (isCollections) {
  //       basePage += 'make a payment terms and conditions';
  //     } else if (isRecovery) {
  //       basePage += 'recovery terms and conditions';
  //     } else if (isGuestCheckout) {
  //       basePage += 'guest checkout terms and conditions';
  //     }
  //   } else if (currPage === '/success') {
  //     if (isCollections) {
  //       basePage += 'make a payment scheduled';
  //     } else if (isRecovery) {
  //       basePage += 'recovery success';
  //     } else if (isGuestCheckout) {
  //       basePage += 'guest checkout payment scheduled';
  //     }
  //   } else if (currPage === '/rds') {
  //     window.tracking = ensightenEvent;
  //     basePage += 'recovery account info';
  //   } else if (currPage === '/recoveryPayment') {
  //     basePage += 'recovery payment';
  //   } else if (currPage === '/questionnaire') {
  //     basePage = basePage + baseHardship + 'questionnaire';
  //   } else if (currPage === '/hardships') {
  //     basePage = basePage + baseHardship + 'eligibility';
  //   } else if (currPage === '/bankruptcy') {
  //     basePage = basePage + baseHardship + 'bankruptcy';
  //   } else if (currPage === '/personalInfo') {
  //     basePage = `${basePage}${baseHardship}${(isCoborrower && isCoborrower === 'true') ? 'coborrower ' : ''}personal info`;
  //   } else if (currPage === '/driversLicense') {
  //     basePage = `${basePage}${baseHardship}${(isCoborrower && isCoborrower === 'true') ? 'coborrower ' : ''}drivers license`;
  //   } else if (currPage === '/hardships-address') {
  //     basePage = `${basePage}${baseHardship}${(isCoborrower && isCoborrower === 'true') ? 'coborrower ' : ''}address`;
  //   } else if (currPage === '/hardships-income') {
  //     basePage = `${basePage}${baseHardship}${(isCoborrower && isCoborrower === 'true') ? 'coborrower ' : ''}income`;
  //   } else if (currPage === '/hardships-liquidassets') {
  //     basePage = `${basePage}${baseHardship}${(isCoborrower && isCoborrower === 'true') ? 'coborrower ' : ''}checking and savings`;
  //   } else if (currPage === '/hardships-retirement') {
  //     basePage = `${basePage}${baseHardship}${(isCoborrower && isCoborrower === 'true') ? 'coborrower ' : ''}retirement`;
  //   } else if (currPage === '/hardships-housing') {
  //     basePage = basePage + baseHardship + 'housing';
  //   } else if (currPage === '/hardships-rentalHousing') {
  //     basePage = basePage + baseHardship + 'rental amount';
  //   } else if (currPage === '/hardships-ownHousing') {
  //     basePage = basePage + baseHardship + 'housing expense';
  //   } else if (currPage === '/hardships-employment') {
  //     basePage = `${basePage}${baseHardship}${(isCoborrower && isCoborrower === 'true') ? 'coborrower ' : ''}employment`;
  //   } else if (currPage === '/CBhardships') {
  //     basePage = basePage + baseHardship + 'coborrower personal info';
  //   } else if (currPage === '/hardshipSuccess') {
  //     basePage = basePage + baseHardship + 'enrollment success';
  //   }

  //   return basePage;
  // }

  // ensightenEvent.url = window.location.href;
  // ensightenEvent.page = pageName();
  
  // const evt = new CustomEvent('REGIONS.tracking', {detail: ensightenEvent});
  // window.dispatchEvent(evt);
}

export default dispatchEvent;