export const experienceApiEnvironments = {
  fallback: 'https://localhost:5001',
  'selfservice-dev.regionstest.com': 'https://sscp-exp-api-dev.regionstest.com',
  'selfservice-test.regionstest.com': 'https://sscp-exp-api-test.regionstest.com',
  'selfservice-qa.regionstest.com': 'https://sscp-exp-api-qa.regionstest.com',
  'selfservice.regions.com': 'https://sscp-exp-api.regions.com',
  'internal-ui-dev.apps.np-dmz-b.openshift.rgbk.com':
    'https://internal-sscp-exp-api-dev.apps.np-dmz-b.openshift.rgbk.com',
  'internal-ui-test.apps.np-dmz-b.openshift.rgbk.com':
    'https://internal-sscp-exp-api-test.apps.np-dmz-b.openshift.rgbk.com',
  'internal-ui-qa.apps.np-dmz-b.openshift.rgbk.com':
    'https://internal-sscp-exp-api-qa.apps.np-dmz-b.openshift.rgbk.com',
  'internal-ui-dr.apps.prod-dmz-b.openshift.rgbk.com':
    'https://internal-sscp-exp-api-dr.apps.prod-dmz-b.openshift.rgbk.com'
};

// All of our API calls will use this to determine which backend URL to call. The reason choosing the
// API URL this way is because we use Docker and promote pre-built images. This means we cannot read
// the environment variables from the server that is hosting the site because we only have access
// to environment variables at build time and not run time. We are setting the URL to a window object
// so that we do not have to run this query before each API call.
// **NOTE: This is made to be a temporary solution**
export const setExperienceApiUrl = () => {
  const {hostname} = window.location;
  let apiUrl;
  if (!(hostname in experienceApiEnvironments)) {
    apiUrl = experienceApiEnvironments.fallback;
  } else {
    apiUrl = experienceApiEnvironments[hostname];
  }
  return apiUrl;
};

export const setLoginLinks = (url) => {
  switch (url) {
    case 'https://sscp-exp-api.regions.com':
      return {
        Enroll: 'https://onlinebanking.regions.com/enrollment/home?channel=sscp',
        PassReset:
          'https://onlinebanking.regions.com/customerservice/forgottenpassword?channel=sscp',
        IDReminder: 'https://onlinebanking.regions.com/customerservice/Recoveruserid?channel=sscp'
      };
    default:
      return {
        Enroll: 'https://onlinebankingregionsq3.rgbk.com/enrollment/home?channel=sscp',
        PassReset:
          'https://onlinebankingregionsq3.rgbk.com/customerservice/forgottenpassword?channel=sscp',
        IDReminder:
          'https://onlinebankingregionsq3.rgbk.com/customerservice/Recoveruserid?channel=sscp'
      };
  }
};

export const setGuestCheckoutUrl = () => {
  return `${window.location.origin}/guest`
}

export const getCurrentEnvironment = () => {
  const {hostname} = window.location;
  if (hostname.toLowerCase() == 'selfservice-dev.regionstest.com') {
    return "dev";
  }
  else if (hostname.toLowerCase() == 'selfservice-test.regionstest.com') {
    return "test";
  }  
  else if (hostname.toLowerCase() == 'selfservice-qa.regionstest.com') {
    return "qa";
  }  
  else if (hostname.toLowerCase() == 'selfservice-dr.regionstest.com') {
    return "dr";
  }  
  else if (hostname.toLowerCase() == 'selfservice.regions.com') {
    return "prod";
  }  
  else {
    return "local";
  }       
}