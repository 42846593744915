import React, {FC, useEffect, Fragment} from 'react';
import PageHeading from '../PageHeading/PageHeading';
import '../AccountDetails/AccountDetails.scss';
import DriversLicenseCard from './DriversLicenseCard/DriversLicenseCard';
import {constants} from '../common/Constants';

const DriversLicenseDetails: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <PageHeading title={constants.driverLicenseHeader} />

      <DriversLicenseCard />
    </Fragment>
  );
};

export default DriversLicenseDetails;
