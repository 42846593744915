import React, {FC, Fragment, useState, useEffect} from 'react';
import PageHeading from '../PageHeading/PageHeading';
import {useAppContext} from '../../contexts/AppContext';
import Button from '../common/Button';
import {useNavigate} from 'react-router-dom';
import {
  numberWithCommas,
  getPendingPayments,
  getAccountDisplayStr
} from '../../utilities/Utilities';
import {pendingPayments, cancelPayment} from '../../services/PaymentService';
import './ManagePayments.scss';
import dispatchEvent from '../../ensighten/index';

function ManagePayments() {
  const {
    selectedAccount,
    modalProps,
    setModalProps,
    userToken,
    setUserToken,
    setIsLoading,
    setAccountList,
    IsActiveCollections,
    EnsightenDetails
  } = useAppContext();
  const navigate = useNavigate();

  const [paymentsInProcessing, setPaymentsInProcessing] = useState([]);
  const [scheduledPayments, setScheduledPayments] = useState([]);
  const [noPayFlag, setNoPayFlag] = useState(false);

  useEffect(() => {
    selectedAccount &&
      getPendingPayments(
        userToken,
        selectedAccount,
        setIsLoading,
        setUserToken,
        setScheduledPayments,
        setPaymentsInProcessing,
        setNoPayFlag
      );
    setAccountList([]);
    dispatchEvent(EnsightenDetails);
  }, []);

  const cancelHandler = (item) => {
    setModalProps({
      show: true,
      title: 'Payment Cancellation Confirmation.',
      body: `Please confirm that you would like to cancel your payment for Account ending in ****${item.pmtFrmAcctNbr.substr(
        -4,
        4
      )}`,
      onSuccessClick: () => {
        const data = {
          token: userToken,
          transType: 'Collections',
          pmtId: item.pmtID,
          PmtToId: selectedAccount && selectedAccount.delId
        };
        setIsLoading(true);
        const response = cancelPayment(data);
        response
          .then((res) => {
            setIsLoading(false);
            setUserToken(res.token);
            getPendingPayments(
              res.token,
              selectedAccount,
              setIsLoading,
              setUserToken,
              setScheduledPayments,
              setPaymentsInProcessing,
              setNoPayFlag
            );
            console.log('cancel pay res', res);
          })
          .catch((err) => console.log(err));
        setModalProps({...modalProps, show: false});
      }
    });
  };

  return (
    <Fragment>
      <PageHeading title="Manage Payments" />
      <div className={'manage-payments-card regions-container'}>
        <div className="row">
          <div className="col-xl-12 col-sm-12">
            <div className={'one-column-block max-width-block'}>
              <h1 className={'sr-only'}>Manage Payments Block</h1>
              <h2 className="payment-header mb30">
                {selectedAccount && (
                  <span className={'account-type'}>
                    {selectedAccount.delqProdType} {getAccountDisplayStr(selectedAccount)}
                  </span>
                )}
              </h2>
              {noPayFlag && <h3 className={'mb30'}>No pending payments for this account.</h3>}
              {paymentsInProcessing.length > 0 && (
                <Fragment>
                  <h3 className={'mb30'}>Payments in Processing</h3>

                  {paymentsInProcessing.map((item, index) => (
                    <div className={'payment-detail-block'} key={index}>
                      <div>{`$${numberWithCommas(item.pmtAmt)} payment on ${item.pmtDate}`} </div>
                      <div>{`
                      From account ending in ****${item.pmtFrmAcctNbr.substr(-4, 4)}`}</div>
                    </div>
                  ))}
                </Fragment>
              )}
              {scheduledPayments.length > 0 && (
                <Fragment>
                  <h3 className={'mb30'}>Upcoming Scheduled Payments</h3>

                  {scheduledPayments.map((item, index) => (
                    <div className={'payment-detail-block'} key={index}>
                      <div>{`$${numberWithCommas(item.pmtAmt)} payment on ${item.pmtDate}`} </div>
                      <div>{`From account ending in ****${item.pmtFrmAcctNbr.substr(-4, 4)}`}</div>
                      <Button
                        title="Cancel Payment"
                        className={'next-button manage-payments-button'}
                        action={(e) => cancelHandler(item)}
                      />
                    </div>
                  ))}
                </Fragment>
              )}
              <div className="row text-right">
                <div className="col-sm-12 col-lg-12 button-group">
                  <Button
                    title="Back"
                    className={'cancel-button'}
                    action={() => navigate('/landing')}
                  />
                  <Button
                    title="Schedule Another Payment"
                    className={'cancel-button manage-payments-button '}
                    action={() => navigate('/payments')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default ManagePayments;
