import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import {verifyAuthCode} from '../../services/UserLoginService';
import {useAppContext} from '../../contexts/AppContext';

function LogInLoadingCallback() {  
    const navigate = useNavigate();

    const {
        primaryToken,
        setUserToken,
        setPrimaryToken,
        setIsLoading,
      } = useAppContext();     

      const [errorState, setErrorState] = useState<{
        error: boolean;
        errorMsg: string;
      }>({error: false, errorMsg: ''});      

    async function completeLogin () {
        setIsLoading(true);
        const cookies = new Cookies();
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('code');
        const response = verifyAuthCode(authCode, cookies.get('CodeVerifier'));

        response
        .then((res) => {
          if ('token' in res) {
            setIsLoading(false);
            setUserToken(res.token);
            navigate('/landing');
          } else {
            setIsLoading(false);
            setPrimaryToken({...primaryToken, token: res.response.data.token});
            setErrorState({
              ...errorState,
              error: true,
              errorMsg: 'Invalid response. Please try again or call 1-866-298-1113 for assistance.'
            });
          }
        })
        .catch((err) => console.log(err));
    };

    useEffect(() => {        
        completeLogin();
      }, []);
    

    console.log("LogInLoadingCallback");
    return (
        <Fragment>
            <div className={'regions-container'}>
                <div className={'row'}> 
                    <div className="landing-container">
                        <div className={'col-xl-12'}>
                            <h1 className={'first-header'}>Past Due Account Summary</h1>
                        </div>                        
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default LogInLoadingCallback;