// @ts-nocheck
import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Popover, OverlayTrigger, Button as ReactButton} from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import './ChargeOffRdsInfo.scss';
//    `${SiteUrls.LogMessageToServerEndpoint}/info`,
function ChargeOffRdsInfo() {
  const popover = (
    <Popover id="popover-basic">
      {/* Please navigate to {`${window.location.protocol}//${window.location.hostname}/rds`}       <Popover.Title as="h3">Popover right</Popover.Title> */}
      <Popover.Content>
        To make a payment please navigate to the <a href={`${window.location.protocol}//${window.location.hostname}/rds`}>account recovery portal</a>
      </Popover.Content>
    </Popover>
  );
  return (
    <Fragment>
      <OverlayTrigger
        placement="bottom"
        overlay={popover}
        delay={{ show: 0, hide: 1000 }}
      >
        {({ ref, ...triggerHandler }) => (
          <ReactButton
            variant="link"
            {...triggerHandler}
            className="d-inline-flex align-items-left icon-padding"
          >
            <InfoCircleFill ref={ref} color="#528400" size={15} />
          </ReactButton>
        )}
      </OverlayTrigger>
    </Fragment>
  );
}

export default ChargeOffRdsInfo;
