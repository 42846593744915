import React, {FC, useState, useEffect, Fragment} from 'react';
import useForm from '../common/UseForm';
import {noValidate} from '../common/FormValidationRules';
import Button from '../common/Button';
import {useAppContext} from '../../contexts/AppContext';
import FormInput from '../common/FormInput';
import {useNavigate} from 'react-router-dom';
import PageHeading from '../PageHeading/PageHeading';
import {saveHardshipApplicationData} from '../../services/HardshipService';
import {
  numberWithCommas,
  formatNumberToFloat,
  getAccountDisplayStr,
  restrictNumericInput
} from '../../utilities/Utilities';
import dispatchEvent from '../../ensighten/index';

const HardshipsRetirement = () => {
  const {
    selectedAccount,
    coBorrowerPath,
    modalProps,
    setModalProps,
    hardshipAppDetails,
    setHardshipAppDetails,
    userToken,
    setUserToken,
    setIsLoading,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  const path = coBorrowerPath ? '/hardships-employment' : '/hardships-housing';
  const {values, errors, handleChange, handleSubmit, isSubmitting, isSent} = useForm(
    noValidate,
    ''
  );
  const applicant = coBorrowerPath ? 'Coborrower' : '';
  const [submitLater, setSubmitLater] = useState<boolean>(false);
  useEffect(() => {
    dispatchEvent(EnsightenDetails, true, false, false, coBorrowerPath);
  }, []);
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent) {
      submitHandleChange();
    }
  }, [errors]);
  const [retirementState, setRetirementState] = useState(
    applicant === 'Coborrower'
      ? {
          retirement401K: hardshipAppDetails.coBorrowerDetails.IRAAccount,
          retirement401KBal: hardshipAppDetails.coBorrowerDetails.IRABalance,
          stocks: hardshipAppDetails.coBorrowerDetails.StockInstitution,
          stocksBal: hardshipAppDetails.coBorrowerDetails.StockBalance,
          others: hardshipAppDetails.coBorrowerDetails.OtherAccount,
          othersInstitute: hardshipAppDetails.coBorrowerDetails.OtherAccountInstitution,
          othersBal: hardshipAppDetails.coBorrowerDetails.OtherAccountBalance
        }
      : {
          retirement401K: hardshipAppDetails.borrowerDetails.IRAAccount,
          retirement401KBal: hardshipAppDetails.borrowerDetails.IRABalance,
          stocks: hardshipAppDetails.borrowerDetails.StockInstitution,
          stocksBal: hardshipAppDetails.borrowerDetails.StockBalance,
          others: hardshipAppDetails.borrowerDetails.OtherAccount,
          othersInstitute: hardshipAppDetails.borrowerDetails.OtherAccountInstitution,
          othersBal: hardshipAppDetails.borrowerDetails.OtherAccountBalance
        }
  );

  const navigate = useNavigate();
  const submitHandleChange = () => {
    setIsLoading(true);
    if (applicant === 'Coborrower') {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          LastUpdatedPageId: '17',
          actionType: 'update',
          coBorrowerDetails: {
            ...hardshipAppDetails.coBorrowerDetails,
            StockInstitution: retirementState.stocks,
            StockBalance: retirementState.stocksBal,
            OtherAccount: retirementState.others,
            OtherAccountBalance: retirementState.othersBal,
            OtherAccountInstitution: retirementState.othersInstitute,
            IRAAccount: retirementState.retirement401K,
            IRABalance: retirementState.retirement401KBal,
            IRAInstitution: retirementState.retirement401K,
            StockIdentifier: retirementState.stocks,
            TotalAssets: `${(
              formatNumberToFloat(hardshipAppDetails.coBorrowerDetails.TotalAssets) +
              formatNumberToFloat(retirementState.stocksBal) +
              formatNumberToFloat(retirementState.retirement401KBal) +
              formatNumberToFloat(retirementState.othersBal)
            ).toFixed(2)}`
          }
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('retirement CoBorrower Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            setUserToken(resp.token);
            console.log('\nRetirement Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              LastUpdatedPageId: '17',
              actionType: 'update',
              coBorrowerDetails: {
                ...hardshipAppDetails.coBorrowerDetails,
                StockInstitution: retirementState.stocks,
                StockBalance: retirementState.stocksBal,
                OtherAccount: retirementState.others,
                OtherAccountBalance: retirementState.othersBal,
                OtherAccountInstitution: retirementState.othersInstitute,
                IRAAccount: retirementState.retirement401K,
                IRABalance: retirementState.retirement401KBal,
                IRAInstitution: retirementState.retirement401K,
                StockIdentifier: retirementState.stocks,
                TotalAssets: `${(
                  formatNumberToFloat(hardshipAppDetails.borrowerDetails.TotalAssets) +
                  formatNumberToFloat(retirementState.stocksBal) +
                  formatNumberToFloat(retirementState.retirement401KBal) +
                  formatNumberToFloat(retirementState.othersBal)
                ).toFixed(2)}`
              }
            });
            if (submitLater) {
              setSubmitLater(false);
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate(path);
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
          }
        })
        .catch((err) => console.log(err));
    } else {
      const reqObj = {
        token: userToken,
        transType: 'Collections',
        hardshipAppDetails: {
          ...hardshipAppDetails,
          LastUpdatedPageId: '7',
          actionType: 'update',
          borrowerDetails: {
            ...hardshipAppDetails.borrowerDetails,
            StockInstitution: retirementState.stocks,
            StockBalance: retirementState.stocksBal,
            OtherAccount: retirementState.others,
            OtherAccountBalance: retirementState.othersBal,
            OtherAccountInstitution: retirementState.othersInstitute,
            IRAAccount: retirementState.retirement401K,
            IRABalance: retirementState.retirement401KBal,
            IRAInstitution: retirementState.retirement401K,
            StockIdentifier: retirementState.stocks,
            TotalAssets: `${(
              formatNumberToFloat(hardshipAppDetails.borrowerDetails.TotalAssets) +
              formatNumberToFloat(retirementState.stocksBal) +
              formatNumberToFloat(retirementState.retirement401KBal) +
              formatNumberToFloat(retirementState.othersBal)
            ).toFixed(2)}`
          }
        }
      };
      const resp = saveHardshipApplicationData(reqObj);
      console.log('retirement Borrower Obj is ', reqObj);
      resp
        .then((resp) => {
          if ('saveHardshipAppStatus' in resp) {
            setIsLoading(false);
            setUserToken(resp.token);
            console.log('Retirement Response :', resp);
            setHardshipAppDetails({
              ...hardshipAppDetails,
              hardshipAppId: resp.saveHardshipAppStatus.confNbr,
              LastUpdatedPageId: '7',
              actionType: 'update',
              borrowerDetails: {
                ...hardshipAppDetails.borrowerDetails,
                StockInstitution: retirementState.stocks,
                StockBalance: retirementState.stocksBal,
                OtherAccount: retirementState.others,
                OtherAccountBalance: retirementState.othersBal,
                OtherAccountInstitution: retirementState.othersInstitute,
                IRAAccount: retirementState.retirement401K,
                IRABalance: retirementState.retirement401KBal,
                IRAInstitution: retirementState.retirement401K,
                StockIdentifier: retirementState.stocks,
                TotalAssets: `${(
                  formatNumberToFloat(hardshipAppDetails.borrowerDetails.TotalAssets) +
                  formatNumberToFloat(retirementState.stocksBal) +
                  formatNumberToFloat(retirementState.retirement401KBal) +
                  formatNumberToFloat(retirementState.othersBal)
                ).toFixed(2)}`
              }
            });
            if (submitLater) {
              setSubmitLater(false);
              setModalProps({
                show: true,
                title: 'Save and Continue Confirmation.',
                body:
                  'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
                onSuccessClick: () => {
                  setAccountList([]);
                  navigate('/landing');
                  setModalProps({...modalProps, show: false});
                }
              });
            } else {
              navigate(path);
            }
          } else {
            setIsLoading(false);
            setUserToken(resp.response.data.token);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <PageHeading title="Liquid Assets - Retirement Accounts and Investments"></PageHeading>
      {selectedAccount && (
        <div className={'regions-container'}>
          <div className={'page-content-container hardship-card '}>
            <div className="row">
              <div className={'two-column-layout'}>
                <div className="column-one">
                  <h2 className="payment-header">
                    <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                    <span className={'account-number'}>
                      {getAccountDisplayStr(selectedAccount)}
                    </span>
                  </h2>
                  <div className={'well'}>
                    <div id="income">
                      <p className={'confirmation-number'}>{hardshipAppDetails.hardshipAppId}</p>
                      <p>
                        <strong>
                          {applicant ? applicant + ' ' : ''}Liquid Assets - Retirement Accounts and
                          Investments
                        </strong>
                      </p>
                      <p>
                        Your answers in the following fields will provide information about your
                        current retirement and/or investment accounts. If a field does not apply,
                        you can leave it blank.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="column-two">
                  <form onSubmit={handleSubmit} noValidate className={'connected-groups'}>
                    <FormInput
                      id="401K"
                      title={
                        (applicant ? applicant + ' ' : '') +
                        '401(K) / IRA / Keogh / Employee Stock Option Plan Financial Institution'
                      }
                      type="text"
                      name="401(K) / IRA / Keogh / Employee Stock Option Plan Financial Institution"
                      value={retirementState.retirement401K}
                      handleChange={(event) => {
                        setRetirementState({
                          ...retirementState,
                          retirement401K: event.target.value
                        });
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="401K-amount"
                      title={
                        (applicant ? applicant + ' ' : '') +
                        '401(K) / IRA / Keogh / Employee Stock Option Plan Balance'
                      }
                      type="text"
                      name="401(K) / IRA / Keogh / Employee Stock Option Plan Balance"
                      value={retirementState.retirement401KBal}
                      handleChange={(event) => {
                        setRetirementState({
                          ...retirementState,
                          retirement401KBal: restrictNumericInput(
                            event.target.value,
                            retirementState.retirement401KBal
                          )
                        });
                      }}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="stocks"
                      title={
                        (applicant ? applicant + ' ' : '') + 'Stocks / Bonds Financial Institution'
                      }
                      type="text"
                      name="Stocks / Bonds Financial Institution"
                      value={retirementState.stocks}
                      handleChange={(event) => {
                        setRetirementState({...retirementState, stocks: event.target.value});
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="stocks"
                      title={(applicant ? applicant + ' ' : '') + 'Stocks / Bonds Balance'}
                      type="text"
                      name="Stocks / Bonds Balance"
                      value={retirementState.stocksBal}
                      handleChange={(event) => {
                        setRetirementState({
                          ...retirementState,
                          stocksBal: restrictNumericInput(
                            event.target.value,
                            retirementState.stocksBal
                          )
                        });
                      }}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />

                    <FormInput
                      id="Other"
                      title={(applicant ? applicant + ' ' : '') + 'Other Type'}
                      type="text"
                      name="Others (Please Specify)"
                      value={retirementState.others}
                      handleChange={(event) => {
                        setRetirementState({...retirementState, others: event.target.value});
                      }}
                      maxLength={20}
                    />
                    <FormInput
                      id="OtherFI"
                      title={(applicant ? applicant + ' ' : '') + 'Other Financial Institution'}
                      type="text"
                      name="Other Financial Institution"
                      value={retirementState.othersInstitute}
                      handleChange={(event) => {
                        setRetirementState({
                          ...retirementState,
                          othersInstitute: event.target.value
                        });
                      }}
                      maxLength={50}
                    />
                    <FormInput
                      id="other-amount"
                      title={(applicant ? applicant + ' ' : '') + 'Other Balance'}
                      type="text"
                      name="Other Balance"
                      value={retirementState.othersBal}
                      handleChange={(event) => {
                        setRetirementState({
                          ...retirementState,
                          othersBal: restrictNumericInput(
                            event.target.value,
                            retirementState.othersBal
                          )
                        });
                      }}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />
                    <div className={'column-two-buttons float-right'}>
                      <Button
                        title="Next"
                        type="submit"
                        className={'next-button float-right'}
                        action={() => setSubmitLater(false)}
                      />
                    </div>
                    <div className="float-left column-two-buttons">
                      <Button
                        title="Back"
                        className="cancel-button float-left"
                        action={() => {
                          navigate('/hardships-liquidassets');
                        }}
                      />
                    </div>
                    <div className="">
                      <Button
                        title="Save and Continue Later"
                        className={'third-button save-button'}
                        action={(e) => {
                          handleSubmit(e);
                          setSubmitLater(true);
                        }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default HardshipsRetirement;
