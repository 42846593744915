import React, {Fragment, useEffect} from 'react';
import PageHeading from '../PageHeading/PageHeading';
import Button from '../common/Button';
import {useNavigate} from 'react-router-dom';
import {useAppContext} from '../../contexts/AppContext';
import {numberWithCommas, getAccountDisplayStr} from '../../utilities/Utilities';
import dispatchEvent from '../../ensighten/index';
function HardshipsSuccess() {
  const navigate = useNavigate();
  const {
    selectedAccount, 
    setAccountList, 
    EnsightenDetails
  } = useAppContext();

  useEffect(() => {
    dispatchEvent(EnsightenDetails);
  }, []);

  return (
    <Fragment>
      <PageHeading title="Hardship Application Submitted"></PageHeading>
      {selectedAccount && (
        <div className={'regions-container'}>
          <div className={'page-content-container hardship-card one-column-layout'}>
            <div className="row">
              <div className="col-lg-12">
                <div className={'one-column-block'}>
                  <h2 className={'secondary-header mb30'}>
                    <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                    <span className={'account-number'}>
                      {getAccountDisplayStr(selectedAccount)}
                    </span>
                  </h2>
                  <div>
                    <div id="details">
                      <p>
                        You have submitted a hardship application. The Regions Account Solutions
                        team will send you a link to digitally sign the application in order to
                        complete it. Your application will not be considered complete until it is
                        signed.
                      </p>
                    </div>
                  </div>
                  <div className="row text-right">
                    <div className="col-sm-12 col-lg-12 button-layout">
                      {/* <Button
                      title="Back"
                      className="cancel-button float-left"
                      action={() => {
                        navigate('/hardships-employment');
                      }}
                    /> */}

                      <Button
                        title="Done"
                        type="submit"
                        className={'next-button float-right'}
                        action={() => {
                          setAccountList([]);
                          navigate('/landing');
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default HardshipsSuccess;
