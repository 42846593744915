import React, {useState, useEffect, Fragment} from 'react';
import RecoveryPaymentCard from './RecoveryPaymentCard/RecoveryPaymentCard';
import {IAccount} from '../AccountDetails/AccountDetails.types';
import PageHeading from '../PageHeading/PageHeading';
import '../AccountDetails/AccountDetails.scss';

function RecoveryPaymentDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <PageHeading title={'Make a Payment'} />
      <div className={'regions-container'}>
        <div className={'row'}>
          <div className={'col-lg-12'}>
            <RecoveryPaymentCard />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default RecoveryPaymentDetails;
