import React, {FC, useEffect, Fragment} from 'react';
import PageHeading from '../PageHeading/PageHeading';
import '../AccountDetails/AccountDetails.scss';
import PersonalInfoCard from './PersonalInfoCard/PersonalInfoCard';
import {IHardshipProps} from '../Hardships/HardshipsConstants';

const PersonalInfoDetails: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <PageHeading title={'Personal Information'} />

      <PersonalInfoCard />
    </Fragment>
  );
};

export default PersonalInfoDetails;
