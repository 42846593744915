import React, {Fragment, useEffect, useState} from 'react';
import './Login.scss';
import {useNavigate} from 'react-router-dom';
import Alert from '../Alert/Alert';
import {getUserToken} from '../../services/UserLoginService';
import {useAppContext} from '../../contexts/AppContext';
import {EAPSettings, EXPERIENCE_API_URL, LOGIN_URLS, GUEST_CHECKOUT_URL} from '../../utilities/constants';
import PageHeading from '../PageHeading/PageHeading';
import dispatchEvent from '../../ensighten/index';
import {initialEnsighten} from '../../ensighten/Ensighten.types';

function Login() {
  const navigate = useNavigate();
  let alertText = `The Online ID or Password you entered is not correct. Please reenter your information or
  click "Forgot Online ID?" or "Forgot Password?" to verify your identity and regain
  access to your accounts.`;
  const {
    primaryToken,
    setPrimaryToken,
    setIsLoading,
    setAccountList,
    setSelectedAccount,
    EnsightenDetails,
    setEnsightenDetails
  } = useAppContext();
  const srText = 'Error occured during Login';
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loginState, setLoginState] = useState<{
    username: string;
    password: string;
  }>({
    username: '',
    password: ''
  });
  const [errorState, setErrorState] = useState<{
    username: boolean;
    password: boolean;
    userError: string;
    passwordError: string;
  }>({
    username: false,
    password: false,
    userError: '',
    passwordError: ''
  });
  useEffect(() => {
    setAccountList([]);
    setSelectedAccount({});
    setEnsightenDetails(initialEnsighten);
    
    sessionStorage.removeItem('isRefreshed');
    sessionStorage.clear();

    dispatchEvent(EnsightenDetails);
  }, []);
  const handleChange = (evt) => {
    const value = evt.target.value;
    setLoginState({
      ...loginState,
      [evt.target.name]: value
    });
  };
  const validateUserName = () => {
    if (loginState.username.length > 32) {
      setErrorState({
        ...errorState,
        username: true,
        userError: 'A maximum of 32 characters are allowed.'
      });
    } else if (loginState.username.trim() === '') {
      setErrorState({
        ...errorState,
        username: true,
        userError: 'This field is required. Please enter a value.'
      });
    } else {
      setErrorState({
        ...errorState,
        username: false,
        userError: ''
      });
    }
  };
  const validatePassword = () => {
    if (loginState.password.length > 18) {
      setErrorState({
        ...errorState,
        password: true,
        passwordError: 'A maximum of 18 characters are allowed.'
      });
    } else if (loginState.password.trim() === '') {
      setErrorState({
        ...errorState,
        password: true,
        passwordError: 'This field is required. Please enter a value.'
      });
    } else {
      setErrorState({
        ...errorState,
        password: false,
        passwordError: ''
      });
    }
  };
  const loginUser = (event) => {
    event && event.preventDefault();
    if (errorState.password !== true || errorState.username !== true) {
      setIsLoading(true);
      const response = getUserToken(loginState);
      response
        .then((res) => {
          setIsLoading(false);
          if (res.token) {
            setPrimaryToken(res);
            
            EnsightenDetails.id = loginState.username;
            setEnsightenDetails(EnsightenDetails);

            navigate('/challenge');
          } else {
            alertText = alertText + res.title;
            setShowAlert(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          alertText = err;
          setShowAlert(true);
          
          setEnsightenDetails(EnsightenDetails);
        });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.clear();
  }, []);
  window.onbeforeunload = null;
  return (
    <Fragment>
      <PageHeading title={'Regions Account Solutions'} />
      <div className={'regions-container'}>
        <h1 className={'sr-only'}>Self Service Portal Login</h1>
        <div className={'inner-block login-page'}>
          {showAlert && <Alert alertText={alertText} srText={srText} />}
          <div className={'row'}>
            <div className={'two-column-layout'}>
            <div className={'column-one'}>
                <h2>Login with Regions Online Banking To</h2>
                <div className={'row'}>
                  <div className={'col-md-12'}>
                    <div className={'well'}>
                      <h3 className={'sr-only'}>Login</h3>
                      <ul>
                        <li> Access your past due accounts</li>
                        <li> Make payments on past due accounts</li>
                        <li>
                          {' '}
                          Apply for financial hardship assistance
                        </li>
                      </ul>
                      <div className={'text-right'}>
                        <a
                          className="enroll-button button"
                          rel="noreferrer"
                          href={EAPSettings.LoginUrl}
                        >
                          Login
                        </a>
                        <input type="hidden" defaultValue={process.env.NODE_ENV} />
                        <input type="hidden" defaultValue={EXPERIENCE_API_URL} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              

              <div className={'column-two'}>
                <h2>Guest Checkout</h2>
                <div className={'row'}>
                  <div className={'col-md-12'}>
                    <div className={'well'}>
                      <p>No Regions online banking account? No problem! You can now utilize our guest checkout option to make payments on your Regions accounts.</p>
                      <div className={'text-right'}>
                        <a
                          className="enroll-button button"
                          rel="noreferrer"
                          href={GUEST_CHECKOUT_URL}
                        >
                          Guest Checkout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
