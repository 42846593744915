import React from 'react';
import {useNavigate} from 'react-router-dom';

function SiteUnderConstruction() {
  const navigate = useNavigate();
  return (
    <div className={'text-center'}>
      <h1 className="primary-header">Sorry Something went wrong!</h1>
      <h2 className="secondary-header text-center">Please login to continue</h2>
      <div className="row">
        <div className="col-sm-12 col-xl-12 text-center">
          <button className="next-button go-to-login-button" onClick={() => navigate('/login')}>
            Go to Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default SiteUnderConstruction;
