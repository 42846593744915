/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext} from 'react';
import {IcontextValue} from './AppContext.types';
import {initialLoanObjDetails, initialHardshipData} from '../components/Hardships/HardshipsConstants';
import {initialEnsighten} from '../ensighten/Ensighten.types';

const defaultValue: IcontextValue = {
  AccountsDetails: [],
  PaymentDetails: [],
  SuccessDetails: [],
  selectedAccount: {},
  PaymentMethod: {},
  IsActiveCollections: true,
  IsRecovery: false,
  IsGuestCheckout: false,
  isAuthenticated: false,
  StoredPaymentMethods: [],
  AuthorizedRegionsAccounts: [],
  DebitStoredPymtMethods: [],
  modalProps: {
    show: false,
    title: 'Modal Title',
    body: 'Modal Body',
    onSuccessClick: () => {
      return null;
    }
  },
  coBorrower: true,
  coBorrowerPath: false,
  userToken: '',
  isLoading: false,
  primaryToken: {},
  hardshipLoanDetails: initialLoanObjDetails,
  hardshipAppDetails: initialHardshipData,
  RoutingErrText: '',
  RecoveryAcctNbr: '',
  IsAchPaymentEnabled: false,
  HasMatchedPendingPayments: true,
  ExistingRegAcctBal: 0,
  hardshipDuration: '',
  CardNbrStr: '',
  GuestCheckoutAcctNbr: '',
  GuestCheckoutDisplayNbr: '',
  GuestCheckoutMinPayment: '',
  GuestCheckoutMaxPayment: '',
  GuestCheckoutProductType: '',
  EnsightenDetails: initialEnsighten
};
const AppContext = React.createContext<IcontextValue>(defaultValue);
export const useAppContext = () => useContext(AppContext);
export default AppContext;
