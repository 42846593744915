import React, {useState} from 'react';
import ToggleIcon from '../../assets/img/icons_package/ToggleIcon.svg';

const Select = (props) => {

  const renderOptions = () => {
    if (props.isArrayOfObjects && props.isArrayOfObjects === true){
      const arrayListItems = props.options.map((option) => {
        return (
          <option key={option.value} value={option.value} label={option.label} onClick={props.onSelect}>
            {option.label}
          </option>
        );
      });

      return arrayListItems;
    }
    else{      
    const listItems = props.options.map((option) => {
      return (
        <option key={option} value={option} label={option} onClick={props.onSelect}>
          {option}
        </option>
      );
    });

    return listItems;
  }
  };

  return (
    <div className="form-group">
      {props.title && (
        <label htmlFor={props.name} className={props.errors ? 'text-danger' : 'form-label'}>
          <strong>{props.title}</strong>{' '}
        </label>
      )}
      <div className="position-rel">
        <select
          id={props.id}
          name={props.name}
          className={props.errors ? 'border border-danger form-control' : 'form-control'}
          value={props.value}
          onSelect={props.onSelect}
          onBlur={props.onBlur}
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onScroll={(e) => {
            e.currentTarget.blur();
          }}
          onChange={props.handleChange}
          aria-label={props.name}
          disabled={props.disabled}
          defaultValue={''}
          role="combobox"
        >
          <option value={''} disabled>
            {props.placeholder}
          </option>
          { renderOptions() }
        </select>
        <img src={ToggleIcon} alt="Toggle icon" className={'selectToggleIcon'} />
      </div>
      {props.errors && <div className="chinstrap form-control">{props.errors}</div>}
    </div>
  );
};

export default Select;
