import React, {FC, useState, useEffect, Fragment} from 'react';
import useForm from '../common/UseForm';
import {ownHousingValidate} from '../common/FormValidationRules';
import Button from '../common/Button';
import Select from '../common/Select';
import {useAppContext} from '../../contexts/AppContext';
import {useNavigate} from 'react-router-dom';
import PageHeading from '../PageHeading/PageHeading';
import FormInput from '../common/FormInput';
import {saveHardshipApplicationData} from '../../services/HardshipService';
import {
  numberWithCommas,
  formatNumberToFloat,
  getAccountDisplayStr
} from '../../utilities/Utilities';
import value from '*.jpg';
import dispatchEvent from '../../ensighten/index';

const HardshipsOwnHousing = () => {
  const {values, errors, handleChange, handleSubmit, setValues, isSubmitting, isSent} = useForm(
    ownHousingValidate,
    ''
  );
  const [submitLater, setSubmitLater] = useState<boolean>(false);
  const {
    selectedAccount,
    modalProps,
    setModalProps,
    hardshipAppDetails,
    setHardshipAppDetails,
    userToken,
    setUserToken,
    setIsLoading,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  const [liens, setLiens] = useState<boolean>(false);
  const navigate = useNavigate();
  const propertyTypeOptions = [
    'Single Family Home',
    'Townhome',
    'Condominium',
    'Multi Family Home',
    'Manufactured or Mobile Home'
  ];
  useEffect(() => {
    setValues({
      ...values,
      propertyType: hardshipAppDetails.HousingDetails.PropertyType,
      housingPropertyAddress: hardshipAppDetails.HousingDetails.PropertyAddress,
      occupancyType: hardshipAppDetails.HousingDetails.OccupancyType,
      maintenance: hardshipAppDetails.HousingDetails.InsuranceTaxes,
      explain: hardshipAppDetails.HousingDetails.Explainationsituation,
      housingLienRadioButton:
        hardshipAppDetails.borrowerDetails.LienspropertyOne === '1' ? 'True' : 'False',
      presentMarketValue: hardshipAppDetails.borrowerDetails.PresentValueOne,
      amountOfMortgage: hardshipAppDetails.borrowerDetails.AmountMortgageOne,
      mortgagePayment: hardshipAppDetails.borrowerDetails.MonthlyPaymentOne,
      grossRentalIncome: hardshipAppDetails.borrowerDetails.MontlyIncomeOne
    });
    
    dispatchEvent(EnsightenDetails);
  }, []);
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent) {
      submitHandleChange();
    }
  }, [errors]);

  const occupancyTypeOptions = ['Abandoned', 'Owner Occupied', 'Tenant Occupied', 'Vacant'];
  const submitHandleChange = () => {
    setIsLoading(true);
    const reqObj = {
      token: userToken,
      transType: 'Collections',
      hardshipAppDetails: {
        ...hardshipAppDetails,
        actionType: 'update',
        LastUpdatedPageId: '9',
        HousingDetails: {
          ...hardshipAppDetails.HousingDetails,
          PropertyType: values.propertyType,
          PropertyAddress: values.housingPropertyAddress,
          OccupancyType: values.occupancyType,
          InsuranceTaxes: values.maintenance,
          Explainationsituation: values.explain,
          MonthlyRental: ''
        },
        borrowerDetails: {
          ...hardshipAppDetails.borrowerDetails,
          LienspropertyOne: values.housingLienRadioButton === 'True' ? '1' : '0',
          PresentValueOne: values.presentMarketValue,
          AmountMortgageOne: values.amountOfMortgage,
          MonthlyPaymentOne: values.mortgagePayment,
          MontlyIncomeOne: values.grossRentalIncome,
          NetIncomeOne: `${(
            formatNumberToFloat(values.grossRentalIncome) -
            formatNumberToFloat(values.mortgagePayment) -
            formatNumberToFloat(values.maintenance)
          ).toFixed(2)}`
        }
      }
    };
    const resp = saveHardshipApplicationData(reqObj);
    console.log(' Housing Own Obj is ', reqObj);
    resp
      .then((resp) => {
        if ('saveHardshipAppStatus' in resp) {
          setIsLoading(false);
          console.log('\nOwn Housing Response :', resp);
          setHardshipAppDetails({
            ...hardshipAppDetails,
            hardshipAppId: resp.saveHardshipAppStatus.confNbr,
            actionType: 'update',
            LastUpdatedPageId: '9',
            HousingDetails: {
              ...hardshipAppDetails.HousingDetails,
              PropertyType: values.propertyType,
              PropertyAddress: values.housingPropertyAddress,
              OccupancyType: values.occupancyType,
              InsuranceTaxes: values.maintenance,
              Explainationsituation: values.explain,
              MonthlyRental: ''
            },
            borrowerDetails: {
              ...hardshipAppDetails.borrowerDetails,
              LienspropertyOne: values.housingLienRadioButton === 'True' ? '1' : '0',
              PresentValueOne: values.presentMarketValue,
              AmountMortgageOne: values.amountOfMortgage,
              MonthlyPaymentOne: values.mortgagePayment,
              MontlyIncomeOne: values.grossRentalIncome,
              // Net Income = Gross Rental Income - Mortage PAYMENT - Insurance
              NetIncomeOne: `${(
                parseFloat(values.grossRentalIncome) -
                parseFloat(values.mortgagePayment) -
                parseFloat(values.maintenance)
              ).toFixed(2)}`
            }
          });
          setUserToken(resp.token);
          if (submitLater) {
            setSubmitLater(false);
            setModalProps({
              show: true,
              title: 'Save and Continue Confirmation.',
              body:
                'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
              onSuccessClick: () => {
                setAccountList([]);
                navigate('/landing');
                setModalProps({...modalProps, show: false});
              }
            });
          } else {
            navigate('/hardships-employment');
          }
        } else {
          setIsLoading(false);
          setUserToken(resp.response.data.token);
          // navigate('/hardships-employment');
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <PageHeading title="Home Expense"></PageHeading>
      {selectedAccount && (
        <div className={'regions-container'}>
          <div className={'page-content-container hardship-card'}>
            <div className="row">
              <div className={'two-column-layout'}>
                <div className="column-one">
                  <h2 className="payment-header">
                    <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                    <span className={'account-number'}>
                      {getAccountDisplayStr(selectedAccount)}
                    </span>
                  </h2>
                  <div className={'well'}>
                    <div id="housing">
                      <p className={'confirmation-number'}>{hardshipAppDetails.hardshipAppId}</p>
                      <p>
                        <strong>Housing - Owners</strong>
                      </p>
                      <p>
                        Your answers in the following fields will provide information about your
                        monthly home ownership expenses.
                      </p>
                      <p>*Fields marked with an asterisk are required. </p>
                    </div>
                  </div>
                </div>

                <div className="column-two">
                  <form onSubmit={handleSubmit} noValidate>
                    <FormInput
                      id="propertyAddress"
                      title="Property Address*"
                      type="text"
                      name="housingPropertyAddress"
                      value={values.housingPropertyAddress}
                      handleChange={handleChange}
                      errors={errors.housingPropertyAddress}
                      maxLength={100}
                    />
                    <Select
                      id="propertyType"
                      name="propertyType"
                      title="Property Type*"
                      options={propertyTypeOptions}
                      value={values.propertyType}
                      placeholder="Select property type"
                      handleChange={handleChange}
                      errors={errors.propertyType}
                    />
                    <Select
                      id="occupancyType"
                      name="occupancyType"
                      title="Occupancy Type*"
                      options={occupancyTypeOptions}
                      value={values.occupancyType}
                      placeholder="Select occupancy type"
                      handleChange={handleChange}
                      errors={errors.occupancyType}
                    />

                    <FormInput
                      id="presentMarketValue"
                      title="Current Market Value*"
                      type="number"
                      name="presentMarketValue"
                      value={values.presentMarketValue}
                      handleChange={handleChange}
                      errors={errors.presentMarketValue}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />
                    <FormInput
                      id="mortgageAmount"
                      title="Total Amount of Mortgage Loan*"
                      type="number"
                      name="amountOfMortgage"
                      value={values.amountOfMortgage}
                      handleChange={handleChange}
                      errors={errors.amountOfMortgage}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />
                    <FormInput
                      id="rentalIncome"
                      title="Gross Rental Income (Monthly)*"
                      type="number"
                      name="grossRentalIncome"
                      value={values.grossRentalIncome}
                      handleChange={handleChange}
                      errors={errors.grossRentalIncome}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />
                    <FormInput
                      id="mortgagePayment"
                      title="Mortgage Payment (Monthly)*"
                      type="number"
                      name="mortgagePayment"
                      value={values.mortgagePayment}
                      handleChange={handleChange}
                      errors={errors.mortgagePayment}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />
                    <FormInput
                      id="misc"
                      title="Insurance, Maintenance, Taxes and Misc. (Monthly)*"
                      type="number"
                      name="maintenance"
                      value={values.maintenance}
                      handleChange={handleChange}
                      errors={errors.maintenance}
                      prepend={true}
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      maxLength={20}
                    />
                    <fieldset className="form-group">
                      <legend className="col-form-label">
                        <strong>Are there any liens or judgments against the property?*</strong>
                      </legend>

                      <div className="form-check form-check-inline">
                        <label className="form-check-label radio-check" htmlFor="housing-lien-yes">
                          Yes
                          <input
                            className="form-check-input"
                            type="radio"
                            name="housingLienRadioButton"
                            id="housing-lien-yes"
                            value="True"
                            checked={values.housingLienRadioButton === 'True'}
                            onChange={handleChange}
                          />
                          <span className="radioDot"></span>
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label radio-check" htmlFor="housing-lien-no">
                          No
                          <input
                            className="form-check-input"
                            type="radio"
                            name="housingLienRadioButton"
                            id="housing-lien-no"
                            value="False"
                            checked={values.housingLienRadioButton === 'False'}
                            onChange={handleChange}
                          />
                          <span className="radioDot"></span>
                        </label>
                      </div>
                    </fieldset>
                    <div className="form-group">
                      <label htmlFor="explain">
                        <strong>If yes, please explain.</strong>
                      </label>
                      <textarea
                        className="form-control"
                        name="explain"
                        id="explain"
                        onChange={handleChange}
                        value={values.explain}
                        maxLength={100}
                      ></textarea>
                      {errors.explain && (
                        <div className="chinstrap form-control">{errors.explain}</div>
                      )}
                    </div>
                    <div className={'column-two-buttons float-right'}>
                      <Button
                        title="Next"
                        type="submit"
                        className={'next-button float-right'}
                        action={() => setSubmitLater(false)}
                      />
                    </div>
                  </form>
                  <div className="float-left column-two-buttons">
                    <Button
                      title="Back"
                      className="cancel-button float-left"
                      action={() => {
                        navigate('/hardships-housing');
                      }}
                    />
                  </div>
                  <div className="">
                    <Button
                      title="Save and Continue Later"
                      className={'third-button save-button'}
                      action={(e) => {
                        setSubmitLater(true);
                        handleSubmit(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default HardshipsOwnHousing;
