import {useState, useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {IFormTypes} from './PaymentDetailsTypes';
import {useAppContext} from '../../contexts/AppContext';

const useForm = (validate, path) => {
  const [values, setValues] = useState<IFormTypes>({});
  const [errors, setErrors] = useState<IFormTypes>({});
  const [isSent, setIsSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {ExistingRegAcctBal, RoutingErrText, selectedAccount, HasMatchedPendingPayments} = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent && path !== '') {
      navigate(path);
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event != null) event.preventDefault();

    let errorsFound = validate(values, selectedAccount, ExistingRegAcctBal, RoutingErrText, HasMatchedPendingPayments);

    setErrors(errorsFound);
    setIsSubmitting(true);
    setIsSent(true);
    
    if (Object.keys(errorsFound).length === 0 && path !== '') {
      navigate(path);
    }

  };

  const handleChange = (event) => {
    event.persist && event.persist();
    setIsSubmitting(false);
    const newValues = {...values, [event.target.name]: event.target.value};
    isSent && setErrors(validate(newValues, selectedAccount, ExistingRegAcctBal, RoutingErrText, HasMatchedPendingPayments));
    setValues((values) => newValues);
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    setValues,
    setErrors,
    isSubmitting,
    isSent
  };
};

export default useForm;
