import React, {Fragment, useState, useEffect} from 'react';
import PageHeading from '../PageHeading/PageHeading';
import Button from '../common/Button';
import {useNavigate} from 'react-router-dom';
import {useAppContext} from '../../contexts/AppContext';
import {saveHardshipApplicationData} from '../../services/HardshipService';
import {noValidate} from '../common/FormValidationRules';
import useForm from '../common/UseForm';
import dispatchEvent from '../../ensighten/index';

const CoborrowerIntro = () => {
  const {
    setCoBorrowerPath,
    setModalProps,
    modalProps,
    setAccountList,
    setIsLoading,
    setHardshipAppDetails,
    hardshipAppDetails,
    userToken,
    setUserToken,
    EnsightenDetails
  } = useAppContext();
  const {errors, handleSubmit, isSubmitting, isSent} = useForm(noValidate, '');
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent) {
      submitHandleChange();
    }
  }, [errors]);
  useEffect(() => {
    dispatchEvent(EnsightenDetails, true, false, false, true);
  }, []);
  const [submitLater, setSubmitLater] = useState(false);
  const navigate = useNavigate();
  const submitHandleChange = () => {
    setIsLoading(true);
    const reqObj = {
      token: userToken,
      transType: 'Collections',
      hardshipAppDetails: {
        ...hardshipAppDetails,
        LastUpdatedPageId: '0',
        actionType: 'update'
      }
    };
    const resp = saveHardshipApplicationData(reqObj);
    console.log(' Coborrower Intro Obj is ', reqObj);
    resp
      .then((resp) => {
        if ('saveHardshipAppStatus' in resp) {
          setIsLoading(false);
          console.log('\nCoborrower Intro Response :', resp);
          setHardshipAppDetails({
            ...hardshipAppDetails,
            hardshipAppId: resp.saveHardshipAppStatus.confNbr,
            LastUpdatedPageId: '0',
            actionType: 'update'
          });
          setUserToken(resp.token);
          if (submitLater) {
            setModalProps({
              show: true,
              title: 'Save and Continue Confirmation.',
              body:
                'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
              onSuccessClick: () => {
                setSubmitLater(false);
                setAccountList([]);
                navigate('/landing');
                setModalProps({...modalProps, show: false});
              }
            });
          } else {
            navigate('/personalInfo');
          }
        } else {
          setIsLoading(false);
          setUserToken(resp.response.data.token);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Fragment>
      <PageHeading title="Coborrower section"></PageHeading>
      <div className={' regions-container'}>
        <div className={'page-content-container hardship-card one-column-layout'}>
          <div className="row">
            <div className="col-lg-12">
              <form onSubmit={handleSubmit} noValidate>
                <div className={'one-column-block'}>
                  <h2 className="payment-header">
                    Please continue onward to complete the coborrower portion of the application
                  </h2>
                  <div id="details">
                    <p>
                      You have successfully completed the borrower section of the application.
                      Please continue onward to complete the coborrower portion of the application.
                      Your coborrower will verify the information and provide their signature after
                      you have submitted the application.
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="float-right button-layout">
                        <Button
                          title="Next"
                          type="submit"
                          className="next-button text-center "
                          action={() => {
                            setCoBorrowerPath(true);
                            // history.push('/personalInfo');
                            submitHandleChange();
                          }}
                        />
                      </div>
                      <div className="float-left button-layout">
                        <Button
                          title="Back"
                          className="cancel-button float-left "
                          action={() => {
                            setCoBorrowerPath(false);
                            navigate('/hardships-employment');
                          }}
                        />
                      </div>
                      <div className="">
                        <Button
                          title="Save and Continue Later"
                          className={'third-button save-button'}
                          action={(e) => {
                            setCoBorrowerPath(true);
                            setSubmitLater(true);
                            handleSubmit(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CoborrowerIntro;
