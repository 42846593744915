import React, {Fragment, useEffect} from 'react';
import * as oauth from 'oauth4webapi'
import Cookies from 'universal-cookie';
import {TransmitSettings} from '../../utilities/constants'
// import axios from 'axios';

function LogInLoading() {
    let code_verifier = '';
    let code_challenge = '';

    async function beginLogin () {
        code_verifier = oauth.generateRandomCodeVerifier()
        code_challenge = await oauth.calculatePKCECodeChallenge(code_verifier)
        //const code_challenge_method = 'S256'
        //const urlRedirect = `https://tsauthdev.rgbk.com:443/api/v2/oidc/oidc_dev/authorize?client_id=q1891423lasf0943568sf&response_type=code&scope=openid+sscp&redirect_uri=http%3a%2f%2flocalhost%3a3000%2fsign-in%2fcallback&nonce=n-0S6_WzA2Mj&prompt=none&code_challenge_method=S256&code_challenge=${code_challenge}`;

        const cookies = new Cookies();
        const expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + 15);

          cookies.set('CodeVerifier', code_verifier, {
            path: '/',
            expires: expirationDate,
            secure: true,
          });            

       window.location.replace(TransmitSettings.GetFullUrl(code_challenge));  
    }

    useEffect(() => {
      beginLogin();
      }, []);
    

    console.log("LogInLoading");
    return (
        <Fragment>
        </Fragment>
    );
}

export default LogInLoading;