import axios from 'axios';
import {e200, e401, e500} from './StatusConstants';
import {EXPERIENCE_API_URL} from '../utilities/constants';

export const getUserToken = async (loginObj) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/authorize/login`,
      // 'https://sscp-exp-api-dev.regionstest.com/authorize/login',
      loginObj,
      options
    );
    const responseObj = res.data;
    switch (responseObj) {
      case responseObj.errorCode === 500:
        return e500;
      case responseObj.errorCode === 401:
        return e401;
      case responseObj.errorCode === 200:
        return responseObj;
      default:
        return responseObj;
    }
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
  }
};

export const validateChallengeQuestion = async (challengeObj) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/authorize/challenge/answer`,
      // 'https://sscp-exp-api-dev.regionstest.com/authorize/challenge/answer',
      challengeObj,
      options
    );
    const responseObj = res.data;
    switch (responseObj) {
      case responseObj.errorCode === 500:
        return e500;
      case responseObj.errorCode === 401:
        return e401;
      case responseObj.errorCode === 200:
        return responseObj;
      default:
        return responseObj;
    }
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};

export const onLogOut = async (data) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(`${EXPERIENCE_API_URL}/Authorize/logout`, data, options);
    const responseObj = res.data;
    return responseObj;
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};

export const verifyAuthCode = async (authCode, codeVerifier) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/authorize/verify`,
      {
        AuthCode: authCode,
        CodeVerifier: codeVerifier,
      },
      options
    );
    const responseObj = res.data;
    switch (responseObj) {
      case responseObj.errorCode === 500:
        return e500;
      case responseObj.errorCode === 401:
        return e401;
      case responseObj.errorCode === 200:
        return responseObj;
      default:
        return responseObj;
    }
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};
