import {IFormTypes} from './PaymentDetailsTypes';
import {constants} from '../common/Constants';
import {numberWithCommas} from '../../utilities/Utilities';
import { RegExStrings } from '../../utilities/constants';
import {match} from 'assert';

// const emailCheck = (emailVal) => {
//   const lastDotPos = emailVal.lastIndexOf('.');
//   const len = emailVal.length - lastDotPos > 2;

//   return len;
// };

export const validate = (values, selectedAccount, ExistingRegAcctBal, RoutingErrText, HasMatchedPendingPayments) => {
  const errors: IFormTypes = {};
  console.log('amountPay', ExistingRegAcctBal, values.amtPayVal);
  if (values.amtPayVal < selectedAccount.delqAcctMinPymtAmt) {
    errors.amtPayVal = `Please pay at least $ ${numberWithCommas(
      selectedAccount.delqAcctMinPymtAmt
    )}.`;
  } else if (values.amtPayVal > selectedAccount.delqAcctPastDueAmt) {
    errors.amtPayVal = `Amount past due is $ ${numberWithCommas(
      selectedAccount.delqAcctPastDueAmt
    )}.`;
  } else if (
    (ExistingRegAcctBal || ExistingRegAcctBal == 0) &&
    values.amtPayVal > ExistingRegAcctBal
  ) {
    errors.amtPayVal = `Selected Regions Pay From account balance is $ ${numberWithCommas(
      ExistingRegAcctBal
    )}. Please select another account.`;
  }

  if (!values.paymentMethod) {
    errors.paymentMethod = 'Payment method is required.';
  }
  if (values.paymentMethod == constants.useExistingAccountText) {
    if (!values.existingAcct) {
      errors.existingAcct = 'Please select an account.';
    }
    if (!values.acctName) {
      errors.acctName = 'Please enter the name.';
    }
  }
  if (values.paymentMethod == constants.otherSavedAcctText) {
    if (!values.otherSavedAcnt) {
      errors.otherSavedAcnt = 'Please select an account.';
    }
    if (!values.acctName) {
      errors.acctName = 'Please enter the name.';
    }
  }
  if (values.paymentMethod == constants.addDebitCardTxt && !values.acctName) {
    errors.acctName = 'Please enter the name.';
  }
  if (values.paymentMethod === constants.addCheckingOrSavingText) {
    console.log('in pay');
    // savePaymentValidate(values, RoutingErrText);
    if (!values.acctName) {
      errors.acctName = 'Name is Required.';
    }
    if (!values.acctNum) {
      errors.acctNum = 'Account Number is Required.';
    } else if (/\D/.test(values.acctNum)) {
      errors.acctNum = 'Account Number should be numeric.';
    } else if (values.acctNum && RoutingErrText) {
      console.log('errtext::', RoutingErrText);
      if ('Please enter valid routing number.' != RoutingErrText) {
        errors.acctNum = RoutingErrText; //'invalid account';
      }
    }
    if (values.confirmAcctNum !== values.acctNum) {
      errors.confirmAcctNum = 'Account numbers do not match.';
    }
    if (!values.routingNum) {
      errors.routingNum = 'Routing Number is Required.';
    } else if (/\D/.test(values.routingNum)) {
      errors.routingNum = 'Routing Number should be numeric.';
    } else if (values.routingNum && RoutingErrText) {
      console.log('errtext::', RoutingErrText);
      errors.routingNum = RoutingErrText; //'invalid routing';
    }
  }
  if (values.actHolderEmailId) {
    const emailVal = values.actHolderEmailId;
    const lastDotPos = emailVal.lastIndexOf('.');
    const len = emailVal.length - lastDotPos > 2;

    if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailVal) && len)) {
      errors.actHolderEmailId = 'Please provide valid email id.';
    }
  }

  if (HasMatchedPendingPayments){
    errors.payDate = 'Cannot schedule a payment on same date as another payment';
  }

  return errors;
};

export const recoveryPaymentValidate = (
  values,
  selectedAccount,
  ExistingRegAcctBal,
  RoutingErrText
) => {
  const errors: IFormTypes = {};
  console.log('values.amtPayRecovery', values.amtPayRecovery);
  if (!values.amtPayRecovery) {
    errors.amtPayRecovery = 'Please enter a value.';
  } else if (values.amtPayRecovery < 5) {
    errors.amtPayRecovery = 'Please pay atleast $5.00.';
  }
  if (!values.paymentMethod) {
    errors.paymentMethod = 'Payment method is required.';
  }
  if (values.paymentMethod == constants.addDebitCardTxt && !values.acctName) {
    errors.acctName = 'Please enter the name.';
  }
  if (values.paymentMethod === constants.addCheckingOrSavingText) {
    console.log('in pay');
    // savePaymentValidate(values, RoutingErrText);
    if (!values.acctName) {
      errors.acctName = 'Name is Required.';
    }
    if (!values.acctNum) {
      errors.acctNum = 'Account Number is Required.';
    } else if (/\D/.test(values.acctNum)) {
      errors.acctNum = 'Account Number should be numeric.';
    } else if (values.acctNum && RoutingErrText) {
      console.log('errtext::', RoutingErrText);
      if ('Please enter valid routing number.' != RoutingErrText) {
        errors.acctNum = RoutingErrText; //'invalid account';
      }
    }
    if (values.confirmAcctNum !== values.acctNum) {
      errors.confirmAcctNum = 'Account numbers do not match.';
    }
    if (!values.routingNum) {
      errors.routingNum = 'Routing Number is Required.';
    } else if (/\D/.test(values.routingNum)) {
      errors.routingNum = 'Routing Number should be numeric.';
    } else if (values.routingNum && RoutingErrText) {
      console.log('errtext::', RoutingErrText);
      errors.routingNum = RoutingErrText; //'invalid routing';
    }
  }
  if (values.actHolderEmailId) {
    const emailVal = values.actHolderEmailId;
    const lastDotPos = emailVal.lastIndexOf('.');
    const len = emailVal.length - lastDotPos > 2;

    if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailVal) && len)) {
      errors.actHolderEmailId = 'Please provide valid email id.';
    }
  }

  return errors;
};

export const guestCheckoutPaymentValidate = (values, RoutingErrText) => {
  const errors: IFormTypes = {};
  if (!values.paymentAmountGC) {
    errors.paymentAmountGC = 'Please enter a value.';
  } else if (values.paymentAmountGC < values.minPayment) {
    errors.paymentAmountGC = `Please pay at least $${numberWithCommas(values.minPayment)}.`;
  } else if (values.paymentAmountGC > values.maxPayment) {
    errors.paymentAmountGC = `Amount exceeds maximum payment amount.`;
  }

  if (!values.paymentMethod) {
    errors.paymentMethod = 'Payment method is required.';
  }

  if (values.paymentMethod == constants.addDebitCardTxt && !values.acctName) {
    errors.acctName = 'Please enter the name.';
  }

  if (values.paymentMethod === constants.addCheckingOrSavingText) {
    if (!values.acctName) {
      errors.acctName = 'Name is Required.';
    }

    if (!values.acctNum) {
      errors.acctNum = 'Account Number is Required.';
    } else if (/\D/.test(values.acctNum)) {
      errors.acctNum = 'Account Number should be numeric.';
    } else if (values.acctNum && RoutingErrText) {
      console.log('errtext::', RoutingErrText);
      if ('Please enter valid routing number.' != RoutingErrText) {
        errors.acctNum = RoutingErrText; //'invalid account';
      }
    }

    if (values.confirmAcctNum !== values.acctNum) {
      errors.confirmAcctNum = 'Account numbers do not match.';
    }

    if (!values.routingNum) {
      errors.routingNum = 'Routing Number is Required.';
    } else if (/\D/.test(values.routingNum)) {
      errors.routingNum = 'Routing Number should be numeric.';
    } else if (values.routingNum && RoutingErrText) {
      console.log('errtext::', RoutingErrText);
      errors.routingNum = RoutingErrText; //'invalid routing';
    }
  }

  if (values.actHolderEmailId) {
    const emailVal = values.actHolderEmailId;
    const lastDotPos = emailVal.lastIndexOf('.');
    const len = emailVal.length - lastDotPos > 2;

    if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailVal) && len)) {
      errors.actHolderEmailId = 'Please provide valid email id.';
    }
  }

  return errors;
};

export const savePaymentValidate = (values, RoutingErrText) => {
  const errors: IFormTypes = {};
  console.log('errtext12::', RoutingErrText);
  if (!values.acctName) {
    errors.acctName = 'Name is Required.';
  }
  if (!values.acctNum) {
    errors.acctNum = 'Account Number is Required.';
  } else if (/\D/.test(values.acctNum)) {
    errors.acctNum = 'Account Number should be numeric.';
  } else if (values.acctNum && RoutingErrText) {
    console.log('errtext::', RoutingErrText);
    if ('Please enter valid routing number.' != RoutingErrText) {
      errors.acctNum = RoutingErrText; //'invalid account';
    }
  }
  if (values.confirmAcctNum !== values.acctNum) {
    errors.confirmAcctNum = 'Account numbers do not match.';
  }
  if (!values.routingNum) {
    errors.routingNum = 'Routing Number is Required.';
  } else if (/\D/.test(values.routingNum)) {
    errors.routingNum = 'Routing Number should be numeric.';
  } else if (values.routingNum && RoutingErrText) {
    console.log('errtext::', RoutingErrText);
    errors.routingNum = RoutingErrText; //'invalid routing';
  }
  // if (values.actHolderEmailId) {
  //   const emailVal = values.actHolderEmailId;
  //   const lastDotPos = emailVal.lastIndexOf('.');
  //   const len = emailVal.length - lastDotPos > 2;

  //   if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailVal) && len)) {
  //     errors.actHolderEmailId = 'Please provide valid email id.';
  //   }
  // }

  return errors;
};
const checkInValidity = (value: string) => {
  const trueValue = value
    .replace(/_/g, '')
    .replace(/ /g, '')
    .replace(/-/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '');

  if (trueValue.length < 10) {
    return true;
  } else {
    return false;
  }
};
export const personalInfoValidate = (values) => {
  const errors: IFormTypes = {};
  if (!values.emailId) {
    errors.emailId = 'Email is required.';
  } else if (values.emailId) {
    const lastDotPos = values.emailId.lastIndexOf('.');
    const len = values.emailId.length - lastDotPos > 2;
    if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.emailId) && len)) {
      errors.emailId = 'Please provide valid email id.';
    }
  }
  if (!values.cellPhone && !values.workPhone && !values.homePhone) {
    errors.cellPhone = 'Please provide atleast one phone number.';
  }
  if (values.cellPhone && checkInValidity(values.cellPhone)) {
    errors.cellPhone = 'Please enter a valid phone number.';
  }
  if (values.workPhone && checkInValidity(values.workPhone)) {
    errors.workPhone = 'Please enter a valid phone number.';
  }
  if (values.homePhone && checkInValidity(values.homePhone)) {
    errors.homePhone = 'Please enter a valid phone number.';
  }
  if (!values.additionalDetails) {
    errors.additionalDetails = 'Hardship explanation is required.';
  }
  return errors;
};
export const CoBorrowerPersonalInfoValidate = (values) => {
  const errors: IFormTypes = {};
  if (!values.emailCoborrower) {
    errors.emailCoborrower = 'Email is required.';
  } else if (values.emailCoborrower) {
    const lastDotPos = values.emailCoborrower.lastIndexOf('.');
    const len = values.emailCoborrower.length - lastDotPos > 2;
    if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.emailCoborrower) && len)) {
      errors.emailCoborrower = 'Please provide valid email id.';
    }
  }
  if (!values.cellPhone && !values.workPhone && !values.homePhone) {
    errors.cellPhone = 'Please provide atleast one phone number.';
  }
  if (values.cellPhone && checkInValidity(values.cellPhone)) {
    errors.cellPhone = 'Please enter a valid phone number.';
  }
  if (values.workPhone && checkInValidity(values.workPhone)) {
    errors.workPhone = 'Please enter a valid phone number.';
  }
  if (values.homePhone && checkInValidity(values.homePhone)) {
    errors.homePhone = 'Please enter a valid phone number.';
  }
  if (values.emailId == values.emailCoborrower) {
    errors.emailCoborrower = 'Borrower and Coborrower must have different emails.';
  }
  return errors;
};

export const driversLicenseValidate = (values) => {
  const errors: IFormTypes = {};

  if (!values.dlNum) {
    errors.dlNum = 'Drivers License Number is required.';
  }
  // if (/\D/.test(values.dlNum)) {
  //   errors.dlNum = 'Please provide a valid License Number.';
  // }
  if (values.dlNum && values.dlNum.length > 20) {
    errors.dlNum = 'Maximum 20 characters are allowed.';
  }
  if (!values.dlExpDt) {
    errors.dlExpDt = 'The Expiry date is required.';
  }
  if (!values.dlIsDt) {
    errors.dlIsDt = 'The issue date is required.';
  }
  return errors;
};

export const incomeValidate = (values) => {
  const errors: IFormTypes = {};

  if (!values.grossMonthlyIncome) {
    errors.grossMonthlyIncome = 'Gross Monthly Income is required.';
  } else if (/\D/.test(values.grossMonthlyIncome)) {
    errors.grossMonthlyIncome = 'Please update valid value in Gross Monthly Income field.';
  } else if (values.grossMonthlyIncome.length > 20) {
    errors.grossMonthlyIncome = 'Maximum of 20 digits are allowed.';
  }
  return errors;
};

export const houseRentValidate = (values) => {
  const errors: IFormTypes = {};

  if (!values.monthlyRentAmount) {
    errors.monthlyRentAmount = 'Monthly rent amount is required.';
  } else if (/\D/.test(values.monthlyRentAmount)) {
    errors.monthlyRentAmount = 'Please update valid value in Monthly Rent Amount field.';
  } else if (values.monthlyRentAmount.length > 20) {
    errors.monthlyRentAmount = 'Maximum of 20 digits are allowed.';
  }
  return errors;
};

export const addressValidate = (values) => {
  const errors: IFormTypes = {};

  if (!values.mailingStreet) {
    errors.mailingStreet = 'Mailing Street Address is required.';
  }
  if (!values.mailingCity) {
    errors.mailingCity = 'Mailing Address City is required.';
    // } else if (!/[a-zA-Z]+/.test(values.mailingCity)) {
  } else if (!/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/.test(values.mailingCity)) {
    errors.mailingCity = 'Please update valid value in Mailing Address City field.';
  }
  if (!values.mailingState) {
    errors.mailingState = 'Mailing State is required.';
  }
  if (!values.mailingZip) {
    errors.mailingZip = 'Mailing Zip Code is required.';
  } else if (values.mailingZip.length !== 5) {
    errors.mailingZip = 'Please provide a valid zip code.';
  }
  if (!values.propertyStreet) {
    errors.propertyStreet = 'Property Street Address is required.';
  }
  if (!values.propertyCity) {
    errors.propertyCity = 'Property Address City is required.';
  } else if (!/\D/.test(values.propertyCity)) {
    errors.propertyCity = 'Please update valid value in Property Address City field.';
  }
  if (!values.propertyState) {
    errors.propertyState = 'Property State is required.';
  }
  if (!values.propertyZip) {
    errors.propertyZip = 'Property Zip Code is required.';
  } else if (values.propertyZip.length !== 5) {
    errors.propertyZip = 'Please provide a valid zip code.';
  }
  return errors;
};

export const employmentValidate = (values) => {
  const errors: IFormTypes = {};

  if (!values.employerName) {
    errors.employerName = 'Employer Name is required.';
  }
  if (!values.empStartDt) {
    errors.empStartDt = 'Employment Start Date is required.';
  }
  return errors;
};

export const ownHousingValidate = (values) => {
  const errors: IFormTypes = {};

  if (!values.housingPropertyAddress) {
    errors.housingPropertyAddress = 'Property address is required.';
  }
  if (!values.propertyType) {
    errors.propertyType = 'Property type is required.';
  }
  if (!values.occupancyType) {
    errors.occupancyType = 'Occupancy type is required.';
  }
  if (!values.presentMarketValue) {
    errors.presentMarketValue = 'Present Market Value is required.';
  }
  if (/\D/.test(values.presentMarketValue)) {
    errors.presentMarketValue = 'Present Market Value should be numeric.';
  }
  if (!values.amountOfMortgage) {
    errors.amountOfMortgage = 'Amount Of Mortgage is required.';
  }
  if (/\D/.test(values.amountOfMortgage)) {
    errors.amountOfMortgage = 'Amount Of Mortgage should be numeric.';
  }
  if (!values.grossRentalIncome) {
    errors.grossRentalIncome = 'Gross Rental Income is required.';
  }
  if (/\D/.test(values.grossRentalIncome)) {
    errors.grossRentalIncome = 'Gross Rental Income should be numeric.';
  }
  if (!values.mortgagePayment) {
    errors.mortgagePayment = 'Mortgage Payment is required.';
  }
  if (/\D/.test(values.mortgagePayment)) {
    errors.mortgagePayment = 'Mortgage Payment should be numeric';
  }
  if (!values.maintenance) {
    errors.maintenance = 'Please provide a value.';
  }
  if (/\D/.test(values.maintenance)) {
    errors.maintenance = 'This value should be numeric.';
  }
  if (values.presentMarketValue.length > 20) {
    errors.presentMarketValue = 'Maximum of 20 digits are allowed.';
  }
  if (values.amountOfMortgage.length > 20) {
    errors.amountOfMortgage = 'Maximum of 20 digits are allowed.';
  }
  if (values.grossRentalIncome.length > 20) {
    errors.grossRentalIncome = 'Maximum of 20 digits are allowed.';
  }
  if (values.mortgagePayment.length > 20) {
    errors.mortgagePayment = 'Maximum of 20 digits are allowed.';
  }
  if (values.maintenance.length > 20) {
    errors.maintenance = 'Maximum of 20 digits are allowed.';
  }
  if (values.housingLienRadioButton == 'True' && !values.explain) {
    errors.explain = 'Please provide an explanation.';
  }
  return errors;
};

export const bankruptcyValidate = (values) => {
  const errors: IFormTypes = {};

  if (values.judegmentRadios == 'True' && !values.explainJudgement) {
    errors.explainJudgement = 'Please provide an explanation.';
  }
  if (values.filedRadios == 'True' && !values.filingDt) {
    errors.filingDt = 'Please provide date.';
  }
  return errors;
};

export const extensionValidate = (values) => {
  const errors: IFormTypes = {};
  if (!values.extensionEmployed) {
    errors.extensionEmployed = 'Employment field is required.';
  }
  else if (values.extensionEmployed === 'no') {
    if(!values.extensionReportableIncome) {
      errors.extensionReportableIncome = 'Reportable income field is required.';
    }
  }

  if (!values.extensionDuration) {
    errors.extensionDuration = 'Hardship duration is required.';
  }
  if (!values.extensionReason) {
    errors.extensionReason = 'Hardship reason is required';
  }
  return errors;
};

export const isValidDate = (datestring, mustBeFutureDate) => {
  console.log(`must be future date: ${mustBeFutureDate}`);
  const isValidFormat = RegExStrings.IsDate.test(datestring);
  let isValid = false;
  const today = new Date();
  if (isValidFormat && mustBeFutureDate) {
    const currentPaymentDate = new Date(datestring);
    console.log(`checking past date`);
    // payment date is in future year
    if (currentPaymentDate.getFullYear() > today.getFullYear()) {
      isValid = true;
      console.log(`future year`);
    }
    // payment date is in current year
    else if (currentPaymentDate.getFullYear() === today.getFullYear()) {
      // payment date is in future month of same year
      console.log(`found in same year`);
      if (currentPaymentDate.getMonth() > today.getMonth()) {
        isValid = true;
        console.log(`future month of same year`);
      }
      // payment date is in same month and same year
      else if (currentPaymentDate.getMonth() === today.getMonth()) {
        // payment date is same date, month, and year
        console.log(`found in same month and year`);
        if (currentPaymentDate.getDate() >= today.getDate()) {
          console.log(`same month and year and date is >= current date`);
          isValid = true;
        }
      }
    }
  } else {
    isValid = isValidFormat;
  }

  return isValid;
};


export const noValidate = (values) => {
  return {};
};
