/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import React from 'react';

const Health = () => {
  return (
    <html lang="en">
      <head />
      <body>works</body>
    </html>
  );
};

export default Health;
