import React, {FC, useState, useEffect} from 'react';

import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import Button from '../../common/Button';
import calendarIcon from '../../../assets/img/icons_package/icon-calendar-lines.svg';
import './BankruptcyCard.scss';
import useForm from '../../common/UseForm';
import {bankruptcyValidate} from '../../common/FormValidationRules';
import {useAppContext} from '../../../contexts/AppContext';
import {
  numberWithCommas,
  getDateString,
  getAccountDisplayStr,
  useDateRange
} from '../../../utilities/Utilities';
import DatePicker from 'react-datepicker';
import {saveHardshipApplicationData} from '../../../services/HardshipService';
import dispatchEvent from '../../../ensighten/index';

const BankruptcyCard = () => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    isSent,
    isSubmitting
  } = useForm(bankruptcyValidate, '');
  const {
    selectedAccount,
    modalProps,
    setModalProps,
    setHardshipAppDetails,
    hardshipAppDetails,
    userToken,
    setUserToken,
    setIsLoading,
    hardshipLoanDetails,
    CardNbrStr,
    hardshipReason,
    hardshipDuration,
    setAccountList,
    EnsightenDetails
  } = useAppContext();
  const navigate = useNavigate();
  const [filingDate, setFilingDate] = useState();
  const [errorFilingDate, setErrorFilingDate] = useState<boolean>(false);
  const [bankruptcyObj, setbankruptcyObj] = useState({judegmentRadios: '', filedRadios: ''});
  const [submitLater, setSubmitLater] = useState<boolean>(false);
  const {minDate, maxDate} = useDateRange();
  function handleFilingDate(date) {
    setFilingDate(date);
    setValues({...values, filingDt: date});
    setErrorFilingDate(false);
    setErrors({...errors, filingDt: undefined});
  }
  let calendar: DatePicker;
  const openDatepicker = () => calendar.setOpen(true);
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && isSent) {
      submitHandleChange();
    }
  }, [errors]);
  useEffect(() => {
    setValues({
      ...values,
      explainJudgement: hardshipAppDetails.borrowerDetails.ExplainationJudgementOne,
      judegmentRadios: hardshipAppDetails.borrowerDetails.AnyLawsuitOne === '1' ? 'True' : 'False',
      filedRadios: hardshipAppDetails.borrowerDetails.FiledBankruptcyOne === '1' ? 'True' : 'False',
      filingDt: hardshipAppDetails.HousingDetails.DateFiling
    });
    dispatchEvent(EnsightenDetails);
  }, []);

  const submitHandleChange = () => {
    setIsLoading(true);
    let action = '';
    if (hardshipAppDetails.hardshipAppId) {
      action = 'update';
    } else {
      action = 'insert';
    }
    const reqObj = {
      token: userToken,
      transType: 'Collections',
      hardshipAppDetails: {
        ...hardshipAppDetails,
        actionType: action,
        LastUpdatedPageId: '1',
        Reason: hardshipReason || hardshipAppDetails.HardshipReason,
        HardshipReason: hardshipReason || hardshipAppDetails.HardshipReason,
        HardshipTerm: hardshipDuration || hardshipAppDetails.HardshipTerm,
        Appl_ID: hardshipAppDetails.Appl_ID || hardshipLoanDetails.appCode || '725',
        loanAcctNbr: hardshipAppDetails.loanAcctNbr || hardshipLoanDetails.acctNum || '',
        BankiD: hardshipAppDetails.BankiD || hardshipLoanDetails.bankNumber || '',
        loanType: hardshipAppDetails.loanType || hardshipLoanDetails.lN_TYP || '',
        autoLoan: {
          make: hardshipAppDetails.autoLoan.make || hardshipLoanDetails.vehicleMake || '',
          model: hardshipAppDetails.autoLoan.model || hardshipLoanDetails.vehicleModel || '',
          year: hardshipAppDetails.autoLoan.year || hardshipLoanDetails.vehicleYear || '',
          vin: hardshipAppDetails.autoLoan.vin || hardshipLoanDetails.vehicleVIN || ''
        },
        HousingDetails: {
          ...hardshipAppDetails.HousingDetails,
          DateFiling: hardshipAppDetails.HousingDetails.DateFiling
            ? hardshipAppDetails.HousingDetails.DateFiling
            : filingDate
            ? getDateString(filingDate)
            : ''
        },
        borrowerDetails: {
          ...hardshipAppDetails.borrowerDetails,
          name: hardshipAppDetails.borrowerDetails.name || hardshipLoanDetails.borrowerName || '',
          email:
            hardshipAppDetails.borrowerDetails.email || hardshipLoanDetails.borrowerEmail || '',
          ssn: hardshipAppDetails.borrowerDetails.ssn || hardshipLoanDetails.borrowerSSN || '',
          dob: hardshipAppDetails.borrowerDetails.dob || hardshipLoanDetails.borrowerDOB || '',
          // incOnW2: hardshipAppDetails.borrowerDetails.incOnW2 || '0',
          // supIncome: hardshipAppDetails.borrowerDetails.supIncome || '0',
          ExplainationJudgementOne:
            hardshipAppDetails.borrowerDetails.ExplainationJudgementOne ||
            values.explainJudgement ||
            '',
          AnyLawsuitOne: values.judegmentRadios === 'True' ? '1' : '0',
          FiledBankruptcyOne: values.filedRadios === 'True' ? '1' : '0',
          mailingAddress: {
            streetAddress:
              hardshipAppDetails.borrowerDetails.mailingAddress.streetAddress ||
              hardshipLoanDetails.borrowerMailingAddress ||
              '',
            city:
              hardshipAppDetails.borrowerDetails.mailingAddress.city ||
              hardshipLoanDetails.borrowerMailingCity ||
              '',
            state:
              hardshipAppDetails.borrowerDetails.mailingAddress.state ||
              hardshipLoanDetails.borrowerMailingState ||
              '',
            zip:
              hardshipAppDetails.borrowerDetails.mailingAddress.zip ||
              hardshipLoanDetails.borrowerMailingZip ||
              ''
          },
          propertyAddress: {
            streetAddress:
              hardshipAppDetails.borrowerDetails.propertyAddress.streetAddress ||
              hardshipLoanDetails.borrowerPropertyAddress ||
              '',
            city:
              hardshipAppDetails.borrowerDetails.propertyAddress.city ||
              hardshipLoanDetails.borrowerPropertyCity ||
              '',
            state:
              hardshipAppDetails.borrowerDetails.propertyAddress.state ||
              hardshipLoanDetails.borrowerPropertyState ||
              '',
            zip:
              hardshipAppDetails.borrowerDetails.propertyAddress.zip ||
              hardshipLoanDetails.borrowerPropertyZip ||
              ''
          }
        },
        coBorrowerDetails: {
          ...hardshipAppDetails.coBorrowerDetails,
          name:
            hardshipAppDetails.coBorrowerDetails.name || hardshipLoanDetails.coBorrowerName || '',
          email:
            hardshipAppDetails.coBorrowerDetails.email || hardshipLoanDetails.coBorrowerEmail || '',
          ssn: hardshipAppDetails.coBorrowerDetails.ssn || hardshipLoanDetails.coBorrowerSSN || '',
          dob: hardshipAppDetails.coBorrowerDetails.dob || hardshipLoanDetails.coBorrowerDOB || '',
          // incOnW2: hardshipAppDetails.coBorrowerDetails.incOnW2 || '0',
          // supIncome: hardshipAppDetails.coBorrowerDetails.supIncome || '0',
          mailingAddress: {
            streetAddress:
              hardshipAppDetails.coBorrowerDetails.mailingAddress.streetAddress ||
              hardshipLoanDetails.coBorrowerMailingAddress ||
              '',
            city:
              hardshipAppDetails.coBorrowerDetails.mailingAddress.city ||
              hardshipLoanDetails.coBorrowerMailingCity ||
              '',
            state:
              hardshipAppDetails.coBorrowerDetails.mailingAddress.state ||
              hardshipLoanDetails.coBorrowerMailingState ||
              '',
            zip:
              hardshipAppDetails.coBorrowerDetails.mailingAddress.zip ||
              hardshipLoanDetails.coBorrowerMailingZip ||
              ''
          }
        }
      }
    };
    const resp = saveHardshipApplicationData(reqObj);
    console.log(' Bankruptsy Obj is ', reqObj);

    resp
      .then((resp) => {
        if ('saveHardshipAppStatus' in resp) {
          setIsLoading(false);
          setUserToken(resp.token);
          console.log('\nBankruptsy Response :', resp);
          setHardshipAppDetails({
            ...hardshipAppDetails,
            actionType: 'insert',
            hardshipAppId: resp.saveHardshipAppStatus.confNbr,
            LastUpdatedPageId: '1',
            Reason: hardshipReason || hardshipAppDetails.HardshipReason,
            HardshipReason: hardshipReason || hardshipAppDetails.HardshipReason,
            HardshipTerm: hardshipDuration || hardshipAppDetails.HardshipTerm,
            Appl_ID: hardshipAppDetails.Appl_ID || hardshipLoanDetails.appCode || '725',
            loanAcctNbr: hardshipAppDetails.loanAcctNbr || hardshipLoanDetails.acctNum || '',
            BankiD: hardshipAppDetails.BankiD || hardshipLoanDetails.bankNumber || '',
            loanType: hardshipAppDetails.loanType || hardshipLoanDetails.lN_TYP || '',
            autoLoan: {
              make: hardshipAppDetails.autoLoan.make || hardshipLoanDetails.vehicleMake || '',
              model: hardshipAppDetails.autoLoan.model || hardshipLoanDetails.vehicleModel || '',
              year: hardshipAppDetails.autoLoan.year || hardshipLoanDetails.vehicleYear || '',
              vin: hardshipAppDetails.autoLoan.vin || hardshipLoanDetails.vehicleVIN || ''
            },
            HousingDetails: {
              ...hardshipAppDetails.HousingDetails,
              DateFiling: hardshipAppDetails.HousingDetails.DateFiling
                ? hardshipAppDetails.HousingDetails.DateFiling
                : filingDate
                ? getDateString(filingDate)
                : ''
            },
            borrowerDetails: {
              ...hardshipAppDetails.borrowerDetails,
              name:
                hardshipAppDetails.borrowerDetails.name || hardshipLoanDetails.borrowerName || '',
              email:
                hardshipAppDetails.borrowerDetails.email || hardshipLoanDetails.borrowerEmail || '',
              ssn: hardshipAppDetails.borrowerDetails.ssn || hardshipLoanDetails.borrowerSSN || '',
              dob: hardshipAppDetails.borrowerDetails.dob || hardshipLoanDetails.borrowerDOB || '',
              incOnW2: hardshipAppDetails.borrowerDetails.incOnW2 || '0',
              supIncome: hardshipAppDetails.borrowerDetails.supIncome || '0',
              ExplainationJudgementOne:
                hardshipAppDetails.borrowerDetails.ExplainationJudgementOne ||
                values.explainJudgement ||
                '',
              AnyLawsuitOne: values.judegmentRadios === 'True' ? '1' : '0',
              FiledBankruptcyOne: values.filedRadios === 'True' ? '1' : '0',
              mailingAddress: {
                streetAddress:
                  hardshipAppDetails.borrowerDetails.mailingAddress.streetAddress ||
                  hardshipLoanDetails.borrowerMailingAddress ||
                  '',
                city:
                  hardshipAppDetails.borrowerDetails.mailingAddress.city ||
                  hardshipLoanDetails.borrowerMailingCity ||
                  '',
                state:
                  hardshipAppDetails.borrowerDetails.mailingAddress.state ||
                  hardshipLoanDetails.borrowerMailingState ||
                  '',
                zip:
                  hardshipAppDetails.borrowerDetails.mailingAddress.zip ||
                  hardshipLoanDetails.borrowerMailingZip ||
                  ''
              },
              propertyAddress: {
                streetAddress:
                  hardshipAppDetails.borrowerDetails.propertyAddress.streetAddress ||
                  hardshipLoanDetails.borrowerPropertyAddress ||
                  '',
                city:
                  hardshipAppDetails.borrowerDetails.propertyAddress.city ||
                  hardshipLoanDetails.borrowerPropertyCity ||
                  '',
                state:
                  hardshipAppDetails.borrowerDetails.propertyAddress.state ||
                  hardshipLoanDetails.borrowerPropertyState ||
                  '',
                zip:
                  hardshipAppDetails.borrowerDetails.propertyAddress.zip ||
                  hardshipLoanDetails.borrowerPropertyZip ||
                  ''
              }
            },
            coBorrowerDetails: {
              ...hardshipAppDetails.coBorrowerDetails,
              name:
                hardshipAppDetails.coBorrowerDetails.name ||
                hardshipLoanDetails.coBorrowerName ||
                '',
              email:
                hardshipAppDetails.coBorrowerDetails.email ||
                hardshipLoanDetails.coBorrowerEmail ||
                '',
              ssn:
                hardshipAppDetails.coBorrowerDetails.ssn || hardshipLoanDetails.coBorrowerSSN || '',
              dob:
                hardshipAppDetails.coBorrowerDetails.dob || hardshipLoanDetails.coBorrowerDOB || '',
              incOnW2: hardshipAppDetails.coBorrowerDetails.incOnW2 || '0',
              supIncome: hardshipAppDetails.coBorrowerDetails.supIncome || '0',
              mailingAddress: {
                streetAddress:
                  hardshipAppDetails.coBorrowerDetails.mailingAddress.streetAddress ||
                  hardshipLoanDetails.coBorrowerMailingAddress ||
                  '',
                city:
                  hardshipAppDetails.coBorrowerDetails.mailingAddress.city ||
                  hardshipLoanDetails.coBorrowerMailingCity ||
                  '',
                state:
                  hardshipAppDetails.coBorrowerDetails.mailingAddress.state ||
                  hardshipLoanDetails.coBorrowerMailingState ||
                  '',
                zip:
                  hardshipAppDetails.coBorrowerDetails.mailingAddress.zip ||
                  hardshipLoanDetails.coBorrowerMailingZip ||
                  ''
              }
            }
          });
          if (submitLater) {
            setModalProps({
              show: true,
              title: 'Save and Continue Confirmation.',
              body:
                'Your application progress has been saved. You will be able to access the application by clicking "continue hardship application" on the accounts summary page for the next 24 hours and then your application will expire.',
              onSuccessClick: () => {
                setAccountList([]);
                navigate('/landing');
                setModalProps({...modalProps, show: false});
              }
            });
          } else {
            navigate('/personalInfo');
          }
        } else {
          setIsLoading(false);
          setUserToken(resp.response.data.token);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={'regions-container'}>
      {selectedAccount && (
        <div className={'page-content-container bankruptcy-card hardship-card '}>
          <div className="row">
            <div className={'two-column-layout'}>
              <div className="column-one">
                <h2 className="payment-header">
                  <span className={'account-type'}>{selectedAccount.delqProdType} </span>
                  <span className={'account-number'}>{getAccountDisplayStr(selectedAccount)}</span>
                </h2>
                <div className={'well'}>
                  <div id="bankruptcy-screen">
                    <p>
                      <strong>Bankruptcy and Judgments</strong>
                    </p>
                    <p>
                      Your answers to the following questions will provide information about any
                      lawsuits, bankruptcies or judgments that may be applicable to you or to this
                      account.
                    </p>
                  </div>
                </div>
              </div>

              <div className="column-two">
                <form onSubmit={handleSubmit} noValidate>
                  <fieldset className="form-group connected-fields">
                    <legend className="col-form-label">
                      <strong>
                        Are there any outstanding judgments against you or are you party to a
                        lawsuit?
                      </strong>
                    </legend>

                    <div className="form-check form-check-inline">
                      <label className="form-check-label radio-check" htmlFor="judegmentYes">
                        Yes
                        <input
                          className="form-check-input"
                          type="radio"
                          name="judegmentRadios"
                          id="judegmentYes"
                          value="True"
                          checked={values.judegmentRadios === 'True'}
                          onChange={handleChange}
                        />
                        <span className="radioDot"></span>
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label radio-check" htmlFor="judegmentNo">
                        No
                        <input
                          className="form-check-input"
                          type="radio"
                          name="judegmentRadios"
                          id="judegmentNo"
                          value="False"
                          defaultChecked
                          checked={values.judegmentRadios === 'False'}
                          onChange={handleChange}
                        />
                        <span className="radioDot"></span>
                      </label>
                    </div>
                  </fieldset>
                  <div className="form-group">
                    <label htmlFor="explainJudgement">
                      <strong>If yes, please explain.</strong>
                    </label>
                    <textarea
                      className="form-control"
                      name="explainJudgement"
                      id="explainJudgement"
                      onChange={handleChange}
                      value={values.explainJudgement}
                      maxLength={100}
                    ></textarea>
                    {errors.explainJudgement && (
                      <div className="chinstrap form-control">{errors.explainJudgement}</div>
                    )}
                  </div>
                  <fieldset className="form-group connected-fields">
                    <legend className="col-form-label">
                      <strong>Have you ever filed bankruptcy?</strong>
                    </legend>

                    <div className="form-check form-check-inline">
                      <label className="form-check-label radio-check" htmlFor="filedYes">
                        Yes
                        <input
                          className="form-check-input"
                          type="radio"
                          name="filedRadios"
                          id="filedYes"
                          value="True"
                          checked={values.filedRadios === 'True'}
                          onChange={handleChange}
                        />
                        <span className="radioDot"></span>
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <label className="form-check-label radio-check" htmlFor="filedNo">
                        No
                        <input
                          className="form-check-input"
                          type="radio"
                          name="filedRadios"
                          id="filedNo"
                          value="False"
                          checked={values.filedRadios === 'False'}
                          onChange={handleChange}
                        />
                        <span className="radioDot"></span>
                      </label>
                    </div>
                  </fieldset>
                  <div className="form-group">
                    <label htmlFor="filingDt">
                      <strong>If yes, please provide date.</strong>
                    </label>

                    <div className={'position-rel'}>
                      <DatePicker
                        id="filingDt"
                        name="filingDt"
                        ref={(c) => (calendar = c)}
                        className="form-control date-picker"
                        selected={filingDate}
                        value={values.filingDt}
                        placeholderText="MM/DD/YYYY"
                        onChange={handleFilingDate}
                        dateFormat="MM / dd / yyyy"
                        aria-label="filingDt"
                        minDate={minDate}
                        maxDate={new Date()}
                      />
                      {(errorFilingDate || errors.filingDt) && (
                        <div className="chinstrap form-control">{errors.filingDt}</div>
                      )}
                      <div className="image-wrapper-div">
                        <img
                          className="img-wrapper"
                          src={calendarIcon}
                          onClick={openDatepicker}
                          alt="Calendar icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column-two-buttons float-right">
                    <Button
                      title="Next"
                      type="submit"
                      className={'next-button'}
                      action={() => setSubmitLater(false)}
                    />
                  </div>
                  <div className="column-two-buttons float-left">
                    <Button
                      title="Back"
                      className={'cancel-button float-left'}
                      action={() => navigate('/hardships')}
                    />
                  </div>
                  <div className=" ">
                    <Button
                      title="Save and Continue Later"
                      className={'third-button save-button'}
                      action={(e) => {
                        setSubmitLater(true);
                        handleSubmit(e);
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BankruptcyCard;
