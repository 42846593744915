import axios from 'axios';
import {EXPERIENCE_API_URL} from '../utilities/constants';
export const getAccounts = async (userToken) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };
  try {
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/Collection/details`,
      {
        token: userToken,
        transType: 'Collections'
      },

      options
    );
    const responseObj = res.data.collectionAccounts;
    const accounts = responseObj.delinquentAccounts;
    const storedPaymentMethods = responseObj.achStoredPymtMethods;
    const storedDebitPymtMethods = responseObj.debitStoredPymtMethods;
    const authorizedRegionsAccounts = responseObj.authorizedRegionsAccounts;
    const token = res.data.token;

    console.log('responseObj::', res);
    return {
      accounts,
      storedPaymentMethods,
      storedDebitPymtMethods,
      authorizedRegionsAccounts,
      token
    };
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};

export const getBankDetails = async (data) => {
  const options = {
    headers: {
      'Client-ID': 'testClient',
      'Client-Secret': 'K007NKrpted$vvall'
    }
  };

  try {
    const res = await axios.post(
      `${EXPERIENCE_API_URL}/Collection/Bank/details`,

      data,
      options
    );
    const responseObj = res.data;
    console.log('in service try::', res);
    return responseObj;
  } catch (error) {
    console.log('Error Occured while Fetching data from server:', error);
    return error;
  }
};
