import React, {FC, Fragment} from 'react';
import PageHeading from '../PageHeading/PageHeading';
import {useAppContext} from '../../contexts/AppContext';
import Button from '../common/Button';
import {useNavigate} from 'react-router-dom';
const ExtensionApplied: FC = () => {
  const {selectedAccount, confNmbr, setAccountList} = useAppContext();
  const navigate = useNavigate();
  return (
    <Fragment>
      <PageHeading title="Loan Extension" />
      {selectedAccount && (
        <div className={'regions-container'}>
          <div className={'page-content-container hardship-card'}>
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <div className={'one-column-block'}>
                  <h1 className={'sr-only'}>Being Extension Application</h1>
                  <h2 className="payment-header">
                    <span className={'account-type'}>
                      {selectedAccount.delqProdType} Ending in{' '}
                      {selectedAccount.delqAcctNbr.substr(-4, 4)}
                    </span>
                  </h2>
                  <div>
                    <p className={'confirmation-number'}>{confNmbr}</p>
                    <p>
                      We have received your loan extension application and will send you an email
                      with instructions for electronically signing and submitting the completed
                      document.
                    </p>
                    <p>
                      <strong>
                        Please note: your application will be considered complete only after it is
                        signed.
                      </strong>
                    </p>
                    <p>
                      Pending decision of your Extension Application, you should continue to make
                      your payments on the loan by the scheduled due date.
                    </p>
                    <p>
                      If you need help or have questions about other hardship assistance that may be
                      available to you, please call 1-866-298-1113 Monday through Friday, 8 a.m. to
                      4:30 p.m. CT.
                    </p>
                  </div>

                  <div className="row text-right">
                    <div className="col-sm-12 col-lg-12 button-layout">
                      <Button
                        title="Return to Account Summary"
                        className={'next-button schedule-button'}
                        action={() => {
                          navigate('/landing');
                          setAccountList([]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default ExtensionApplied;
